import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";

import { Control } from "../../../types/controls";
import { Link } from "react-router-dom";
import { ControlSet, isControlSet } from "../../../types/controlsets";
import { Box, Button } from "@mui/material";
import {
  useGeneralPatch,
  useExportRelatedObjects,
  useGetObjects,
} from "../../../hooks/common";
import { EvidenceRequest } from "../../../types/evidencerequests";
import { toast } from "react-toastify";
import CSVPatch from "../../controlsets/edit/import";
import { getUserInfo } from "../../../helpers/user";
import ControlVideosComponent from "../../controls/edit/videos";

interface HasControls {
  id?: string;
  controls?: Control[];
}

interface SecondaryControlTableProps {
  parentObject: Partial<EvidenceRequest>;
  parentObjectUrlKey: string;
  relatedChildFieldName: string;
  throughObjectKey: string;
}

const SecondaryControlTable = ({
  parentObject,
  parentObjectUrlKey,
  relatedChildFieldName,
  throughObjectKey,
}: SecondaryControlTableProps) => {
  const [adminMode, setAdminMode] = useState<boolean>(false);
  useEffect(() => {
    const userInfo = getUserInfo();
    if (userInfo.role === "admin") {
      setAdminMode(true);
    }
  }, []);

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [editedParentObject, setEditedParentObject] =
    useState<HasControls>(parentObject);

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [columnFilters]);

  const relatedParentField = parentObjectUrlKey;
  const relatedChildField = relatedChildFieldName;
  const relatedParentId = parentObject.id;
  const [showRelatedOnly, setShowRelatedOnly] = useState(true);

  const getParams = {
    pagination,
    columnFilters,
    sorting,
    globalFilter,
    relatedParentField,
    relatedChildField,
    relatedParentId,
    showRelatedOnly,
    throughObjectKey,
  };

  const {
    data: controlQueryResults = [],
    isError: isLoadingControlsError,
    isLoading: isLoadingControls,
    isFetching: isFetchingControls,
  } = useGetObjects<
    Control,
    [
      "controls",
      {
        pagination: MRT_PaginationState;
        columnFilters: MRT_ColumnFiltersState;
        sorting: MRT_SortingState;
        globalFilter: string | undefined;
        relatedParentField: string | undefined;
        relatedChildField: string | undefined;
        relatedParentId: string | undefined;
        showRelatedOnly: boolean;
        throughObjectKey: string;
      }
    ]
  >(
    [
      "controls",
      {
        pagination,
        columnFilters,
        sorting,
        globalFilter,
        relatedParentField,
        relatedChildField,
        relatedParentId,
        showRelatedOnly,
        throughObjectKey,
      },
    ],
    "controls",
    getParams
  );

  const fetchedControls = controlQueryResults[0]?.data || [];

  useEffect(() => {
    const count = controlQueryResults[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [controlQueryResults]);

  useEffect(() => {
    parentObject.controls?.forEach((control: Control) => {
      const id = control.id;
      rowSelection[id] = true;
    });

    setTimeout(() => {
      setInitialLoad(false);
    }, 1000);
  }, []);

  const exportRelatedObjects = useExportRelatedObjects(
    relatedParentField,
    relatedParentId,
    "controls"
  );

  const tableName = "secondary_controls_table";
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const tableState = JSON.parse(localStorage.getItem("tableState") || "{}");
    return (
      tableState[tableName]?.columnVisibility || {
        id: false,
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tableState") || "{}"),
        [tableName]: {
          columnVisibility,
        },
      })
    );
  }, [columnVisibility, tableName]);

  const columns = useMemo<MRT_ColumnDef<Control>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
        enableSorting: false,
      },

      {
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: false,
        header: "Name",
        Cell: ({ row }) => {
          return (
            <>
              <Link to={`/control/${row.original.id}`}>
                {row.original.name}
              </Link>
            </>
          );
        },
      },
      {
        accessorKey: "title",
        enableColumnFilter: false,
        header: "Title",
        enableSorting: false,
      },
      {
        accessorKey: "description",
        enableSorting: false,
        enableColumnFilter: false,
        header: "Description",
        Cell: ({ row }) => (
          <div style={{ textAlign: "left", maxHeight: "110px" }}>
            <ReactMarkdown>{row.original.description}</ReactMarkdown>
          </div>
        ),
      },

      {
        accessorKey: "preventive",
        enableColumnFilter: false,
        header: "Preventive",
        enableSorting: false,
        accessorFn: (row) => (row.preventive ? "Yes" : "No"),
      },

      {
        accessorKey: "administrative",
        enableColumnFilter: false,
        header: "Administrative",
        enableSorting: false,
        accessorFn: (row) => (row.administrative ? "Yes" : "No"),
      },

      {
        accessorKey: "detective",
        enableColumnFilter: false,
        header: "Detective",
        enableSorting: false,
        accessorFn: (row) => (row.detective ? "Yes" : "No"),
      },
      {
        accessorKey: "control_description_video_exists",
        header: "Control Description Video",
        enableSorting: false,
        enableColumnFilter: false,

        Cell: ({ row }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <ControlVideosComponent
              video_type="control_description"
              control={row.original}
              tableMode={true}
            />
          </Box>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    enableGlobalFilter: false,
    data: fetchedControls,
    positionToolbarAlertBanner: "none",
    initialState: {
      showColumnFilters: false,
      density: "compact",
      columnVisibility: columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    enableSelectAll: false,
    enableColumnResizing: true,
    getRowId: (row) => row.id,
    rowNumberDisplayMode: "original",
    muiToolbarAlertBannerProps: isLoadingControlsError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    muiTableContainerProps: {
      sx: {
        minHeight: "100px",
      },
    },

    state: {
      columnFilters,
      globalFilter,
      rowSelection,
      pagination,
      sorting,
      columnVisibility: columnVisibility,
      isLoading: isLoadingControls,
      showAlertBanner: isLoadingControlsError,
      showProgressBars: isFetchingControls,
    },
  });

  if (initialLoad) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loader"></div>
      </div>
    );
  } else {
    return (
      <div style={{ opacity: 1, transition: "opacity 3s" }}>
        <div className="fullPageTable">
          <MaterialReactTable table={table} />
        </div>
      </div>
    );
  }
};

export default SecondaryControlTable;
