import React, { useState, useEffect, FunctionComponent } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  ListItem,
  ListItemText,
  IconButton,
  Autocomplete,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Grid,
} from "@mui/material";
import PaginatedModalComponent from "../../edit/PaginatedModalComponent";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import {
  useCreateControl,
  useDeleteControl,
  useGetControl,
  useUpdateControl,
} from "../../../hooks/controls";

import CloseIcon from "@mui/icons-material/Close";
import Layout from "../../../layout";
import { Control } from "../../../types/controls";
import { useSearchControls } from "../../../hooks/controls";
import { TabPanel, a11yProps } from "../../../helpers/functions";
import ControlTable from "../../tables/controls";
import PredecessorControlTable from "../../tables/predecessor_controls";
import ControlVideosComponent from "./videos";

export const defaultControl: Partial<Control> = {
  name: "",
  title: "",
  description: "",
  preventive: false,
  administrative: false,
  detective: false,
  user: "",
  predecessor_controls: [],
  date_created: new Date(),
};

interface EditControlModalProps {
  control?: Partial<Control> | null;
  isOpen?: boolean;
  mode?: "modal" | "page";
  onClose?: () => void;
}

const AdminEditControlModal: FunctionComponent<EditControlModalProps> = ({
  control = defaultControl,
  isOpen = true,
  mode = "modal",
  onClose = () => {},
}) => {
  const navigate = useNavigate();
  const [editedControl, setEditedControl] = useState<
    Control | Partial<Control>
  >(control || defaultControl);
  const isPageMode = mode === "page";

  const { id } = useParams<{ id: string }>();
  const [loadingError, setLoadingError] = useState(false);
  const [descriptionMode, setDescriptionMode] = useState("edit");

  if (id) {
    const { data, isLoading: isFetching } = useGetControl({ id });
    useEffect(() => {
      if (mode === "page" && id && data && !isFetching) {
        if (data[0].controls.length === 0) {
          setLoadingError(true);
        } else {
          const deepCopiedControl = JSON.parse(
            JSON.stringify(data[0].controls[0])
          );
          setEditedControl(deepCopiedControl);
          setDescriptionMode(deepCopiedControl.description ? "view" : "edit");
        }
      }
    }, [id, mode, data, isFetching]);
  }

  const [searchQueryPredecessorControls, setSearchQueryPredecessorControls] =
    useState("");
  const [
    searchResultsPredecessorControls,
    setSearchResultsPredecessorControls,
  ] = useState<Control[]>([]);

  const updateControl = useUpdateControl();
  const createControl = useCreateControl();

  const {
    data: predecessorControlSearchResults,
    refetch: refetchPredecessorControls,
  } = useSearchControls({
    q: searchQueryPredecessorControls,
  });

  const toggleMode = () => {
    setDescriptionMode(descriptionMode === "view" ? "edit" : "view");
  };

  useEffect(() => {
    if (predecessorControlSearchResults) {
      setSearchResultsPredecessorControls(
        predecessorControlSearchResults[0].controls
      );
    }
  }, [predecessorControlSearchResults]);

  useEffect(() => {
    refetchPredecessorControls();
  }, [searchQueryPredecessorControls, refetchPredecessorControls]);

  const handleAddPredecessorControl = (control: Control): void => {
    let updatedControls: Control[];
    updatedControls = [...(editedControl.predecessor_controls ?? []), control];
    setEditedControl((prev) => ({
      ...prev,
      predecessor_controls: updatedControls,
    }));
  };

  const handleRemovePredecessorControl = (control: Control): void => {
    const updatedControls: Control[] =
      editedControl.predecessor_controls?.filter((c) => c.id !== control.id) ??
      [];

    setEditedControl((prev) => ({
      ...prev,
      predecessor_controls: updatedControls,
    }));
  };

  const renderPredecessorControl = (control: Control) => (
    <ListItem className="modalEditListItem" key={control.id}>
      <ListItemText
        primary={<Link to={`/control/${control.id}`}>{control.name}</Link>}
      />

      <IconButton
        edge="end"
        aria-label="remove"
        onClick={() => handleRemovePredecessorControl(control)}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </ListItem>
  );

  const handleSubmit = async () => {
    try {
      if ("id" in editedControl) {
        await updateControl.mutateAsync(editedControl as Control);
        onClose();
      } else {
        await createControl.mutateAsync(editedControl as Control);
        onClose();
      }
    } catch (error) {}
  };

  const deleteControl = useDeleteControl();

  const triggerDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Control?"
    );
    if (confirmDelete) {
      try {
        await deleteControl.mutateAsync(editedControl.id as Control["id"]);
        navigate("/controls");
      } catch (error) {}
    }
  };
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabId: number) => {
    setActiveTab(tabId);
  };

  const pageModeContent = (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => handleTabClick(newValue)}
            aria-label="Switch tabs"
          >
            <Tab label="Predecessor Controls" {...a11yProps(0)} />
            <Tab label="Control Videos" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={activeTab} index={0}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" component="div">
              Controls
            </Typography>

            {editedControl.id && (
              <PredecessorControlTable
                parentObjectUrlKey="controls"
                loadPartial={true}
                parentObject={editedControl}
                relatedChildFieldName="predecessor_controls"
              />
            )}
          </Box>
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <Box sx={{ padding: 2 }}>
            {editedControl.id && (
              <>
                <Grid container gap={2}>
                  <ControlVideosComponent
                    video_type="control_description"
                    control={editedControl}
                  />
                  {/* <ControlVideosComponent
                    video_type="evidence_description"
                    control={editedControl}
                  /> */}
                </Grid>
              </>
            )}
          </Box>
        </TabPanel>
      </Box>
    </>
  );

  const content = (
    <>
      <DialogContent>
        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <TextField
            fullWidth
            label="Name"
            variant="outlined"
            value={editedControl.name}
            onChange={(e) =>
              setEditedControl({
                ...editedControl,
                name: e.target.value,
              })
            }
            margin="normal"
          />
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            value={editedControl.title}
            onChange={(e) =>
              setEditedControl({
                ...editedControl,
                title: e.target.value,
              })
            }
            margin="normal"
          />
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <Button
            onClick={toggleMode}
            variant="contained"
            startIcon={
              descriptionMode === "view" ? <EditIcon /> : <VisibilityIcon />
            }
            sx={{
              margin: 2,
              marginLeft: 0,
            }}
          >
            {descriptionMode === "view"
              ? "Edit Description"
              : "Preview Description"}
          </Button>
          {descriptionMode === "edit" ? (
            <TextField
              fullWidth
              multiline
              label="Control Description"
              variant="outlined"
              value={editedControl.description}
              onChange={(e) =>
                setEditedControl({
                  ...editedControl,
                  description: e.target.value,
                })
              }
              margin="normal"
            />
          ) : (
            <Typography
              component="div"
              variant="body1"
              sx={{
                lineHeight: "1em",
                padding: 2,
                border: 2,
                borderColor: "divider",
                borderRadius: 3,
                overflow: "wrap",
              }}
            >
              <ReactMarkdown>{editedControl.description}</ReactMarkdown>
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={editedControl.preventive}
                onChange={(e) =>
                  setEditedControl({
                    ...editedControl,
                    preventive: e.target.checked,
                  })
                }
                name="preventive"
              />
            }
            label="Preventive"
          />
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={editedControl.administrative}
                onChange={(e) =>
                  setEditedControl({
                    ...editedControl,
                    administrative: e.target.checked,
                  })
                }
                name="administrative"
              />
            }
            label="Administrative"
          />
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={editedControl.detective}
                onChange={(e) =>
                  setEditedControl({
                    ...editedControl,
                    detective: e.target.checked,
                  })
                }
                name="detective"
              />
            }
            label="Detective"
          />
        </Box>

        {/* predecessor controls */}
        {!isPageMode && (
          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <Typography variant="h6">Predecessor Controls</Typography>
            <Autocomplete
              multiple
              options={searchResultsPredecessorControls}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              onChange={(event, newValue) => {
                newValue.forEach((predecessorControl) => {
                  if (
                    !editedControl.predecessor_controls?.some(
                      (c) => c.id === predecessorControl.id
                    )
                  ) {
                    handleAddPredecessorControl(predecessorControl);
                  }
                });
              }}
              renderTags={() => {
                return null;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Predecessor Controls"
                  variant="outlined"
                  onChange={(e) =>
                    setSearchQueryPredecessorControls(e.target.value)
                  }
                />
              )}
            />
            <PaginatedModalComponent
              items={
                editedControl.predecessor_controls?.map((control) => control) ??
                []
              }
              renderItem={renderPredecessorControl}
            />
          </Box>
        )}
      </DialogContent>
    </>
  );

  if (loadingError) {
    return (
      <Layout pageTitle="" parentUrl="" parentPageName="">
        <Typography variant="h5" sx={{ color: "black", padding: 3 }}>
          Control not found
        </Typography>
        ;
      </Layout>
    );
  } else
    return isPageMode ? (
      <Layout
        pageTitle="Control"
        parentUrl="/controls"
        parentPageName="Controls"
      >
        <Box>
          <Box sx={{ paddingRight: "6vh" }}>
            <Typography fontSize={25} sx={{ color: "black", padding: 3 }}>
              {editedControl.title}
            </Typography>

            <DialogActions>
              <Button variant="contained" color="error" onClick={triggerDelete}>
                Delete Control
              </Button>

              <Button variant="contained" onClick={handleSubmit}>
                Update Control
              </Button>
            </DialogActions>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "100%",
                color: "black",
                border: 2,
                borderColor: "divider",
                margin: 2,
                borderRadius: 3,
              }}
            >
              {content}
            </Box>
            <Box
              sx={{
                width: "100%",
                maxWidth: "100%",
                color: "black",
                border: 2,
                borderColor: "divider",
                margin: 2,
                borderRadius: 3,
              }}
            >
              {pageModeContent}
            </Box>
          </Box>
        </Box>
      </Layout>
    ) : (
      <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography fontSize={25}>{editedControl.name}</Typography>
          <CloseIcon
            onClick={onClose}
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              fontSize: 30,
              cursor: "pointer",
            }}
          />
        </DialogTitle>

        {content}

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    );
};

export default AdminEditControlModal;
