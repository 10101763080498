import React, { useEffect, useMemo, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ReactMarkdown from "react-markdown";
import Layout from "../../layout";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";

import { Engagement } from "../../types/engagements";

import { Link } from "react-router-dom";
import { useGetObjects } from "../../hooks/common";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import { getUserInfo } from "../../helpers/user";
import OrgAdminEditEngagementModal from "../../components/engagements/edit/org_admin";

interface UserEngagementPageProp {
  cardMode?: boolean;
}

const UserEngagementPage: React.FC<UserEngagementPageProp> = ({ cardMode }) => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([
    { id: "due_date", desc: false },
  ]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);
  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [columnFilters]);

  const showLayout = !cardMode;
  const user = getUserInfo();
  const isOrgAdmin = user.role === "org_admin";

  const [currentEngagement, setCurrentEngagement] = useState<Engagement | null>(
    null
  );
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);

  const openEditModal = (engagement: Engagement) => {
    setCurrentEngagement(engagement);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setCurrentEngagement(null);
  };

  const getParams = {
    pagination,
    columnFilters,
    sorting,
    globalFilter,
  };

  const {
    data: engagementQueryResults = [],
    isError: isLoadingEngagementsError,
    isLoading: isLoadingEngagements,
    isFetching: isFetchingEngagements,
  } = useGetObjects<
    Engagement,
    [
      "engagements",
      {
        pagination: MRT_PaginationState;
        columnFilters: MRT_ColumnFiltersState;
        sorting: MRT_SortingState;
        globalFilter: string | undefined;
      }
    ]
  >(
    ["engagements", { pagination, columnFilters, sorting, globalFilter }],
    "engagements",
    getParams
  );

  const fetchedEngagements = engagementQueryResults[0]?.data || [];

  useEffect(() => {
    const count = engagementQueryResults[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [engagementQueryResults]);

  const tableName = "user_engagement_page_table";
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const tableState = JSON.parse(localStorage.getItem("tableState") || "{}");
    return (
      tableState[tableName]?.columnVisibility || {
        id: false,
        description: showLayout,
        organization: showLayout,
        date_created: showLayout,
        completed: showLayout,
        users: showLayout,
        evidence_requests: showLayout,
        scopes: showLayout,
        prev_engagements: showLayout,
        peer_engagements: showLayout,
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tableState") || "{}"),
        [tableName]: {
          columnVisibility,
        },
      })
    );
  }, [columnVisibility, tableName]);
  const columns = useMemo<MRT_ColumnDef<Engagement>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
        enableSorting: false,
      },

      {
        accessorKey: "name",
        header: "Name",
        Cell: ({ row }) => {
          return (
            <>
              <div style={{ marginLeft: "20px" }}>
                <Link to={`/engagement/${row.original.id}`}>
                  <Typography
                    sx={{
                      fontSize: "0.875rem",
                      color: row.original.completed ? "grey" : "inherit",
                    }}
                  >
                    {row.original.name}
                  </Typography>
                </Link>
              </div>
            </>
          );
        },

        muiEditTextFieldProps: {
          type: "text",
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              name: undefined,
            }),
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        enableColumnFilter: false,
        enableSorting: false,
        size: 240,

        Cell: ({ row }) => (
          <div style={{ textAlign: "left", maxHeight: "110px" }}>
            <ReactMarkdown>{row.original.description}</ReactMarkdown>
          </div>
        ),
        muiEditTextFieldProps: {
          type: "text",
          required: true,
          error: !!validationErrors?.description,
          helperText: validationErrors?.description,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              description: undefined,
            }),
        },
      },
      {
        accessorKey: "due_date",
        header: "Due Date",
        enableColumnFilter: false,
        Cell: ({ row }) => {
          if (row.original.due_date) {
            const date = new Date(row.original.due_date);
            return new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour12: true,
            }).format(date);
          }
        },
        muiEditTextFieldProps: {
          type: "Date",
          required: false,
        },
      },
      {
        accessorKey: "completed",
        header: "Completed",
        accessorFn: (row) => (row.completed ? "Yes" : "No"),
      },

      {
        accessorKey: "date_created",
        enableColumnFilter: false,
        header: "Created On",
        Cell: ({ row }) => {
          if (row.original.date_created) {
            const date = new Date(row.original.date_created);
            return new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }).format(date);
          }
        },
        muiEditTextFieldProps: {
          type: "Date",
          enableEditing: false,
        },
      },

      {
        accessorKey: "users",
        header: "Users",
        enableColumnFilter: false,
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.users.map((user, index) => (
                  <div key={index}>
                    <div>{user.name}</div>
                  </div>
                ))}
              </div>
            </>
          );
        },
      },

      {
        accessorKey: "evidence_requests",
        header: "Evidence Requests",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.evidence_requests.map(
                  (evidence_request, index) => (
                    <div key={index}>
                      <Link to={`/evidence-request/${evidence_request.id}`}>
                        {evidence_request.title}
                      </Link>
                    </div>
                  )
                )}
              </div>
            </>
          );
        },
      },

      {
        accessorKey: "prev_engagements",
        header: "Previous Engagements",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.prev_engagements.map((prev_engagement, index) => (
                  <div key={index}>
                    <Link to={`/engagement/${prev_engagement.id}`}>
                      {prev_engagement.name}
                    </Link>
                  </div>
                ))}
              </div>
            </>
          );
        },
      },

      {
        accessorKey: "peer_engagements",
        header: "Peer Engagements",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.peer_engagements.map((peer_engagement, index) => (
                  <div key={index}>
                    <Link to={`/engagement/${peer_engagement.id}`}>
                      {peer_engagement.name}
                    </Link>
                  </div>
                ))}
              </div>
            </>
          );
        },
      },
    ],
    [validationErrors]
  );

  const table = useMaterialReactTable({
    columns,
    enableGlobalFilter: false,
    data: fetchedEngagements,
    enableRowActions: isOrgAdmin,
    initialState: {
      showColumnFilters: false,
      density: "compact",
      columnVisibility: columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    manualFiltering: true,
    renderEmptyRowsFallback: () => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography gutterBottom>
          <i>No Engagements have been assigned to you yet.</i>
        </Typography>
      </Box>
    ),
    manualPagination: true,
    manualSorting: true,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    selectAllMode: "all",
    enableColumnResizing: true,
    getRowId: (row) => row.id,
    rowNumberDisplayMode: "original",
    renderRowActionMenuItems: ({ row, table }) => [
      <div key={row.id}>
        <Box>
          {isOrgAdmin && (
            <Tooltip title="Edit">
              <IconButton onClick={() => openEditModal(row.original)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </div>,
    ],
    muiToolbarAlertBannerProps: isLoadingEngagementsError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),

    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      columnVisibility: columnVisibility,
      isLoading: isLoadingEngagements,
      showAlertBanner: isLoadingEngagementsError,
      showProgressBars: isFetchingEngagements,
    },
  });

  return (
    <>
      {showLayout ? (
        <Layout pageTitle="Engagements" parentUrl="" parentPageName="">
          <div className="fullPageTable">
            <MaterialReactTable table={table} />
            {currentEngagement && isOrgAdmin && (
              <OrgAdminEditEngagementModal
                engagement={currentEngagement}
                isOpen={isEditModalOpen}
                onClose={closeEditModal}
              />
            )}
          </div>
        </Layout>
      ) : (
        <>
          <div className="fullPageTable">
            <MaterialReactTable table={table} />
          </div>
        </>
      )}
    </>
  );
};

export default UserEngagementPage;
