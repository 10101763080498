import React, { useState } from "react";
import { User } from "../../../types/users";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

interface UserAvatarProps {
  user: User;
}

export function UserAvatar({ user }: UserAvatarProps) {
  const [open, setOpen] = useState(false);

  const stringToColor = (string: string) => {
    let hash = 0;
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    const color = `hsl(${hash % 360}, 90%, 40%)`;
    return color;
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={
          <Box
            sx={{
              p: 2,
              bgcolor: "white",
              color: "text.primary",
              maxWidth: 400,
              overflow: "hidden",
            }}
          >
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", pl: 2 }}>
              {`${user.first_name} ${user.last_name}`}
            </Typography>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      border: "none",
                    }}
                  >
                    {user.email}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      border: "none",
                    }}
                  >
                    {user.organization}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        }
      >
        <Avatar
          onClick={handleTooltipOpen}
          sx={{
            border: 1,
            borderColor: "white",
            width: 56,
            height: 56,
            bgcolor: stringToColor(user.id),
          }}
        >
          {`${user.first_name[0]}${user.last_name[0]}`}
        </Avatar>
      </Tooltip>
    </ClickAwayListener>
  );
}
