import React, { useState, useMemo, useEffect } from "react";
import {
  useTable,
  useSortBy,
  Column,
  HeaderGroup,
  Cell,
  Row,
} from "react-table";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import Papa from "papaparse";
import {
  Button,
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Modal,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";

import { toast } from "react-toastify";
import { apiClient } from "../../api/apiClient";
import { GridCloseIcon } from "@mui/x-data-grid";

type CSVData = Record<string, string | number>;
interface ValidationResult {
  isValid: boolean;
  errors: string[];
}

interface ErrorData {
  row: number;
  field: string;
  value: string | null;
  error: string[];
}

interface AxiosErrorResponse {
  response: {
    data: ErrorData[];
  };
}

const ImportFromCSV: React.FC<{
  onClose: () => void;
  apiUrl: string;
  importType: string;
}> = ({ onClose, apiUrl, importType }) => {
  const [csvData, setCsvData] = useState<CSVData[]>([]);
  const [isCsvLoaded, setIsCsvLoaded] = useState(false);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);
  const [errorResponseData, setErrorResponseData] = useState("");

  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    if (importType === "controls") {
      setTitle("Controls");
    }

    if (importType === "evidencerequests") {
      setTitle("Evidence Requests");
    }

    if (importType === "engagements") {
      setTitle("Engagements");
    }

    if (importType === "controlsets") {
      setTitle("Control Sets");
    }
    if (importType === "compliancestandards") {
      setTitle("Compliance Standards");
    }
  });

  const columns = useMemo(() => {
    if (csvData.length > 0) {
      const headers = Object.keys(csvData[0]);

      const dataColumns = headers.map((header) => ({
        Header: header,
        accessor: header,
      })) as Column<CSVData>[];

      return dataColumns;
    }
    return [];
  }, [csvData]);

  const validateCsvData = (
    headers: string[],
    data: CSVData[]
  ): ValidationResult => {
    let errors: string[] = [];

    const blankHeaders = headers.filter((header) => header.trim() === "");
    if (blankHeaders.length > 0) {
      errors.push(
        `The CSV file contains ${blankHeaders.length} blank header(s). Please provide a valid header name for all columns.`
      );
    }

    return {
      isValid: errors.length === 0,
      errors,
    };
  };

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<CSVData>(
      {
        columns,
        data: csvData,
        initialState: {},
      },
      useSortBy
    );
  const onDrop: DropzoneOptions["onDrop"] = (acceptedFiles) => {
    const file = acceptedFiles[0];

    Papa.parse<CSVData>(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const headers = result.meta.fields as string[];
        const validation = validateCsvData(headers, result.data);

        if (!validation.isValid) {
          setUploadError(validation.errors.join(" "));
          setCsvData([]);
          return;
        }

        if (result.errors.length === 0) {
          const filteredData = result.data.filter((row) => {
            return Object.values(row).some(
              (value) => value !== null && value !== ""
            );
          });

          setIsCsvLoaded(true);
          setCsvData(filteredData);
          setUploadError(null);
        } else {
          setCsvData([]);
          setUploadError(
            "Error parsing the CSV file. Please check the file format."
          );
        }
      },
    });
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
  });

  const resetCsvData = () => {
    setCsvData([]);
    setIsCsvLoaded(false);
    setUploadError(null);
    setErrorResponseData("");
  };

  const handleUpload = async () => {
    if (csvData.length > 0) {
      setIsImporting(true);
      try {
        const filteredCsvData = csvData.map((row) => {
          const filteredRow: { csv_mode: boolean; [key: string]: any } = {
            csv_mode: true,
          };
          for (const key in row) {
            if (row[key]) {
              filteredRow[key] = String(row[key]);
            }
          }
          return filteredRow;
        });
        const response = await apiClient.post(apiUrl, filteredCsvData);

        if (response.status === 201 || response.status === 200) {
          closeImportModal();
          toast.success(`${title} imported successfully`);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          toast.error(
            `Error importing ${title}. Please check the status column.`
          );
        }
      } catch (error: AxiosErrorResponse | any) {
        setIsImporting(false);
        if (error.response && error.response.status === 400) {
          const errorResponseData = error.response.data;

          const updatedCsvData = csvData.map((row, index) => {
            const rowError = errorResponseData.find(
              (r: any) => r.row === index + 1
            );
            if (rowError) {
              let errorMessage = "";

              // Extract the actual error from the api response
              const stringRegex = /string=\'(.*?)\'/;
              const match = stringRegex.exec(rowError.error);
              if (match && match[1]) {
                errorMessage = `❌ Error: ${match[1]}`;
              } else {
                errorMessage = rowError.error ?? "";
              }
              const cleanedErrorMessage = errorMessage
                .replace(/^\["|"\]$/g, "")
                .replace(/^"/, "")
                .replace(/"$/, "");

              setErrorResponseData(
                `Row ${rowError.row}: ${cleanedErrorMessage}`
              );

              return {
                error: errorMessage,
                ...row,
              };
            } else {
              return row;
            }
          });

          setCsvData(updatedCsvData);
          toast.error(
            `Error importing ${title}. Please check the error column.`
          );
        } else {
          setUploadError(`An error occurred while importing ${title}.`);
          toast.error(`An error occurred while importing ${title}.`);
        }
      }
    }
  };

  const closeImportModal = () => {
    setIsModalOpen(false);
    onClose();
  };

  const modalStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "90vh",
    width: "90vw",

    borderRadius: "8px",
    backgroundColor: "white",
    overflow: "auto",
    display: "flex",
    flexDirection: "column",
  };

  const buttonContainerStyle: React.CSSProperties = {
    position: "sticky",
    top: "0",
    zIndex: 1,
    backgroundColor: "white",
  };

  const tableContainerStyle: React.CSSProperties = {
    overflow: "auto",
    flex: 1,
  };

  return (
    <Box>
      <Modal
        sx={modalStyle}
        open={isModalOpen}
        onClose={closeImportModal}
        id="innerModal"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box id="modalButtons" sx={modalStyle}>
          <Box sx={buttonContainerStyle}>
            {isCsvLoaded && (
              <>
                <Button
                  sx={{ margin: "10px" }}
                  variant="contained"
                  onClick={handleUpload}
                  disabled={isImporting}
                >
                  Import {title}
                </Button>
                <Button
                  sx={{ margin: "10px" }}
                  variant="contained"
                  onClick={resetCsvData}
                  disabled={isImporting}
                >
                  Reset
                </Button>
              </>
            )}
            <Button
              onClick={closeImportModal}
              style={{
                position: "absolute",
                right: "10px",
                fontSize: "1.5rem",
              }}
            >
              ✖
            </Button>
          </Box>
          {errorResponseData && errorResponseData.length > 0 && (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 2,
                  color: "error.light",
                  borderRadius: 1,
                  marginBottom: 2,
                }}
              >
                <Typography variant="body1" color="error.main">
                  {errorResponseData}
                </Typography>
                <Tooltip title="Close" arrow>
                  <IconButton
                    onClick={() => setErrorResponseData("")}
                    size="small"
                    aria-label="close"
                    color="error"
                  >
                    <GridCloseIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </>
          )}
          {!csvData.length && (
            <Box>
              <Typography id="modal-title" variant="h6" component="h2">
                CSV Import for {title}
              </Typography>
              <hr />

              <Box {...getRootProps()} style={dropzoneStyle} />

              <input {...getInputProps()} />
              <Typography>
                Drag and drop a CSV file here or click to select one
              </Typography>

              {uploadError && (
                <Typography className="error">{uploadError}</Typography>
              )}
            </Box>
          )}
          {uploadError && <Box color="error.main">{uploadError}</Box>}
          {csvData.length > 0 && (
            <Box sx={tableContainerStyle} id="csvData">
              <TableContainer>
                <Typography sx={{ margin: "10px" }}>
                  {isImporting && <CircularProgress size={20} thickness={4} />}
                  <br />
                  {csvData.length} {title} in csv
                </Typography>
                <Table {...getTableProps()}>
                  <TableHead>
                    {headerGroups.map((headerGroup: HeaderGroup<CSVData>) => (
                      <TableRow {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <TableCell
                            sx={{
                              fontWeight: 900,
                              backgroundColor: "#f5f5f5",
                            }}
                            {...(column as any).getHeaderProps(
                              (column as any).getSortByToggleProps()
                            )}
                          >
                            {(column as any).render("Header")}
                            <div>
                              {(column as any).canFilter
                                ? (column as any).render("Filter")
                                : null}
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    ))}
                  </TableHead>
                  <TableBody {...getTableBodyProps()}>
                    {rows.map((row: Row<CSVData>) => {
                      prepareRow(row);
                      return (
                        <TableRow {...row.getRowProps()}>
                          {row.cells.map((cell: Cell<CSVData>) => {
                            return (
                              <TableCell {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

const dropzoneStyle: React.CSSProperties = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};

export default ImportFromCSV;
