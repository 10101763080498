import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import { Box, Button, Typography } from "@mui/material";
import { useMultiplePatch } from "../../../hooks/common";

interface CSVPatchProps {
  parentObjectType: string | undefined;
  parentObjectId: string | undefined;
  mode:
    | "controls"
    | "compliance_standards"
    | "engagements"
    | "evidencerequests"
    | "compliancestandards";
  action: "add" | "remove";
  childObjectType?: string;
}

const CSVPatch: React.FC<CSVPatchProps> = ({
  parentObjectType,
  parentObjectId,
  mode,
  action,
  childObjectType,
}) => {
  const [csvData, setCsvData] = useState<Array<any>>([]);
  const patchParentObject = useMultiplePatch("add");
  const unPatchParentObject = useMultiplePatch("remove");

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      Papa.parse(file, {
        complete: (result) => {
          setCsvData(result.data);
        },
        header: true,
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleSendData = () => {
    if (!parentObjectId || csvData.length === 0) return;

    const payload: any = { parentObjectId };

    payload.parentObjectId = parentObjectId;
    payload.parentObjectType = parentObjectType;
    payload.childObjectType = childObjectType || mode;

    if (mode === "controls") {
      payload.childObjects = csvData.map((row) => row.controls);
      if (childObjectType == "predecessor_controls") {
        payload.childObjects = csvData.map((row) => row.predecessor_controls);
      }
    }
    if (mode === "compliancestandards") {
      payload.childObjects = csvData.map((row) => row.compliance_standards);
    }

    if (mode === "engagements") {
      payload.childObjects = csvData.map((row) => row.engagements);
    }

    if (mode === "evidencerequests") {
      payload.childObjects = csvData.map((row) => row.evidence_requests);
    }

    if (action === "add") {
      patchParentObject.mutate(payload);
    }

    if (action === "remove") {
      unPatchParentObject.mutate(payload);
    }
  };

  return (
    <div>
      <div
        {...getRootProps()}
        style={{
          border: "2px dashed #007bff",
          padding: "20px",
          cursor: "pointer",
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <div>
            <p>Drop or click to select file</p>
            <p>File should be single column text or csv </p>
            <p style={{ color: "red" }}>First row: {childObjectType} </p>
            <p>
              {mode === "controls" &&
                "Then each row should have a control name"}
              {mode === "compliancestandards" &&
                "Then each row should have a compliance standard title"}
              {mode === "engagements" &&
                "Then each row should have an engagement name"}
              {mode === "evidencerequests" &&
                "Then each row should have an evidence request title"}
            </p>
          </div>
        )}
      </div>

      {csvData.length > 0 && (
        <Box
          sx={{
            display: "block",
            padding: 3,
            border: "1px solid #007bff",
            borderRadius: 1,
            marginTop: 2,
          }}
        >
          <Box
            sx={{
              margin: 3,
            }}
          >
            {csvData.map((row, index) => (
              <Box key={index}>
                {Object.keys(row).map((key) => (
                  <Typography key={key}>{`${row[key]}`} </Typography>
                ))}
              </Box>
            ))}
          </Box>
          <Button
            sx={{
              margin: 3,
            }}
            variant="contained"
            onClick={handleSendData}
            disabled={csvData.length === 0}
          >
            {action === "add" ? "Add " : "Remove "}
            {mode === "controls" && "Controls"}
            {mode === "compliancestandards" && "Compliance Standards"}
            {mode === "engagements" && "Engagements"}
            {mode === "evidencerequests" && "Evidence Requests"}
          </Button>
        </Box>
      )}
    </div>
  );
};

export default CSVPatch;
