import React, { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";

import { Box, Button, IconButton, Tooltip } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useGetOrganizations,
  useUpdateOrganization,
  useDeleteOrganization,
  useCreateOrganization,
} from "../../../hooks/organizations";
import { Organization } from "../../../types/organizations";

const OrganizationTable = () => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [columnFilters]);

  const { mutateAsync: deleteOrganization } = useDeleteOrganization();

  const {
    data: organizationQueryResults = [],
    isError: isLoadingOrganizationsError,
    isFetching: isFetchingOrganizations,
    isLoading: isLoadingOrganizations,
  } = useGetOrganizations({ pagination, columnFilters, sorting, globalFilter });

  const fetchedOrganizations = organizationQueryResults[0]?.organizations || [];

  useEffect(() => {
    const count = organizationQueryResults[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [organizationQueryResults]);

  const handleSaveOrganization: MRT_TableOptions<Organization>["onEditingRowSave"] =
    async ({ values, table }) => {
      const newValidationErrors = validateOrganization(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await updateOrganization(values);
    };

  const handleCreateOrganization: MRT_TableOptions<Organization>["onCreatingRowSave"] =
    async ({ values, table }) => {
      const newValidationErrors = validateOrganization(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await createOrganization(values);
    };

  const openDeleteConfirmModal = (row: MRT_Row<Organization>) => {
    if (window.confirm("Are you sure you want to delete this Organization?")) {
      deleteOrganization(row.original.id);
    }
  };

  function validateOrganization(organization: Organization) {
    const validateRequired = (value: any) => !!value;

    return {
      name: !validateRequired(organization.name) ? "Name is required" : "",
    };
  }

  const tableName = "organizations_table";
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const tableState = JSON.parse(localStorage.getItem("tableState") || "{}");
    return (
      tableState[tableName]?.columnVisibility || {
        id: false,
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tableState") || "{}"),
        [tableName]: {
          columnVisibility,
        },
      })
    );
  }, [columnVisibility, tableName]);

  const columns = useMemo<MRT_ColumnDef<Organization>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        enableSorting: false,
        size: 80,
      },

      {
        accessorKey: "name",
        header: "Name",
        enableEditing: (row) => !row.original.id,
        muiEditTextFieldProps: {
          type: "text",
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              name: undefined,
            }),
        },
      },
      {
        accessorKey: "short_description",
        header: "Short Description",
        enableColumnFilter: false,
        enableSorting: false,
        muiEditTextFieldProps: {
          type: "text",
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        enableSorting: false,
        enableColumnFilter: false,
        muiEditTextFieldProps: {
          type: "text",
        },
      },
      {
        accessorKey: "user_count",
        header: "User Count",
        enableSorting: false,
        enableEditing: false,
        enableColumnFilter: false,
        muiEditTextFieldProps: {
          type: "text",
        },
      },
    ],
    [validationErrors]
  );

  const table = useMaterialReactTable({
    columns,
    enableGlobalFilter: false,
    data: fetchedOrganizations,
    initialState: {
      showColumnFilters: false,
      density: "compact",
      columnVisibility: columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    selectAllMode: "all",
    enableColumnResizing: true,
    getRowId: (row) => row.id,
    rowNumberDisplayMode: "original",
    muiToolbarAlertBannerProps: isLoadingOrganizationsError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    renderBottomToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            table.setCreatingRow(true);
          }}
        >
          New Organization
        </Button>
      </Box>
    ),
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateOrganization,
    onEditingRowSave: handleSaveOrganization,

    enableRowActions: true,
    renderRowActionMenuItems: ({ row, table }) => [
      <Box key={row.id}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>,
    ],
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      columnVisibility: columnVisibility,
      isLoading: isLoadingOrganizations,
      showAlertBanner: isLoadingOrganizationsError,
      showProgressBars: isFetchingOrganizations,
    },
  });

  const { mutateAsync: updateOrganization } = useUpdateOrganization({ table });
  const { mutateAsync: createOrganization } = useCreateOrganization({ table });

  return <MaterialReactTable table={table} />;
};

export default OrganizationTable;
