import React, { useCallback, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Box,
  Button,
  Typography,
  Grid,
  Paper,
  IconButton,
  Tabs,
  Tab,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Layout from "../../layout";
import {
  useGetEvidenceFile,
  useGetEvidenceFiles,
  useS3Upload,
} from "../../hooks/evidencefiles";
import { EvidenceFile } from "../../types/evidencefiles";
import { useNavigate, useParams } from "react-router-dom";
import { GridSaveAltIcon } from "@mui/x-data-grid";
import {
  useAcceptEvidenceRequest,
  useDeleteEvidenceRequest,
  useGetEvidenceRequest,
  useRequestFollowUpEvidenceRequest,
  useStartReviewEvidenceRequest,
  useUpdateEvidenceRequest,
} from "../../hooks/evidencerequests";
import ControlTable from "../../components/tables/controls";
import { TabPanel, a11yProps } from "../../helpers/functions";
import EngagementTable from "../../components/tables/engagements";
import { InsertDriveFile } from "@mui/icons-material";
import EvidenceRequestChatInterface from "../../components/chat";
import { EvidenceRequest } from "../../types/evidencerequests";
import VerifierSelector from "./components/verifier";
import UserSelector from "./components/users";
import { formatDueDate } from "../../components/engagements/edit/admin";
import { getUserInfo } from "../../helpers/user";

const AdminEditEvidenceRequestPage = () => {
  const user = getUserInfo();
  const isAdmin = user.role == "admin" || user.role == "auditor";

  const [files, setFiles] = useState<EvidenceFile[]>([]);
  const { id } = useParams<{ id: string }>();
  if (!id) {
    return <div>No ID provided</div>;
  }
  const [editedEvidenceRequest, setEditedEvidenceRequest] =
    useState<EvidenceRequest>();

  const updateEvidenceRequest = useUpdateEvidenceRequest();
  const deleteEvidenceRequest = useDeleteEvidenceRequest();

  const handleFieldChange = (
    fieldName: keyof EvidenceRequest,
    value: string
  ) => {
    setEditedEvidenceRequest((prevState) => {
      if (!prevState) return undefined;

      return {
        ...prevState,
        [fieldName]: value,
      };
    });
  };

  const handleUpdate = async () => {
    if (!editedEvidenceRequest) return;
    await updateEvidenceRequest.mutateAsync(editedEvidenceRequest);
  };

  const navigate = useNavigate();

  const handleDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Evidence Request?"
    );
    if (!editedEvidenceRequest) return;
    if (!confirmDelete) return;
    await deleteEvidenceRequest.mutateAsync(editedEvidenceRequest.id);
    navigate("/evidencerequests");
  };

  const {
    data: evidenceRequestData,
    isLoading: evidenceRequestLoading,
    error: evidenceRequestError,
    refetch,
  } = useGetEvidenceRequest({ id });
  const [descriptionMode, setDescriptionMode] = useState("edit");
  useEffect(() => {
    if (evidenceRequestData) {
      if (evidenceRequestData.evidencerequests[0]) {
        setEditedEvidenceRequest(evidenceRequestData.evidencerequests[0]);
        setDescriptionMode(
          evidenceRequestData.evidencerequests[0].description ? "view" : "edit"
        );
      }
    }
  }, [evidenceRequestData]);

  const [downloadUrls, setDownloadUrls] = useState<Record<string, string>>({});
  const { uploadToS3AndMarkUploaded, loading, error } = useS3Upload();
  const { fetchDownloadUrl, url, downloadLoading, downloadError } =
    useGetEvidenceFile();

  const downloadFile = useCallback(
    async (evidenceFileId: string) => {
      const url = await fetchDownloadUrl(evidenceFileId);
      if (url) {
        setDownloadUrls((prevUrls) => ({ ...prevUrls, [evidenceFileId]: url }));
      } else {
        console.error("Failed to get download URL");
      }
    },
    [fetchDownloadUrl]
  );

  const {
    data: myFiles,
    loading: fetchLoading,
    error: fetchError,
  } = useGetEvidenceFiles(id || "");

  useEffect(() => {
    if (myFiles) {
      setFiles((currentFiles) => {
        const allFiles = [...currentFiles, ...myFiles];
        const uniqueFilesMap = new Map();
        allFiles.forEach((file) => {
          uniqueFilesMap.set(file.id, file);
        });
        const uniqueFiles = Array.from(uniqueFilesMap.values());
        return uniqueFiles;
      });
    }
  }, [myFiles]);

  const calculateSHA256 = async (file: File): Promise<string> => {
    const buffer = await file.arrayBuffer();
    const digest = await crypto.subtle.digest("SHA-256", buffer);
    return Array.from(new Uint8Array(digest))
      .map((b) => b.toString(16).padStart(2, "0"))
      .join("");
  };

  const onFileSelect = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (!files || files.length === 0) return;

      const temporaryFiles = Array.from(files).map((file) => ({
        id: "",
        original_name: file.name,
        file_url: "",
        filetype: file.type,
        sha256: "",
        size: file.size,
        date_created: new Date(),
        progress: 0,
        isUploading: true,
      }));

      setFiles((currentFiles) => [...currentFiles, ...temporaryFiles]);

      const uploads = temporaryFiles.map(async (tempFile, index) => {
        try {
          const sha256 = await calculateSHA256(files[index]);
          const evidenceRequestId = id;

          const uploadResult = await uploadToS3AndMarkUploaded(
            evidenceRequestId,
            files[index],
            tempFile.original_name,
            sha256,
            tempFile.filetype
          );

          return {
            ...tempFile,
            id: uploadResult.evidence_file_id || "",
            file_url: uploadResult.s3_url || "unknown-url",
            sha256,
            progress: 100,
            isUploading: false,
          };
        } catch (error) {
          console.error("Upload error:", error);
          return { ...tempFile, isUploading: false };
        }
      });

      const results = await Promise.all(uploads);

      setFiles((currentFiles) =>
        currentFiles.map((file) => {
          const uploadedFile = results.find(
            (result) => result.original_name === file.original_name
          );
          return uploadedFile ? uploadedFile : file;
        })
      );
      refetch();
    },
    [id, uploadToS3AndMarkUploaded]
  );

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabId: number) => {
    setActiveTab(tabId);
  };

  const evidenceRequestInfo = (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => handleTabClick(newValue)}
            aria-label="Switch tabs"
          >
            <Tab label="Controls" {...a11yProps(0)} />
            <Tab label="Engagements" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={activeTab} index={0}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" component="div">
              Controls
            </Typography>

            {editedEvidenceRequest && (
              <>
                <ControlTable
                  parentObjectUrlKey="evidencerequests"
                  loadPartial={true}
                  parentObject={editedEvidenceRequest}
                  relatedChildFieldName="controls"
                />
              </>
            )}
          </Box>
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" component="div">
              Engagements
            </Typography>

            {editedEvidenceRequest && (
              <>
                <EngagementTable
                  parentObjectUrlKey="evidencerequests"
                  loadPartial={true}
                  parentObject={editedEvidenceRequest}
                  relatedChildFieldName="engagements"
                />
              </>
            )}
          </Box>
        </TabPanel>
      </Box>
    </>
  );

  const evidenceRequestFiles = (
    <>
      <Typography variant="h5" sx={{ p: 4, pb: 0 }}>
        Evidence Files
      </Typography>

      <List
        sx={{
          width: "100%",
          bgcolor: "background.paper",
          maxHeight: "50vh",
          overflow: "scroll",
        }}
      >
        {files.map((file) => (
          <ListItem
            key={file.id}
            secondaryAction={
              <>
                {!downloadUrls[file.id] ? (
                  <IconButton
                    edge="end"
                    aria-label="download"
                    onClick={() => downloadFile(file.id)}
                  >
                    <GridSaveAltIcon />
                  </IconButton>
                ) : (
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => window.open(downloadUrls[file.id], "_blank")}
                  >
                    Download
                  </Button>
                )}
              </>
            }
            divider
            sx={{ "&:hover": { bgcolor: "action.hover" } }}
          >
            <ListItemIcon>
              <InsertDriveFile />
            </ListItemIcon>
            <ListItemText
              primary={file.original_name}
              secondary={
                <>
                  {file.isUploading ? (
                    <CircularProgress size={20} />
                  ) : (
                    `Uploaded: ${new Date(file.date_created).toLocaleString(
                      undefined,
                      {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      }
                    )}`
                  )}
                </>
              }
            />
          </ListItem>
        ))}
      </List>

      <Box sx={{ textAlign: "right" }}>
        <Button sx={{ m: 2 }} variant="contained" component="label">
          Select Files to Upload
          <input type="file" hidden onChange={onFileSelect} multiple />
        </Button>
      </Box>
    </>
  );

  const toggleMode = () => {
    setDescriptionMode(descriptionMode === "view" ? "edit" : "view");
  };

  const formattedDate = editedEvidenceRequest?.date_created
    ? new Intl.DateTimeFormat(undefined, {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: true,
      }).format(new Date(editedEvidenceRequest?.date_created))
    : "Date not available";

  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  const startEvidenceRequestReview = useStartReviewEvidenceRequest(id);

  const startReview = async () => {
    const confirmSubmit = window.confirm(
      "Are you sure you want to set the Evidence Request to In review?"
    );
    if (!editedEvidenceRequest) return;
    if (!confirmSubmit) return;

    const result = await startEvidenceRequestReview();
    if (result) {
      refetch();
    }
  };

  const acceptEvidenceRequest = useAcceptEvidenceRequest(id);

  const approveEvidenceRequest = async () => {
    let note = "";
    const alertnote = window.prompt(
      "(Optional) Additional notes for accepting the Evidence Request"
    );

    if (alertnote) {
      note = alertnote;
    }

    const confirmSubmit = window.confirm(
      "Are you sure you want to accept this Evidence Request?"
    );
    if (!editedEvidenceRequest) return;
    if (!confirmSubmit) return;

    const result = await acceptEvidenceRequest(note);
    if (result) {
      refetch();
    }
  };

  const requestFollowUpEvidenceRequest = useRequestFollowUpEvidenceRequest(id);

  const requestFollowUp = async () => {
    let note = "";
    const alertnote = window.prompt(
      "(Optional) Reason for requesting follow-up"
    );
    if (!editedEvidenceRequest) return;
    if (alertnote) {
      note = alertnote;
    }

    const confirmSubmit = window.confirm(
      "Are you sure you want to request follow up on the Evidence Request?"
    );
    if (!editedEvidenceRequest) return;
    if (!confirmSubmit) return;

    const result = await requestFollowUpEvidenceRequest(note);
    if (result) {
      window.location.reload();
    }
  };

  const evidenceRequestInfoCard = (
    <>
      <Box
        sx={{
          m: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ textAlign: "left" }}>
          <Typography variant="h4" sx={{ mb: 0 }}>
            {editedEvidenceRequest?.title}
          </Typography>

          <Typography sx={{ mb: 2 }}>
            {editedEvidenceRequest?.status}
          </Typography>
        </Box>

        {isAdmin && (
          <Box sx={{ textAlign: "right" }}>
            <Button
              sx={{ m: 1 }}
              variant="contained"
              color="error"
              onClick={handleDelete}
            >
              Delete Evidence Request
            </Button>
            <Button
              sx={{ m: 1 }}
              variant="contained"
              color="primary"
              onClick={handleUpdate}
            >
              Update Evidence Request
            </Button>
          </Box>
        )}
      </Box>

      <Box sx={{ display: "flex", gap: 2 }}>
        {editedEvidenceRequest?.status == "Submitted For Review" && (
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none" }}
            onClick={() => startReview()}
          >
            Start Review
          </Button>
        )}

        {editedEvidenceRequest?.status == "In Review" && (
          <Button
            variant="contained"
            color="primary"
            sx={{ textTransform: "none" }}
            onClick={() => approveEvidenceRequest()}
          >
            Accept Evidence Request
          </Button>
        )}
        {(editedEvidenceRequest?.status == "In Review" ||
          editedEvidenceRequest?.status == "Accepted") && (
          <Button
            variant="contained"
            sx={{ textTransform: "none", backgroundColor: "grey.600" }}
            onClick={() => requestFollowUp()}
          >
            Request Follow Up
          </Button>
        )}
      </Box>

      {editedEvidenceRequest && (
        <>
          <Box sx={{ p: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button size="small" variant="outlined" onClick={toggleExpand}>
              {isExpanded ? "Show Less" : "Show More"}
            </Button>
          </Box>

          <Box
            sx={{
              maxHeight: isExpanded ? "none" : "100px",
              border: 1,
              borderRadius: 3,
              borderColor: "divider",
              overflow: "hidden",
            }}
          >
            <Card sx={{ width: "100%", mb: 2 }}>
              <CardContent>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label="Title"
                  disabled={!isAdmin}
                  value={editedEvidenceRequest.title}
                  onChange={(e) => handleFieldChange("title", e.target.value)}
                />

                <Button
                  onClick={toggleMode}
                  variant="contained"
                  disabled={!isAdmin}
                  startIcon={
                    descriptionMode === "view" ? (
                      <EditIcon />
                    ) : (
                      <VisibilityIcon />
                    )
                  }
                  sx={{
                    margin: 2,
                    marginLeft: 0,
                  }}
                >
                  {descriptionMode === "view"
                    ? "Edit Description"
                    : "Preview Description"}
                </Button>
                {descriptionMode === "edit" ? (
                  <TextField
                    fullWidth
                    multiline
                    label="Evidence Request Description"
                    variant="outlined"
                    value={editedEvidenceRequest.description}
                    onChange={(e) =>
                      handleFieldChange("description", e.target.value)
                    }
                    margin="normal"
                  />
                ) : (
                  <Typography
                    component="div"
                    variant="body1"
                    sx={{
                      lineHeight: "1em",
                      padding: 2,
                      border: 2,
                      borderColor: "divider",
                      borderRadius: 3,
                      overflow: "wrap",
                    }}
                  >
                    <ReactMarkdown>
                      {editedEvidenceRequest.description}
                    </ReactMarkdown>
                  </Typography>
                )}

                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  multiline
                  rows={4}
                  label="Notes"
                  disabled={!isAdmin}
                  value={editedEvidenceRequest.notes}
                  onChange={(e) => handleFieldChange("notes", e.target.value)}
                />
                <Box
                  sx={{
                    border: 1,
                    borderColor: "divider",
                    padding: 2,
                    marginTop: 2,
                    marginBottom: 2,
                  }}
                >
                  <TextField
                    label="Due Date"
                    disabled={!isAdmin}
                    type="date"
                    variant="outlined"
                    fullWidth
                    value={formatDueDate(editedEvidenceRequest.due_date)}
                    onChange={(e) =>
                      setEditedEvidenceRequest({
                        ...editedEvidenceRequest,
                        due_date: e.target.value
                          ? new Date(e.target.value)
                          : null,
                      })
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    margin="normal"
                  />
                </Box>
              </CardContent>
            </Card>
          </Box>
        </>
      )}
    </>
  );

  return (
    <Layout
      pageTitle="Evidence Request"
      parentUrl="/evidencerequests"
      parentPageName="All Evidence Requests"
      engagements={editedEvidenceRequest?.engagements}
    >
      <Box sx={{ p: 3, color: "black" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              margin: 2,
              borderRadius: 3,
            }}
          >
            {evidenceRequestInfoCard}

            {editedEvidenceRequest && setEditedEvidenceRequest && (
              <Box sx={{ pt: 4, display: "flex", width: "100%", gap: 1 }}>
                <Box sx={{ flex: 1 }}>
                  <VerifierSelector
                    editedEvidenceRequest={editedEvidenceRequest}
                    setEditedEvidenceRequest={setEditedEvidenceRequest}
                    editMode={isAdmin}
                  />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <UserSelector
                    editedEvidenceRequest={editedEvidenceRequest}
                    setEditedEvidenceRequest={setEditedEvidenceRequest}
                    editMode={isAdmin}
                  />
                </Box>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              color: "black",
              border: 2,
              borderColor: "divider",
              margin: 2,
              borderRadius: 3,
            }}
          >
            <Grid container spacing={2} padding={2}>
              <Grid item sm={12} md={5}>
                <Paper sx={{ padding: 1, height: "65vh" }}>
                  <Typography variant="h5" sx={{ p: 4, pb: 0 }}>
                    Conversation
                  </Typography>{" "}
                  <EvidenceRequestChatInterface
                    refetch={refetch}
                    conversationId={
                      evidenceRequestData?.evidencerequests[0]
                        ?.message_history ?? ""
                    }
                  />
                </Paper>
              </Grid>
              <Grid item sm={12} md={7}>
                <Paper sx={{ padding: 1, height: "65vh" }}>
                  <div>{evidenceRequestFiles}</div>
                </Paper>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              width: "100%",
              maxWidth: "100%",
              color: "black",
              border: 2,
              borderColor: "divider",
              margin: 2,
              borderRadius: 3,
            }}
          >
            {evidenceRequestInfo}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default AdminEditEvidenceRequestPage;
