import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Menu, MenuItem, ListItemText, Box } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Engagement } from "../types/engagements";

interface EngagementDropdownProps {
  engagements: Engagement[];
}

const EngagementDropdown: React.FC<EngagementDropdownProps> = ({
  engagements,
}) => {
  const lastViewed = JSON.parse(
    localStorage.getItem("last_viewed_engagement") || "{}"
  );

  const defaultEngagement =
    engagements.find((e) => e.id === lastViewed.id) || engagements[0];

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedEngagement, setSelectedEngagement] =
    useState<Engagement>(defaultEngagement);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setSelectedEngagement(defaultEngagement);
  }, [engagements, lastViewed, defaultEngagement]);

  return (
    <Box>
      <Button
        sx={{ p: 0 }}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        endIcon={<MoreVertIcon />}
      >
        {selectedEngagement?.name || "Select Engagement"}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {engagements.map((engagement) => (
          <MenuItem
            key={engagement.id}
            sx={{ color: "#000000" }}
            onClick={() => {
              setSelectedEngagement(engagement);
              handleClose();
            }}
          >
            <Link
              to={`/engagement/${engagement.id}`}
              style={{
                textDecoration: "none",
                color: "#000000",
              }}
            >
              <ListItemText
                sx={{ color: "#000000" }}
                primary={engagement.name}
              />
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default EngagementDropdown;
