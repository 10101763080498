import { useQuery } from "@tanstack/react-query";
import { apiClient } from "../../api/apiClient";
import axios from "axios";
import { toast } from "react-toastify";

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

interface Change {
  old: string | null;
  new: string | null;
}

interface LogEntry {
  changes: Record<string, Change>;
  timestamp: string;
}

interface EngagementHistoryQueryResult {
  logEntries: LogEntry[];
}

export function useGetEngagementHistory({ id }: { id: string }) {
  return useQuery<EngagementHistoryQueryResult>({
    queryKey: ["engagementHistory", id],
    queryFn: async () => {
      try {
        const url = `/api/engagementshistory/${id}`;
        const response = await apiClient.get(url);
        return {
          logEntries: response.data as LogEntry[],
        };
      } catch (error) {
        //
        return { logEntries: [] };
      }
    },
    refetchOnWindowFocus: true,
  });
}

interface EngagementHistoryTableProps {
  engagementId: string;
}

const EngagementHistoryTable: React.FC<EngagementHistoryTableProps> = ({
  engagementId,
}) => {
  const { data, isLoading, error } = useGetEngagementHistory({
    id: engagementId,
  });

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error loading data</div>;

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Timestamp</TableCell>
            <TableCell>Field</TableCell>
            <TableCell>Old Value</TableCell>
            <TableCell>New Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.logEntries.map((entry, index) => (
            <React.Fragment key={index}>
              {Object.entries(entry.changes).map(([field, change], idx) => (
                <TableRow key={`${index}-${idx}`}>
                  <TableCell>{entry.timestamp}</TableCell>
                  <TableCell>{field}</TableCell>
                  <TableCell>{change.old}</TableCell>
                  <TableCell>{change.new}</TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EngagementHistoryTable;
