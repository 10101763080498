import React, { useState, useEffect, FunctionComponent } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  ListItem,
  ListItemText,
  IconButton,
  Autocomplete,
  Box,
  Typography,
  Tabs,
  Tab,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import PaginatedModalComponent from "../../edit/PaginatedModalComponent";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Engagement } from "../../../types/engagements";

import { User } from "../../../types/users";
import {
  useToggleCompleteEngagement,
  useCreateEngagement,
  useDeleteEngagement,
  useGetEngagement,
  useSearchEngagements,
  useUpdateEngagement,
} from "../../../hooks/engagements";
import { useSearchUsers } from "../../../hooks/users";
import { Scope } from "../../../types/scopes";
import { useSearchScopes } from "../../../hooks/scopes";
import { EvidenceRequest } from "../../../types/evidencerequests";
import { useSearchEvidenceRequests } from "../../../hooks/evidencerequests";

import CloseIcon from "@mui/icons-material/Close";
import Layout from "../../../layout";
import EvidenceRequestTable from "../../tables/evidencerequests";
import { useExportRelatedEvidenceRequests } from "../../../hooks/evidencefiles";
import { toast } from "react-toastify";
import { TabPanel, a11yProps } from "../../../helpers/functions";
import SecondaryControlTable from "../../tables/controls/secondary";
import { Organization } from "../../../types/organizations";
import {
  useGetAllOrganizations,
  useSearchOrganizations,
} from "../../../hooks/organizations";
import { GridArrowDownwardIcon } from "@mui/x-data-grid";
import { getUserInfo } from "../../../helpers/user";
import EngagementHistoryTable from "../../history/EngagementHistory";
import { ClearIcon } from "@mui/x-date-pickers";

export const defaultEngagement: Partial<Engagement> = {
  name: "",
  description: "",
  due_date: null,
  organization: null,
  ticket_url: "",
  last_updated: new Date(),
  evidence_requests: [],
  users: [],
  scopes: [],
  prev_engagements: [],
  peer_engagements: [],
};

export function formatDueDate(due_date: Date | string | null | undefined) {
  if (!due_date) return "";

  if (due_date instanceof Date) {
    return due_date.toISOString().substring(0, 10);
  }

  try {
    const date = new Date(due_date);
    return date.toISOString().substring(0, 10);
  } catch (error) {
    console.error("Error formatting due_date:", error);
    return "";
  }
}

interface EditEngagementModalProps {
  engagement?: Partial<Engagement> | null;
  isOpen?: boolean;
  mode?: "modal" | "page";
  onClose?: () => void;
}

const AdminEditEngagementModal: FunctionComponent<EditEngagementModalProps> = ({
  engagement = defaultEngagement,
  isOpen = true,
  mode = "modal",
  onClose = () => {},
}) => {
  const navigate = useNavigate();
  const [editedEngagement, setEditedEngagement] = useState<
    Engagement | Partial<Engagement>
  >(engagement || defaultEngagement);
  const isPageMode = mode === "page";

  const { id } = useParams<{ id: string }>();
  const [loadingError, setLoadingError] = useState(false);
  const [
    evidenceRequestsTablePartialMode,
    setEvidenceRequestsTablePartialMode,
  ] = useState(true);
  const [descriptionMode, setDescriptionMode] = useState("edit");

  const user = getUserInfo();
  const isAdmin = user.role === "admin";
  const isAuditor = user.role === "auditor";

  if (id) {
    const { data, isLoading: isFetching } = useGetEngagement({ id });
    useEffect(() => {
      if (mode === "page" && id && data && !isFetching) {
        if (data[0].engagements.length === 0) {
          setLoadingError(true);
        } else {
          setEditedEngagement(data[0].engagements[0]);
          setDescriptionMode(
            data[0].engagements[0].description ? "view" : "edit"
          );
        }
      }
    }, [id, mode, data, isFetching]);
  }

  useEffect(() => {
    if (editedEngagement) {
      localStorage.setItem(
        "last_viewed_engagement",
        JSON.stringify(editedEngagement)
      );
    }
  }, [editedEngagement]);

  const [searchQueryUser, setSearchQueryUser] = useState("");
  const [searchResultsUser, setSearchResultsUser] = useState<User[]>([]);

  const [searchQueryPrevEngagements, setSearchQueryPrevEngagements] =
    useState("");

  const [searchResultsPrevEngagements, setSearchResultsPrevEngagements] =
    useState<Engagement[]>([]);

  const [searchQueryPeerEngagements, setSearchQueryPeerEngagements] =
    useState("");

  const [searchResultsPeerEngagements, setSearchResultsPeerEngagements] =
    useState<Engagement[]>([]);

  const [searchQueryEvidenceRequests, setSearchQueryEvidenceRequests] =
    useState("");

  const [searchResultsEvidenceRequests, setSearchResultsEvidenceRequests] =
    useState<EvidenceRequest[]>([]);

  const [searchQueryScope, setSearchQueryScope] = useState("");
  const [searchResultsScope, setSearchResultsScope] = useState<Scope[]>([]);

  const updateEngagement = useUpdateEngagement();

  const createEngagement = useCreateEngagement();

  const { data: userSearchResults, refetch: refetchUsers } = useSearchUsers({
    q: searchQueryUser,
    organization: editedEngagement.organization?.id || "",
    intent: "users",
  });

  const { data: scopeSearchResults, refetch: refetchScopes } = useSearchScopes({
    q: searchQueryScope,
  });

  const {
    data: prevEngagementsSearchResults,
    refetch: refetchPrevEngagements,
  } = useSearchEngagements({
    q: searchQueryPrevEngagements,
    organization: editedEngagement.organization?.id,
  });

  const {
    data: peerEngagementsSearchResults,
    refetch: refetchPeerEngagements,
  } = useSearchEngagements({
    q: searchQueryPeerEngagements,
    organization: editedEngagement.organization?.id,
  });

  const {
    data: evidenceRequestSearchResults,
    refetch: refetchEvidenceRequests,
  } = useSearchEvidenceRequests({
    q: searchQueryEvidenceRequests,
    organization: editedEngagement.organization?.id,
  });

  const toggleMode = () => {
    setDescriptionMode(descriptionMode === "view" ? "edit" : "view");
  };

  const toggleEvidenceRequestsTablePartialMode = () => {
    setEvidenceRequestsTablePartialMode(!evidenceRequestsTablePartialMode);
  };

  const exportRelatedEvidenceRequests = useExportRelatedEvidenceRequests(
    "engagement",
    editedEngagement.id
  );

  const handleExportRelatedEvidenceRequests = async () => {
    try {
      await exportRelatedEvidenceRequests();
    } catch (error) {
      console.error(error);
      toast.error("Failed to export");
    } finally {
    }
  };

  useEffect(() => {
    if (userSearchResults) {
      setSearchResultsUser(userSearchResults[0].users);
    }
    if (scopeSearchResults) {
      setSearchResultsScope(scopeSearchResults[0].scopes);
    }
    if (prevEngagementsSearchResults) {
      setSearchResultsPrevEngagements(
        prevEngagementsSearchResults[0].engagements
      );
    }

    if (peerEngagementsSearchResults) {
      setSearchResultsPeerEngagements(
        peerEngagementsSearchResults[0].engagements
      );
    }

    if (evidenceRequestSearchResults) {
      setSearchResultsEvidenceRequests(
        evidenceRequestSearchResults[0].evidencerequests
      );
    }
  }, [
    userSearchResults,
    scopeSearchResults,
    prevEngagementsSearchResults,
    peerEngagementsSearchResults,
    evidenceRequestSearchResults,
  ]);

  useEffect(() => {
    refetchScopes();
  }, [searchQueryScope, refetchScopes]);

  useEffect(() => {
    refetchPrevEngagements();
  }, [
    searchQueryPrevEngagements,
    refetchPrevEngagements,
    editedEngagement.organization?.id,
  ]);

  useEffect(() => {
    refetchPeerEngagements();
  }, [
    searchQueryPeerEngagements,
    refetchPeerEngagements,
    editedEngagement.organization?.id,
  ]);

  useEffect(() => {
    refetchEvidenceRequests();
  }, [
    searchQueryEvidenceRequests,
    refetchEvidenceRequests,
    editedEngagement.organization?.id,
  ]);

  const handleAddUser = (user: User): void => {
    let updatedUsers: User[];
    updatedUsers = [...(editedEngagement.users ?? []), user];
    setEditedEngagement((prev) => ({ ...prev, users: updatedUsers }));
  };

  const handleRemoveUser = (user: User): void => {
    const updatedUsers: User[] =
      editedEngagement.users?.filter((u) => u.id !== user.id) ?? [];

    setEditedEngagement((prev) => ({ ...prev, users: updatedUsers }));
  };

  const handleAddEvidenceRequest = (
    evidence_request: EvidenceRequest
  ): void => {
    let updatedEvidenceReqeuests: EvidenceRequest[];
    updatedEvidenceReqeuests = [
      ...(editedEngagement.evidence_requests ?? []),
      evidence_request,
    ];
    setEditedEngagement((prev) => ({
      ...prev,
      evidence_requests: updatedEvidenceReqeuests,
    }));
  };

  const handleRemoveEvidenceRequest = (
    evidence_request: EvidenceRequest
  ): void => {
    const updatedEvidenceRequests: EvidenceRequest[] =
      editedEngagement.evidence_requests?.filter(
        (e) => e.id !== evidence_request.id
      ) ?? [];
    setEditedEngagement((prev) => ({
      ...prev,
      evidence_requests: updatedEvidenceRequests,
    }));
  };

  const handleRemoveScope = (scope: Scope): void => {
    const updatedScopes: Scope[] =
      editedEngagement.scopes?.filter((s) => s.id !== scope.id) ?? [];

    setEditedEngagement((prev) => ({ ...prev, scopes: updatedScopes }));
  };

  const handleAddScope = (scope: Scope): void => {
    let updatedScopes: Scope[];
    updatedScopes = [...(editedEngagement.scopes ?? []), scope];
    setEditedEngagement((prev) => ({ ...prev, scopes: updatedScopes }));
  };

  const handleAddPrevEngagement = (prev_engagement: Engagement): void => {
    let updatedPrevEngagements: Engagement[];
    updatedPrevEngagements = [
      ...(editedEngagement.prev_engagements ?? []),
      prev_engagement,
    ];

    setEditedEngagement((prev) => ({
      ...prev,
      prev_engagements: updatedPrevEngagements,
    }));
  };

  const handleRemovePrevEngagement = (prev_engagement: Engagement): void => {
    const updatedPrevEngagements: Engagement[] =
      editedEngagement.prev_engagements?.filter(
        (p) => p.id !== prev_engagement.id
      ) ?? [];

    setEditedEngagement((prev) => ({
      ...prev,
      prev_engagements: updatedPrevEngagements,
    }));
  };

  const handleAddPeerEngagement = (peer_engagement: Engagement): void => {
    let updatedPeerEngagements: Engagement[];
    updatedPeerEngagements = [
      ...(editedEngagement.peer_engagements ?? []),
      peer_engagement,
    ];
    setEditedEngagement((prev) => ({
      ...prev,
      peer_engagements: updatedPeerEngagements,
    }));
  };

  const handleRemovePeerEngagement = (peer_engagement: Engagement): void => {
    const updatedPeerEngagements: Engagement[] =
      editedEngagement.peer_engagements?.filter(
        (p) => p.id !== peer_engagement.id
      ) ?? [];

    setEditedEngagement((prev) => ({
      ...prev,
      peer_engagements: updatedPeerEngagements,
    }));
  };

  const renderPeerEngagement = (engagement: Engagement) => (
    <ListItem className="modalEditListItem" key={engagement.id}>
      <ListItemText
        primary={
          <Link to={`/engagement/${engagement.id}`}>{engagement.name}</Link>
        }
      />

      <IconButton
        edge="end"
        aria-label="remove"
        onClick={() => handleRemovePeerEngagement(engagement)}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </ListItem>
  );

  const [searchResultsOrg, setSearchResultsOrg] = useState<Organization[]>([]);
  const [searchQueryOrg, setSearchQueryOrg] = useState("");

  useEffect(() => {
    refetchUsers();
  }, [searchQueryUser, refetchUsers, editedEngagement.organization?.id]);

  useEffect(() => {
    if (!editedEngagement.id) {
      setEditedEngagement((prev) => ({
        ...prev,
        users: [],
        evidence_requests: [],
        peer_engagements: [],
        prev_engagements: [],
      }));
      setSearchResultsUser([]);
      setSearchResultsEvidenceRequests([]);
      setSearchResultsPeerEngagements([]);
      setSearchResultsPrevEngagements([]);
    }
  }, [editedEngagement.organization?.id]);

  const { data: orgSearchResults, refetch: refetchOrgs } =
    useSearchOrganizations({
      q: searchQueryOrg,
    });

  useEffect(() => {
    refetchOrgs();
  }, [searchQueryOrg, refetchOrgs]);

  useEffect(() => {
    if (orgSearchResults) {
      setSearchResultsOrg(orgSearchResults[0].organizations);
    }
  }, [orgSearchResults]);

  const handleAddOrg = (org: Organization): void => {
    if (org) {
      setEditedEngagement((prev) => ({ ...prev, organization: org }));
    }
  };

  const handleSubmit = async () => {
    try {
      if ("id" in editedEngagement) {
        await updateEngagement.mutateAsync(editedEngagement as Engagement);
        onClose();
      } else {
        await createEngagement.mutateAsync(editedEngagement as Engagement);
        onClose();
      }
    } catch (error) {}
  };

  const toggleEngagementCompleted = useToggleCompleteEngagement();

  const toggleComplete = async () => {
    let confirm = "";
    if (editedEngagement.completed) {
      confirm = "Are you sure you want to mark this Engagement as incomplete?";
    } else {
      confirm = "Are you sure you want to mark this Engagement as completed?";
    }
    const confirmComplete = window.confirm(confirm);

    if (confirmComplete) {
      try {
        await toggleEngagementCompleted.mutateAsync(
          editedEngagement.id as Engagement["id"]
        );
      } catch (error) {}
    }
  };

  const deleteEngagement = useDeleteEngagement();
  const triggerDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Engagement?"
    );
    if (confirmDelete) {
      try {
        await deleteEngagement.mutateAsync(
          editedEngagement.id as Engagement["id"]
        );

        navigate("/engagements");
      } catch (error) {}
    }
  };

  const renderEvidenceRequest = (evidenceRequest: EvidenceRequest) => (
    <ListItem className="modalEditListItem" key={evidenceRequest.id}>
      <ListItemText
        primary={
          <Link to={`/evidence-request/${evidenceRequest.id}`}>
            {evidenceRequest.title}
          </Link>
        }
      />

      <IconButton
        edge="end"
        aria-label="remove"
        onClick={() => handleRemoveEvidenceRequest(evidenceRequest)}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </ListItem>
  );

  const renderUser = (user: User) => (
    <ListItem className="modalEditListItem" key={user.id}>
      <ListItemText primary={user.name} />

      <IconButton
        edge="end"
        aria-label="remove"
        onClick={() => handleRemoveUser(user)}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </ListItem>
  );

  const renderScope = (scope: Scope) => (
    <ListItem className="modalEditListItem" key={scope.id}>
      <ListItemText primary={scope.title} />

      <IconButton
        edge="end"
        aria-label="remove"
        onClick={() => handleRemoveScope(scope)}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </ListItem>
  );

  const renderPrevEngagement = (engagement: Engagement) => (
    <ListItem className="modalEditListItem" key={engagement.id}>
      <ListItemText
        primary={
          <Link to={`/engagement/${engagement.id}`}>{engagement.name}</Link>
        }
      />

      <IconButton
        edge="end"
        aria-label="remove"
        onClick={() => handleRemovePrevEngagement(engagement)}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </ListItem>
  );

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabId: number) => {
    setActiveTab(tabId);
  };

  const pageModeContent = (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => handleTabClick(newValue)}
            aria-label="Switch tabs"
          >
            <Tab label="Evidence Requests" {...a11yProps(0)} />
            <Tab label="Controls" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={activeTab} index={0}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" component="div">
              Evidence Requests
            </Typography>

            {editedEngagement.id && (
              <EvidenceRequestTable
                parentObjectUrlKey="engagements"
                loadPartial={true}
                parentObject={editedEngagement}
                relatedChildFieldName="evidence_requests"
              />
            )}
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" component="div">
              Controls
            </Typography>

            {editedEngagement.id && (
              <SecondaryControlTable
                parentObjectUrlKey="engagements"
                throughObjectKey="controlsets"
                parentObject={editedEngagement}
                relatedChildFieldName="controls"
              />
            )}
          </Box>
        </TabPanel>
      </Box>
    </>
  );

  const content = (
    <>
      <Accordion defaultExpanded={mode == "modal" ? true : false}>
        {mode == "page" && (
          <AccordionSummary
            expandIcon={<GridArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography sx={{ p: 2 }}> {editedEngagement.name} </Typography>
          </AccordionSummary>
        )}
        {mode == "page" && (
          <Box sx={{ paddingRight: "6vh" }}>
            <DialogActions>
              {editedEngagement.completed ? (
                <Button
                  variant="contained"
                  color="warning"
                  onClick={toggleComplete}
                >
                  Mark Incomplete
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  onClick={toggleComplete}
                >
                  Mark Completed
                </Button>
              )}

              <Button variant="contained" color="error" onClick={triggerDelete}>
                Delete Engagement
              </Button>

              <Button variant="contained" onClick={handleSubmit}>
                Update Engagement
              </Button>
            </DialogActions>
          </Box>
        )}

        <AccordionDetails>
          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <TextField
              fullWidth
              label="Engagement Name"
              variant="outlined"
              disabled={isAuditor}
              value={editedEngagement.name || ""}
              onChange={(e) =>
                setEditedEngagement({
                  ...editedEngagement,
                  name: e.target.value,
                })
              }
              margin="normal"
            />
          </Box>

          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            {isAdmin && (
              <Button
                onClick={toggleMode}
                variant="contained"
                startIcon={
                  descriptionMode === "view" ? <EditIcon /> : <VisibilityIcon />
                }
                sx={{
                  margin: 2,
                  marginLeft: 0,
                }}
              >
                {descriptionMode === "view"
                  ? "Edit Description"
                  : "Preview Description"}
              </Button>
            )}

            {descriptionMode === "edit" ? (
              <TextField
                fullWidth
                disabled={isAuditor}
                multiline
                label="Engagement Description"
                variant="outlined"
                value={editedEngagement.description || ""}
                onChange={(e) =>
                  setEditedEngagement({
                    ...editedEngagement,
                    description: e.target.value,
                  })
                }
                margin="normal"
              />
            ) : (
              <Typography
                component="div"
                variant="body1"
                sx={{
                  lineHeight: "1em",
                  padding: 2,
                  border: 2,
                  borderColor: "divider",
                  borderRadius: 3,
                  overflow: "wrap",
                }}
              >
                <ReactMarkdown>{editedEngagement.description}</ReactMarkdown>
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <TextField
              label="Due Date"
              type="date"
              variant="outlined"
              disabled={isAuditor}
              fullWidth
              value={formatDueDate(editedEngagement.due_date) || ""}
              onChange={(e) =>
                setEditedEngagement({
                  ...editedEngagement,
                  due_date: e.target.value ? new Date(e.target.value) : null,
                })
              }
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
            />
          </Box>

          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <Typography variant="h6">Organization</Typography>
            {editedEngagement.organization?.id && (
              <Box display="flex" alignItems="center">
                <TextField
                  fullWidth
                  label="Organization"
                  variant="outlined"
                  value={editedEngagement.organization.name}
                  disabled={defaultEngagement && "id" in defaultEngagement}
                  margin="normal"
                />

                {!editedEngagement.date_created && (
                  <IconButton
                    onClick={() => {
                      setEditedEngagement((prev) => ({
                        ...prev,
                        organization: null,
                      }));
                    }}
                    sx={{ ml: 1 }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
              </Box>
            )}
            {!editedEngagement.organization?.id && isAdmin && (
              <Autocomplete
                options={searchResultsOrg || []}
                getOptionLabel={(option) => option.name || ""}
                filterSelectedOptions
                onChange={(event, newValue: Organization | null) => {
                  if (newValue) {
                    handleAddOrg(newValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Organizations"
                    variant="outlined"
                    onChange={(e) => setSearchQueryOrg(e.target.value)}
                  />
                )}
              />
            )}
          </Box>

          {/* users on engagements */}
          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <Typography variant="h6">Users</Typography>
            <Autocomplete
              multiple
              options={searchResultsUser}
              disabled={!editedEngagement.organization?.id}
              getOptionLabel={(option) => option.name + " - " + option.email}
              filterSelectedOptions
              onChange={(event, newValue) => {
                newValue.forEach((user) => {
                  if (!editedEngagement.users?.some((u) => u.id === user.id)) {
                    handleAddUser(user);
                  }
                });
              }}
              renderTags={() => {
                return null;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Users"
                  variant="outlined"
                  onChange={(e) => setSearchQueryUser(e.target.value)}
                />
              )}
            />
            <PaginatedModalComponent
              items={editedEngagement.users?.map((user) => user) ?? []}
              renderItem={renderUser}
            />
          </Box>

          {/* evidence requests */}

          {!isPageMode && (
            <Box
              sx={{
                border: 1,
                borderColor: "divider",
                padding: 2,
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              <Typography variant="h6">Evidence Requests</Typography>
              <Autocomplete
                multiple
                disabled={!editedEngagement.organization?.id || isAuditor}
                options={searchResultsEvidenceRequests}
                getOptionLabel={(option) => option.title}
                filterSelectedOptions
                onChange={(event, newValue) => {
                  newValue.forEach((evidence_request) => {
                    if (
                      !editedEngagement.evidence_requests?.some(
                        (e) => e.id === evidence_request.id
                      )
                    ) {
                      handleAddEvidenceRequest(evidence_request);
                    }
                  });
                }}
                renderTags={() => {
                  return null;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Evidence Requests"
                    variant="outlined"
                    onChange={(e) =>
                      setSearchQueryEvidenceRequests(e.target.value)
                    }
                  />
                )}
              />
              <PaginatedModalComponent
                items={
                  editedEngagement.evidence_requests?.map(
                    (evidenceRequest) => evidenceRequest
                  ) ?? []
                }
                renderItem={renderEvidenceRequest}
              />
            </Box>
          )}

          {/* prev engagements */}
          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <Typography variant="h6">Previous Engagements</Typography>

            <Autocomplete
              multiple
              disabled={!editedEngagement.organization?.id || isAuditor}
              options={searchResultsPrevEngagements}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              onChange={(event, newValue) => {
                newValue.forEach((prev_engagement) => {
                  if (
                    !editedEngagement.prev_engagements?.some(
                      (p) => p.id === prev_engagement.id
                    )
                  ) {
                    handleAddPrevEngagement(prev_engagement);
                  }
                });
              }}
              renderTags={() => {
                return null;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Previous Engagements"
                  variant="outlined"
                  onChange={(e) =>
                    setSearchQueryPrevEngagements(e.target.value)
                  }
                />
              )}
            />
            <PaginatedModalComponent
              items={
                editedEngagement.prev_engagements?.map(
                  (prev_engagement) => prev_engagement
                ) ?? []
              }
              renderItem={renderPrevEngagement}
            />
          </Box>

          {/* peer engagements */}
          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <Typography variant="h6">Peer Engagements</Typography>

            <Autocomplete
              multiple
              disabled={!editedEngagement.organization?.id || isAuditor}
              options={searchResultsPeerEngagements}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              onChange={(event, newValue) => {
                newValue.forEach((peer_engagement) => {
                  if (
                    !editedEngagement.peer_engagements?.some(
                      (p) => p.id === peer_engagement.id
                    )
                  ) {
                    handleAddPeerEngagement(peer_engagement);
                  }
                });
              }}
              renderTags={() => {
                return null;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Peer Engagements"
                  variant="outlined"
                  onChange={(e) =>
                    setSearchQueryPeerEngagements(e.target.value)
                  }
                />
              )}
            />
            <PaginatedModalComponent
              items={
                editedEngagement.peer_engagements?.map(
                  (peer_engagement) => peer_engagement
                ) ?? []
              }
              renderItem={renderPeerEngagement}
            />
          </Box>
          {/* scopes on engagements */}
          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <Typography variant="h6">Scopes</Typography>

            <Autocomplete
              multiple
              options={searchResultsScope}
              getOptionLabel={(option) => option.title}
              filterSelectedOptions
              disabled={isAuditor}
              onChange={(event, newValue) => {
                newValue.forEach((scope) => {
                  if (
                    !editedEngagement.scopes?.some((s) => s.id === scope.id)
                  ) {
                    handleAddScope(scope);
                  }
                });
              }}
              renderTags={() => {
                return null;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Scopes"
                  variant="outlined"
                  onChange={(e) => setSearchQueryScope(e.target.value)}
                />
              )}
            />
            <PaginatedModalComponent
              items={editedEngagement.scopes?.map((scope) => scope) ?? []}
              renderItem={renderScope}
            />
          </Box>

          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <TextField
              fullWidth
              disabled={isAuditor}
              label="Ticket URL"
              variant="outlined"
              value={editedEngagement.ticket_url || ""}
              onChange={(e) =>
                setEditedEngagement({
                  ...editedEngagement,
                  ticket_url: e.target.value,
                })
              }
              margin="normal"
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );

  if (loadingError) {
    return (
      <Layout pageTitle="" parentUrl="" parentPageName="">
        <Typography variant="h5" sx={{ color: "black", padding: 3 }}>
          Engagement not found
        </Typography>
        ;
      </Layout>
    );
  } else
    return isPageMode ? (
      <Layout
        pageTitle="Engagement"
        parentUrl="/engagements"
        parentPageName="Engagements"
      >
        <Box>
          <Box
            sx={{
              display: "flex",

              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "100%",
                color: "black",
                border: 2,
                borderColor: "divider",
                margin: 2,

                borderRadius: 2,
              }}
            >
              {content}
            </Box>
            <Box
              sx={{
                width: "100%",
                maxWidth: "100%",
                color: "black",
                border: 2,
                borderColor: "divider",
                margin: 2,
                borderRadius: 3,
              }}
            >
              {pageModeContent}
            </Box>
            {editedEngagement.id && (
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  color: "black",
                  border: 2,
                  borderColor: "divider",
                  margin: 2,
                  borderRadius: 3,
                }}
              >
                <EngagementHistoryTable engagementId={editedEngagement.id} />
              </Box>
            )}
          </Box>
        </Box>
      </Layout>
    ) : (
      <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography fontSize={25}>{editedEngagement.name}</Typography>
          <CloseIcon
            onClick={onClose}
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              fontSize: 30,
              zIndex: 999,
            }}
          />
        </DialogTitle>

        {content}

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    );
};

export default AdminEditEngagementModal;
