import React from "react";
import { Navigate } from "react-router-dom";
import AdminEditControlSetModal from "./admin";
import { getUserInfo } from "../../../helpers/user";
import Error from "../../../pages/error";

const EditControlSetPageRouteWrapper = () => {
  const userInfo = getUserInfo();

  if (userInfo.role === "admin" || userInfo.role === "auditor") {
    return <AdminEditControlSetModal mode="page" />;
  } else if (userInfo.role === "user") {
    return <Error />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default EditControlSetPageRouteWrapper;
