import React, { useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Modal, TextField, Typography } from "@mui/material";
import Layout from "../../layout";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useGetControlSets,
  useCreateControlSet,
  useUpdateControlSet,
  useDeleteControlSet,
  useExportControlSets,
  useDeleteControlSets,
} from "../../hooks/controlsets";
import { ControlSet } from "../../types/controlsets";
import { GridCloseIcon } from "@mui/x-data-grid";
import AdminEditControlSetModal, {
  defaultControlSet,
} from "../../components/controlsets/edit/admin";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ImportFromCSV from "../../components/csv/importFromCSV";
import { getUserInfo } from "../../helpers/user";
import { baseURL } from "../../api/apiClient";

const AdminControlSetPage = () => {
  const user = getUserInfo();
  const isAdmin = user.role === "admin";

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);
  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [columnFilters]);

  const { mutateAsync: deleteControlSet } = useDeleteControlSet();

  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [currentControlSet, setCurrentControlSet] = useState<ControlSet | null>(
    null
  );

  const [isCreatingControlSet, setIsCreatingControlSet] =
    useState<boolean>(false);

  const openEditModal = (controlset: ControlSet) => {
    setCurrentControlSet(controlset);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setCurrentControlSet(null);
  };

  const openCreateModal = () => {
    setIsCreatingControlSet(true);
    setEditModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreatingControlSet(false);
    setCurrentControlSet(null);
  };

  const {
    data: controlsetQueryResults = [],
    isError: isLoadingControlSetsError,
    isFetching: isFetchingControlSets,
    isLoading: isLoadingControlSets,
  } = useGetControlSets({ pagination, columnFilters, sorting, globalFilter });

  const fetchedControlSets = controlsetQueryResults[0]?.controlsets || [];

  useEffect(() => {
    const count = controlsetQueryResults[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [controlsetQueryResults]);

  const handleCreateControlSet: MRT_TableOptions<ControlSet>["onCreatingRowSave"] =
    async ({ values, table }) => {
      const newValidationErrors = validateControlSet(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await createControlSet(values);
    };

  const handleSaveControlSet: MRT_TableOptions<ControlSet>["onEditingRowSave"] =
    async ({ values, table }) => {
      const newValidationErrors = validateControlSet(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await updateControlSet(values);
    };

  const openDeleteConfirmModal = (row: MRT_Row<ControlSet>) => {
    if (window.confirm("Are you sure you want to delete this ControlSet?")) {
      deleteControlSet(row.original.id);
    }
  };

  const exportControlSets = useExportControlSets(columnFilters, sorting);
  const deleteControlSets = useDeleteControlSets(
    columnFilters,
    sorting,
    rowCount
  );

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const openImportModal = () => setIsImportModalOpen(true);
  const closeImportModal = () => setIsImportModalOpen(false);

  const triggerDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete all currently filtered Control Sets?"
    );
    if (confirmDelete) {
      try {
        await deleteControlSets();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleExport = async () => {
    try {
      await exportControlSets();
    } catch (error) {
      console.error(error);
      toast.error("Failed to export");
    } finally {
    }
  };

  function validateControlSet(controlset: ControlSet) {
    const validateRequired = (value: string) => !!value.trim().length;
    const validateBooleanField = (value: any) =>
      value === "Yes" || value === "No";

    return {
      title: !validateRequired(controlset.title) ? "Title is required" : "",
      client_specific: !validateBooleanField(controlset.client_specific)
        ? "Client Specific must be 'Yes' or 'No'"
        : "",
      description: !validateRequired(controlset.description)
        ? "Description is required"
        : "",
    };
  }

  const tableName = "admin_control_sets_table";
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const tableState = JSON.parse(localStorage.getItem("tableState") || "{}");
    return (
      tableState[tableName]?.columnVisibility || {
        id: false,
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tableState") || "{}"),
        [tableName]: {
          columnVisibility,
        },
      })
    );
  }, [columnVisibility, tableName]);

  const columns = useMemo<MRT_ColumnDef<ControlSet>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
        enableSorting: false,
      },

      {
        accessorKey: "title",
        header: "Title",
        Cell: ({ row }) => {
          return (
            <>
              <Link to={`/controlset/${row.original.id}`}>
                {row.original.title}
              </Link>
            </>
          );
        },

        muiEditTextFieldProps: {
          type: "text",
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              name: undefined,
            }),
        },
      },
      {
        accessorKey: "description",
        enableSorting: false,
        header: "Description",
        size: 240,

        Cell: ({ row }) => (
          <div style={{ textAlign: "left", maxHeight: "110px" }}>
            <ReactMarkdown>{row.original.description}</ReactMarkdown>
          </div>
        ),
        muiEditTextFieldProps: {
          type: "text",
          required: true,
          error: !!validationErrors?.description,
          helperText: validationErrors?.description,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              description: undefined,
            }),
        },
      },
      {
        accessorKey: "client_specific",
        header: "Client Specific",
        accessorFn: (row) => (row.client_specific ? "Yes" : "No"),
        muiEditTextFieldProps: {
          type: "text",
          required: true,
          error: !!validationErrors?.client_specific,
          helperText: validationErrors?.client_specific,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              client_specific: undefined,
            }),
        },
      },

      {
        accessorKey: "controls",
        enableColumnFilter: false,
        enableSorting: false,
        header: "Controls",
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.controls.map((control, index) => (
                  <div key={index}>
                    <Link to={`/control/${control.id}`}>{control.name}</Link>
                  </div>
                ))}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "compliance_standards",
        enableColumnFilter: false,
        enableSorting: false,
        header: "Compliance Standards",
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.compliance_standards.map(
                  (compliance_standard, index) => (
                    <div key={index}>
                      <Link
                        to={`/compliancestandard/${compliance_standard.id}`}
                      >
                        {compliance_standard.title}
                      </Link>
                    </div>
                  )
                )}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "date_created",
        enableColumnFilter: false,
        header: "Created On",
        Cell: ({ row }) => {
          if (row.original.date_created) {
            const date = new Date(row.original.date_created);
            return new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }).format(date);
          }
        },
        muiEditTextFieldProps: {
          type: "Date",
          enableEditing: false,
        },
      },
    ],
    [validationErrors]
  );

  const table = useMaterialReactTable({
    columns,
    enableGlobalFilter: false,
    data: fetchedControlSets,
    initialState: {
      showColumnFilters: false,
      density: "compact",
      columnVisibility: columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    manualFiltering: true,
    enableBottomToolbar: true,
    manualPagination: true,
    manualSorting: true,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    selectAllMode: "all",
    enableColumnResizing: true,
    getRowId: (row) => row.id,
    rowNumberDisplayMode: "original",
    muiToolbarAlertBannerProps: isLoadingControlSetsError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateControlSet,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveControlSet,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        {isAdmin && (
          <>
            <DialogTitle variant="h3">New Control Set</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => table.setCreatingRow(null)}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <GridCloseIcon />
            </IconButton>
            <DialogContent
              sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              {internalEditComponents}
            </DialogContent>
            <DialogActions>
              <MRT_EditActionButtons variant="text" table={table} row={row} />
            </DialogActions>
          </>
        )}
      </>
    ),

    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        {isAdmin && (
          <>
            <DialogTitle
              variant="h3"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Edit ControlSet
              <IconButton
                aria-label="close"
                onClick={() => table.setEditingRow(null)}
                sx={{ position: "absolute", right: 8, top: 8 }}
              >
                <GridCloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent
              sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
            >
              {internalEditComponents}
            </DialogContent>
            <DialogActions>
              <MRT_EditActionButtons variant="text" table={table} row={row} />
            </DialogActions>
          </>
        )}
      </>
    ),
    enableRowActions: isAdmin,
    renderRowActionMenuItems: ({ row, table }) => [
      <div key={row.id}>
        {isAdmin && (
          <Box>
            <Tooltip title="Edit">
              <IconButton onClick={() => openEditModal(row.original)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                color="error"
                onClick={() => openDeleteConfirmModal(row)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </div>,
    ],
    renderBottomToolbarCustomActions: ({ table }) => (
      <>
        {isAdmin && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  openCreateModal();
                }}
              >
                New Control Set
              </Button>

              <Button variant="contained" color="error" onClick={triggerDelete}>
                Delete {rowCount} Rows
              </Button>

              <Button variant="contained" onClick={handleExport}>
                Export {rowCount} Rows
              </Button>

              <Button variant="contained" onClick={openImportModal}>
                Import Control Sets
              </Button>

              <Modal
                open={isImportModalOpen}
                onClose={closeImportModal}
                id="modal"
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "90vh",
                    width: "90vw",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: "8px",
                    overflow: "auto",
                  }}
                >
                  <Typography id="modal-title" variant="h6" component="h2">
                    CSV Import
                  </Typography>
                  <hr />
                  <Typography
                    id="modal-description"
                    sx={{ mt: 2 }}
                  ></Typography>
                  <ImportFromCSV
                    apiUrl={`${baseURL}/api/controlsets/import/`}
                    importType="controlsets"
                    onClose={closeImportModal}
                  />

                  <Button onClick={closeImportModal} sx={{ mt: 2 }}>
                    Close
                  </Button>
                </Box>
              </Modal>
            </Box>
          </>
        )}
      </>
    ),
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      columnVisibility: columnVisibility,
      isLoading: isLoadingControlSets,
      showAlertBanner: isLoadingControlSetsError,
      showProgressBars: isFetchingControlSets,
    },
  });

  const { mutateAsync: updateControlSet } = useUpdateControlSet({ table });
  const { mutateAsync: createControlSet } = useCreateControlSet({ table });

  return (
    <Layout pageTitle="Control Sets" parentUrl="" parentPageName="">
      <div className="fullPageTable">
        <MaterialReactTable table={table} />
        {currentControlSet && isAdmin && (
          <AdminEditControlSetModal
            controlset={currentControlSet}
            isOpen={isEditModalOpen}
            onClose={closeEditModal}
          />
        )}
        {isCreatingControlSet && isAdmin && (
          <AdminEditControlSetModal
            controlset={defaultControlSet}
            isOpen={isEditModalOpen}
            onClose={closeCreateModal}
          />
        )}
      </div>
    </Layout>
  );
};

export default AdminControlSetPage;
