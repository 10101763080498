import React from "react";
import { Navigate } from "react-router-dom";
import AdminUserPage from "./admin";
import { getUserInfo } from "../../helpers/user";
import Error from "../error";
import OrgAdminUserPage from "./org_admin";

const UserPageRouteWrapper = () => {
  const userInfo = getUserInfo();

  if (userInfo.role === "admin") {
    return <AdminUserPage />;
  } else if (userInfo.role === "org_admin") {
    return <OrgAdminUserPage />;
  } else {
    return <Error />;
  }
};

export default UserPageRouteWrapper;
