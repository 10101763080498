import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardContent, Typography, Button, Box } from "@mui/material";
import { checkAuthentication } from "../../components/auth/RequireAuth";
import { baseURL } from "../../api/apiClient";

const Login: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = checkAuthentication();
    if (isAuthenticated) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        backgroundImage: `url('/bg.png')`,
        backgroundSize: "cover",
      }}
    >
      <Card
        sx={{
          marginLeft: 0,
          minHeight: 200,
          boxShadow: 20,
          opacity: 0.9,
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContent: "center",
          }}
        >
          <Typography variant="h2" sx={{ mb: 2 }}>
            Securisea
          </Typography>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Evidence Portal
          </Typography>
          <Button
            variant="contained"
            color="primary"
            href={`${baseURL}/auth/amazon-cognito/login/?process=login`}
          >
            Proceed to Login
          </Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Login;
