import React, { useEffect, useMemo, useState } from "react";
import { Engagement } from "../../types/engagements";
import { useGetObjects } from "../../hooks/common";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import { Link } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

interface EngagementCardsProp {
  cardMode?: boolean;
}

interface StatusCount {
  status: string;
  count: number;
}

interface CardWithSingleBarGraphProps {
  statusCounts: StatusCount[];
}

export type StatusType =
  | "Accepted"
  | "Followup Required"
  | "In Progress"
  | "Submitted For Review"
  | "In Review"
  | "New"
  | "Overdue";

export const statusColors: Record<StatusType, string> = {
  New: "#0D87F2", // Purple
  "In Progress": "#17a2b8", // Glossy Light Blue
  "In Review": "#303fcf", // Glossy Blue
  "Submitted For Review": "#6f42c1", // Glossy Purple
  "Followup Required": "#ed8912", // Glossy Yellow
  Overdue: "#dc3545", // Glossy Red
  Accepted: "#28A745", // Glossy Green
};
const CardWithSingleBarGraph = ({
  statusCounts,
}: CardWithSingleBarGraphProps) => {
  const totalValue = statusCounts.reduce((acc, item) => acc + item.count, 0);

  if (totalValue === 0) {
    return (
      <Box
        sx={{
          height: "20px",
          width: "100%",
          backgroundColor: "#ccc",
          display: "flex",
          position: "relative",
        }}
        title="No Evidence Requests"
      />
    );
  }

  return (
    <Box
      sx={{
        height: "20px",
        width: "100%",
        display: "flex",
        position: "relative",
      }}
    >
      {statusCounts.map((item, index) => {
        const color = statusColors[item.status as StatusType] || "#CCCCCC";
        return (
          <Box
            key={index}
            title={`${item.status}: ${item.count}`}
            sx={{
              height: "100%",
              width: `${(item.count / totalValue) * 100}%`,
              backgroundColor: color,
              position: "relative",
            }}
          />
        );
      })}
    </Box>
  );
};

const EngagementCards: React.FC<EngagementCardsProp> = ({ cardMode }) => {
  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [rowCount, setRowCount] = useState(0);
  const [showStats, setShowStats] = useState(true);
  const getParams = {
    pagination,
    columnFilters,
    sorting,
    globalFilter,
    showStats,
  };

  const { data: engagementQueryResults = [], isLoading } = useGetObjects<
    Engagement,
    [
      "engagements",
      {
        pagination: MRT_PaginationState;
        columnFilters: MRT_ColumnFiltersState;
        sorting: MRT_SortingState;
        globalFilter: string | undefined;
        showStats: boolean;
      }
    ]
  >(
    [
      "engagements",
      { pagination, columnFilters, sorting, globalFilter, showStats },
    ],
    "engagements",
    getParams
  );

  const fetchedEngagements = engagementQueryResults[0]?.data || [];

  useEffect(() => {
    const count = engagementQueryResults[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [engagementQueryResults]);

  return (
    <>
      <Box sx={{ flexGrow: 1, padding: 2, mr: 12 }}>
        {fetchedEngagements.length > 0 && (
          <>
            <Typography sx={{ textAlign: "left", pl: 2, fontWeight: 900 }}>
              Engagements
            </Typography>
            <Box
              sx={{
                height: "30vh",
                overflowY: "auto",
                p: 2,
              }}
            >
              <Grid container spacing={2} direction="column">
                {fetchedEngagements.map((engagement) => (
                  <Grid item xs={12} key={engagement.id}>
                    <Card
                      sx={{
                        maxWidth: "100%",
                        minHeight: "100px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-end",
                        borderRadius: 3,
                      }}
                    >
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          justifyContent: "space-between",
                        }}
                      >
                        <Link to={`/engagement/${engagement.id}`}>
                          <Typography
                            variant="h5"
                            sx={{ alignSelf: "flex-end" }}
                          >
                            {engagement.name}
                          </Typography>
                        </Link>
                        <Box sx={{ width: "100%", mt: 1 }}>
                          <CardWithSingleBarGraph
                            statusCounts={engagement.evidence_request_statuses}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </>
        )}
        {fetchedEngagements.length === 0 && !isLoading && (
          <Paper sx={{ p: 2, textAlign: "center" }}>
            <Typography>No engagements assigned</Typography>
          </Paper>
        )}
        {isLoading && <CircularProgress />}
      </Box>
    </>
  );
};

export default EngagementCards;
