import React, { useEffect, useMemo, useState } from "react";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import { useGetUsersAdmin, useUpdateUser } from "../../../hooks/users";
import { User } from "../../../types/users";
import { GridCloseIcon } from "@mui/x-data-grid";
import OrgAdminEditUserModal from "../../users/edit/org_admin";

const OrgAdminUserTable = () => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [columnFilters]);

  const {
    data: userQueryResults = [],
    isError: isLoadingUsersError,
    isFetching: isFetchingUsers,
    isLoading: isLoadingUsers,
  } = useGetUsersAdmin({ pagination, columnFilters, sorting, globalFilter });

  const fetchedUsers = userQueryResults[0]?.users || [];

  useEffect(() => {
    const count = userQueryResults[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [userQueryResults]);

  const handleSaveUser: MRT_TableOptions<User>["onEditingRowSave"] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateUser(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateUser(values);
  };

  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  const openEditModal = (user: User) => {
    setCurrentUser(user);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setCurrentUser(null);
  };

  function validateUser(user: User) {
    const validateRequired = (value: any) => !!value;
    const validateBooleanField = (value: any) =>
      value === "Yes" || value === "No";

    return {
      first_name: !validateRequired(user.first_name)
        ? "First name is required"
        : "",
      last_name: !validateRequired(user.last_name)
        ? "Last name is required"
        : "",
      organization: !validateRequired(user.organization)
        ? "Organization is required"
        : "",

      access_enabled: !validateBooleanField(user.access_enabled)
        ? "Access Enabled must be 'Yes' or 'No'"
        : "",
    };
  }

  const tableName = "org_admin_users_table";
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const tableState = JSON.parse(localStorage.getItem("tableState") || "{}");
    return (
      tableState[tableName]?.columnVisibility || {
        id: false,
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tableState") || "{}"),
        [tableName]: {
          columnVisibility,
        },
      })
    );
  }, [columnVisibility, tableName]);

  const columns = useMemo<MRT_ColumnDef<User>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
        enableSorting: false,
      },

      {
        accessorKey: "name",
        header: "Full Name",
        enableColumnFilter: false,
        enableEditing: false,
        enableSorting: false,
      },

      {
        accessorKey: "email",
        header: "Email",
        enableEditing: false,
        enableSorting: false,
      },

      {
        accessorKey: "first_name",
        header: "First Name",
        muiEditTextFieldProps: {
          type: "text",
          required: true,
          error: !!validationErrors?.first_name,
          helperText: validationErrors?.first_name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              first_name: undefined,
            }),
        },
      },

      {
        accessorKey: "last_name",
        header: "Last Name",
        muiEditTextFieldProps: {
          type: "text",
          required: true,
          error: !!validationErrors?.last_name,
          helperText: validationErrors?.last_name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              last_name: undefined,
            }),
        },
      },
      {
        accessorKey: "role",
        header: "Role",
        accessorFn: (row) => row.role.split("_").join(" "),
        enableEditing: false,
        muiEditTextFieldProps: {
          type: "text",
        },
      },
      {
        accessorKey: "access_enabled",
        header: "Access Enabled",
        accessorFn: (row) => (row.access_enabled ? "Yes" : "No"),
        muiEditTextFieldProps: {
          type: "text",
          required: true,
          defaultValue: "Yes",
          error: !!validationErrors?.access_enabled,
          helperText: validationErrors?.access_enabled,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              access_enabled: undefined,
            }),
        },
      },
    ],
    [validationErrors]
  );

  const table = useMaterialReactTable({
    columns,
    enableGlobalFilter: false,
    data: fetchedUsers,
    initialState: {
      showColumnFilters: false,
      density: "compact",
      columnVisibility: columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    selectAllMode: "all",
    enableColumnResizing: true,
    getRowId: (row) => row.id,
    rowNumberDisplayMode: "original",
    muiToolbarAlertBannerProps: isLoadingUsersError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveUser,

    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle
          variant="h3"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Edit User
          <IconButton
            aria-label="close"
            onClick={() => table.setEditingRow(null)}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <GridCloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    enableRowActions: true,
    renderRowActionMenuItems: ({ row, table }) => [
      <Box key={row.id}>
        <Tooltip title="Edit">
          <IconButton onClick={() => openEditModal(row.original)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      </Box>,
    ],
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      columnVisibility: columnVisibility,
      isLoading: isLoadingUsers,
      showAlertBanner: isLoadingUsersError,
      showProgressBars: isFetchingUsers,
    },
  });

  const { mutateAsync: updateUser } = useUpdateUser({ table });

  return (
    <div className="fullPageTable">
      <MaterialReactTable table={table} />
      {currentUser && (
        <OrgAdminEditUserModal
          user={currentUser}
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
        />
      )}
    </div>
  );
};

export default OrgAdminUserTable;
