import React, { useState, useEffect } from "react";
import { PieChart } from "@mui/x-charts";
import { type MRT_ColumnFiltersState } from "material-react-table";
import { useGetEvidenceRequestStats } from "../../hooks/evidencerequests";
import { useDrawingArea } from "@mui/x-charts/hooks";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {
  CircularProgress,
  Stack,
  Typography,
  Paper,
  Grid,
  styled,
  Card,
  CardContent,
  Box,
  Button,
  IconButton,
  Collapse,
} from "@mui/material";
import EngagementCards, {
  StatusType,
  statusColors,
} from "../engagements/cards";

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 16,
}));

function PieCenterLabel({ children }: { children: React.ReactNode }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText
      sx={{ fontSize: 14, fontWeight: 600 }}
      x={left + width / 2}
      y={top + height / 2}
    >
      {children}
    </StyledText>
  );
}

interface EvidenceRequestsPieChartProps {
  columnFilters: MRT_ColumnFiltersState;
  rowCount: number;
  setColumnFilters: (filters: MRT_ColumnFiltersState) => void;
  setShowAttentionRequired: (show: boolean) => void;
  showAttentionRequired: boolean;
}

export const EvidenceRequestsPieChart: React.FC<
  EvidenceRequestsPieChartProps
> = ({
  columnFilters,
  rowCount,
  setColumnFilters,
  setShowAttentionRequired,
  showAttentionRequired,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedStatus, setSelectedStatus] = useState<string | null>("All");
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    const savedState = localStorage.getItem("componentExpanded");
    if (savedState !== null) {
      setExpanded(JSON.parse(savedState));
    }
  }, []);

  const handleExpandClick = () => {
    const newExpandedState = !expanded;
    setExpanded(newExpandedState);
    localStorage.setItem("componentExpanded", JSON.stringify(newExpandedState));
  };

  const { data: stats, isLoading } = useGetEvidenceRequestStats({});

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (columnFilters.length > 0) {
      setShowAttentionRequired(false);
    }
  }, [columnFilters]);

  const statusCounts =
    stats?.status_counts.reduce<Record<string, number>>((acc, stat) => {
      acc[stat.status] = (acc[stat.status] || 0) + stat.count;
      return acc;
    }, {}) || {};

  const chartData = Object.keys(statusCounts).map((key, index) => ({
    id: index,
    value: statusCounts[key],
    label: key,
    color: statusColors[key as StatusType],
  }));

  const handleFilterChange = (label: string) => {
    if (selectedStatus === label) {
      setColumnFilters([]);
      setSelectedStatus(null);
      setShowAttentionRequired(true);
    } else {
      setColumnFilters([{ id: "status", value: label }]);
      setSelectedStatus(label);
      setShowAttentionRequired(false);
    }
  };

  const handlePieSliceClick = (d: { dataIndex: number }) => {
    const label = chartData[d.dataIndex].label;
    setSelectedStatus(label);
    handleFilterChange(label);
  };

  const handleAllClick = () => {
    setShowAttentionRequired(!showAttentionRequired);
    handleFilterChange("");
    setSelectedStatus("All");
  };

  if (loading) {
    return (
      <Stack alignItems="center" justifyContent="center" sx={{ height: 400 }}>
        <CircularProgress />
      </Stack>
    );
  }
  console.log();

  return (
    <>
      <Paper
        elevation={1}
        sx={{
          mr: -2,
          position: "relative",
          "&:hover": {
            boxShadow: 3,
          },
        }}
      >
        <IconButton
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "grey",
            transition: "transform 0.3s",
          }}
        >
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
        <Typography
          variant="subtitle1"
          sx={{
            padding: 2,
            paddingRight: 6,
            cursor: "pointer",
            userSelect: "none",
            color: "grey",
          }}
          onClick={handleExpandClick}
        >
          {expanded ? "Hide" : "Show Summary"}
        </Typography>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Grid container spacing={2} sx={{ width: "100%" }}>
            <Grid
              item
              xs={12}
              sm={4}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <EngagementCards cardMode={true} />
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {stats && stats?.status_counts && chartData.length > 0 && (
                <>
                  <PieChart
                    centered
                    onItemClick={(event, d) => handlePieSliceClick(d)}
                    slotProps={{
                      legend: {
                        hidden: true,
                      },
                    }}
                    series={[
                      {
                        data: chartData.map((item) => ({
                          ...item,
                          color: item.color,
                        })),
                        highlightScope: {
                          faded: "global",
                          highlighted: "item",
                        },
                        innerRadius: 110,
                        outerRadius: 150,
                        paddingAngle: 2,
                        cornerRadius: 2,
                        faded: {
                          outerRadius: 100,
                          innerRadius: 80,
                        },
                      },
                    ]}
                    width={400}
                    height={400}
                  >
                    <PieCenterLabel>
                      {selectedStatus || "Action Required"}: {rowCount}
                    </PieCenterLabel>
                  </PieChart>
                </>
              )}
            </Grid>
            {stats && stats?.status_counts && chartData.length > 0 && (
              <>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <Box sx={{ top: 0 }}>
                    <Typography
                      sx={{
                        textAlign: "right",
                        mt: 2,
                        mb: 2,
                        pl: 2,
                        fontWeight: 900,
                      }}
                    >
                      Evidence Requests
                    </Typography>

                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box display="flex" flexWrap="wrap" gap={2}>
                          {chartData.map((data, index) => (
                            <Card
                              key={index}
                              sx={{
                                flexGrow: 1,
                                width: "200px",

                                boxShadow:
                                  selectedStatus === data.label
                                    ? "0px 5px 10px rgba(0,0,0, 1)"
                                    : "0px 0px 5px rgba(0,0,0, .3)",
                                border:
                                  selectedStatus === data.label
                                    ? "1px solid white"
                                    : "inherit",
                                opacity:
                                  selectedStatus === data.label ? "1" : "0.9",
                                transition: "box-shadow 0.1s",
                                backgroundColor:
                                  statusColors[data.label as StatusType],
                                color: "white",
                                borderRadius: 2,
                                backdropFilter: "blur(100px)",
                                cursor: "pointer",
                                "&:hover": {
                                  boxShadow: "0px 5px 10px rgba(0,0,0, 1)",
                                },
                              }}
                              onClick={() =>
                                handlePieSliceClick({ dataIndex: index })
                              }
                            >
                              <CardContent>
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                >
                                  <Typography
                                    sx={{ fontSize: 14, textAlign: "left" }}
                                  >
                                    {data.label}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: 14,
                                      fontWeight: 900,
                                      textAlign: "left",
                                    }}
                                  >
                                    {data.value}
                                  </Typography>
                                </Box>
                              </CardContent>
                            </Card>
                          ))}

                          <Card
                            sx={{
                              flexGrow: 1,
                              width: "200px",
                              transition: "box-shadow 0.1s",
                              background: "black",
                              color: "white",
                              borderRadius: 2,
                              backdropFilter: "blur(100px)",
                              cursor: "pointer",
                              "&:hover": {
                                boxShadow: "0px 5px 10px rgba(0,0,0, 1)",
                              },
                            }}
                            onClick={() => handleAllClick()}
                          >
                            <CardContent>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                              >
                                <Typography
                                  variant="h5"
                                  sx={{ fontSize: 14, textAlign: "left" }}
                                >
                                  All
                                </Typography>
                                <Typography
                                  variant="h5"
                                  sx={{
                                    fontSize: 14,
                                    fontWeight: 900,
                                    textAlign: "right",
                                  }}
                                >
                                  {stats?.status_counts.reduce(
                                    (acc, stat) => acc + stat.count,
                                    0
                                  )}
                                </Typography>
                              </Box>
                            </CardContent>
                          </Card>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
        </Collapse>
      </Paper>
    </>
  );
};
