import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      main: '#304BC8',
      light: '#6A7DDB',
    },
    secondary: {
      main: '#598519',
      light: '#D4D4D4',
    },
  },
  typography: {
    h2: {
      fontSize: '4rem',
      fontWeight: 600,
    },
    h4: {
        fontSize: '2rem',
        fontWeight: 500,
      },
  },
});

export default theme;
