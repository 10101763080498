import React from "react";
import { Navigate } from "react-router-dom";
import AdminScopePage from "./admin";
import { getUserInfo } from "../../helpers/user";
import Error from "../error";

const ScopePageRouteWrapper = () => {
  const userInfo = getUserInfo();

  if (userInfo.role === "admin") {
    return <AdminScopePage />;
  } else if (userInfo.role === "user") {
    return <Error />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default ScopePageRouteWrapper;
