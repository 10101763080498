import React, { useState, useEffect, FunctionComponent } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  ListItem,
  ListItemText,
  IconButton,
  Autocomplete,
  Box,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import PaginatedModalComponent from "../../edit/PaginatedModalComponent";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import {
  useCreateEvidenceRequest,
  useDeleteEvidenceRequest,
  useGetEvidenceRequest,
  useUpdateEvidenceRequest,
} from "../../../hooks/evidencerequests";

import CloseIcon from "@mui/icons-material/Close";
import Layout from "../../../layout";
import {
  EvidenceRequest,
  defaultEvidenceRequest,
} from "../../../types/evidencerequests";
import { TabPanel, a11yProps } from "../../../helpers/functions";
import { Control } from "../../../types/controls";
import { useSearchControls } from "../../../hooks/controls";
import { Engagement } from "../../../types/engagements";
import { useSearchEngagements } from "../../../hooks/engagements";
import { User } from "../../../types/users";
import { useSearchUsers } from "../../../hooks/users";
import { formatDueDate } from "../../engagements/edit/admin";
import { Organization } from "../../../types/organizations";
import { useSearchOrganizations } from "../../../hooks/organizations";
import { ClearIcon } from "@mui/x-date-pickers";

interface EditEvidenceRequestModalProps {
  evidencerequest?: Partial<EvidenceRequest> | null;
  isOpen?: boolean;
  mode?: "modal" | "page";
  onClose?: () => void;
}

const AdminEditEvidenceRequestModal: FunctionComponent<
  EditEvidenceRequestModalProps
> = ({
  evidencerequest = defaultEvidenceRequest,
  isOpen = true,
  mode = "modal",
  onClose = () => {},
}) => {
  const navigate = useNavigate();
  const [editedEvidenceRequest, setEditedEvidenceRequest] = useState<
    EvidenceRequest | Partial<EvidenceRequest>
  >(evidencerequest || defaultEvidenceRequest);
  const isPageMode = mode === "page";

  const { id } = useParams<{ id: string }>();
  const [loadingError, setLoadingError] = useState(false);
  const [descriptionMode, setDescriptionMode] = useState("edit");

  if (id) {
    const { data, isLoading: isFetching } = useGetEvidenceRequest({ id });
    useEffect(() => {
      if (mode === "page" && id && data && !isFetching) {
        if (data.evidencerequests.length === 0) {
          setLoadingError(true);
        } else {
          const deepCopiedEvidenceRequest = JSON.parse(
            JSON.stringify(data.evidencerequests[0])
          );
          setEditedEvidenceRequest(deepCopiedEvidenceRequest);
          setDescriptionMode(
            deepCopiedEvidenceRequest.description ? "view" : "edit"
          );
        }
      }
    }, [id, mode, data, isFetching]);
  }

  const [searchQueryControls, setSearchQueryControls] = useState("");
  const [searchResultsControls, setSearchResultsControls] = useState<Control[]>(
    []
  );

  const [searchQueryUser, setSearchQueryUser] = useState("");
  const [searchQueryVerifier, setSearchQueryVerifier] = useState("");
  const [searchResultsVerifier, setSearchResultsVerifier] = useState<User[]>(
    []
  );
  const [searchResultsUser, setSearchResultsUser] = useState<User[]>([]);
  const [searchQueryEngagements, setSearchQueryEngagements] = useState("");
  const [searchResultsEngagements, setSearchResultsEngagements] = useState<
    Engagement[]
  >([]);

  const [searchResultsOrg, setSearchResultsOrg] = useState<Organization[]>([]);
  const [searchQueryOrg, setSearchQueryOrg] = useState("");

  const { data: orgSearchResults, refetch: refetchOrgs } =
    useSearchOrganizations({
      q: searchQueryOrg,
    });

  useEffect(() => {
    refetchOrgs();
  }, [searchQueryOrg, refetchOrgs]);

  useEffect(() => {
    if (orgSearchResults) {
      setSearchResultsOrg(orgSearchResults[0].organizations);
    }
  }, [orgSearchResults]);

  const handleAddOrg = (org: Organization): void => {
    if (org) {
      setEditedEvidenceRequest((prev) => ({ ...prev, organization: org }));
    }
  };

  const updateEvidenceRequest = useUpdateEvidenceRequest();
  const createEvidenceRequest = useCreateEvidenceRequest();

  const { data: userSearchResults, refetch: refetchUsers } = useSearchUsers({
    q: searchQueryUser,
    organization: editedEvidenceRequest.organization?.id || "",
    intent: "users",
  });

  const { data: verifierSearchResults, refetch: refetchVerifiers } =
    useSearchUsers({
      q: searchQueryVerifier,
      organization: "",
      intent: "verifiers",
    });

  useEffect(() => {
    if (userSearchResults) {
      setSearchResultsUser(userSearchResults[0].users);
    }
  }, [userSearchResults]);

  useEffect(() => {
    if (!editedEvidenceRequest.date_created) {
      setEditedEvidenceRequest((prev) => ({
        ...prev,
        users: [],
        engagements: [],
      }));
      setSearchResultsUser([]);
      setSearchResultsEngagements([]);
    }
  }, [editedEvidenceRequest.organization?.id]);

  useEffect(() => {
    refetchUsers();
  }, [searchQueryUser, refetchUsers, editedEvidenceRequest.organization?.id]);

  useEffect(() => {
    if (verifierSearchResults) {
      setSearchResultsVerifier(verifierSearchResults[0].users);
    }
  }, [verifierSearchResults]);

  useEffect(() => {
    refetchVerifiers();
  }, [searchQueryVerifier, refetchVerifiers]);

  const { data: controlSearchResults, refetch: refetchControls } =
    useSearchControls({
      q: searchQueryControls,
    });

  const { data: engagementSearchResults, refetch: refetchEngagements } =
    useSearchEngagements({
      q: searchQueryEngagements,
      organization: editedEvidenceRequest.organization?.id,
    });

  const toggleMode = () => {
    setDescriptionMode(descriptionMode === "view" ? "edit" : "view");
  };

  useEffect(() => {
    if (controlSearchResults) {
      setSearchResultsControls(controlSearchResults[0].controls);
    }
  }, [controlSearchResults]);

  useEffect(() => {
    if (engagementSearchResults) {
      setSearchResultsEngagements(engagementSearchResults[0].engagements);
    }
  }, [engagementSearchResults]);

  useEffect(() => {
    refetchControls();
  }, [searchQueryControls, refetchControls]);

  useEffect(() => {
    refetchEngagements();
  }, [
    searchQueryEngagements,
    refetchEngagements,
    editedEvidenceRequest.organization?.id,
  ]);

  const handleAddUser = (user: User): void => {
    let updatedUsers: User[];
    updatedUsers = [...(editedEvidenceRequest.users ?? []), user];
    setEditedEvidenceRequest((prev) => ({ ...prev, users: updatedUsers }));
  };

  const handleAddVerifier = (user: User): void => {
    let updatedUsers: User[];
    updatedUsers = [...(editedEvidenceRequest.verifiers ?? []), user];
    setEditedEvidenceRequest((prev) => ({ ...prev, verifiers: updatedUsers }));
  };
  const handleRemoveUser = (user: User): void => {
    const updatedUsers: User[] =
      editedEvidenceRequest.users?.filter((u) => u.id !== user.id) ?? [];

    setEditedEvidenceRequest((prev) => ({ ...prev, users: updatedUsers }));
  };

  const renderUser = (user: User) => (
    <ListItem className="modalEditListItem" key={user.id}>
      <ListItemText primary={user.name} />

      <IconButton
        edge="end"
        aria-label="remove"
        onClick={() => handleRemoveUser(user)}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </ListItem>
  );

  const handleAddControl = (control: Control): void => {
    let updatedEvidenceRequests: Control[];
    updatedEvidenceRequests = [
      ...(editedEvidenceRequest.controls ?? []),
      control,
    ];
    setEditedEvidenceRequest((prev) => ({
      ...prev,
      controls: updatedEvidenceRequests,
    }));
  };

  const handleRemoveControl = (control: Control): void => {
    const updatedEvidenceRequest: Control[] =
      editedEvidenceRequest.controls?.filter((c) => c.id !== control.id) ?? [];

    setEditedEvidenceRequest((prev) => ({
      ...prev,
      controls: updatedEvidenceRequest,
    }));
  };

  const handleAddEngagement = (engagement: Engagement): void => {
    let updatedEngagements: Engagement[];
    updatedEngagements = [
      ...(editedEvidenceRequest.engagements ?? []),
      engagement,
    ];
    setEditedEvidenceRequest((prev) => ({
      ...prev,
      engagements: updatedEngagements,
    }));
  };

  const handleRemoveEngagement = (engagement: Engagement): void => {
    const updatedEvidenceRequest: Engagement[] =
      editedEvidenceRequest.engagements?.filter(
        (e) => e.id !== engagement.id
      ) ?? [];

    setEditedEvidenceRequest((prev) => ({
      ...prev,
      engagements: updatedEvidenceRequest,
    }));
  };

  const renderControl = (control: Control) => (
    <ListItem className="modalEditListItem" key={control.id}>
      <ListItemText
        primary={<Link to={`/control/${control.id}`}>{control.name}</Link>}
      />

      <IconButton
        edge="end"
        aria-label="remove"
        onClick={() => handleRemoveControl(control)}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </ListItem>
  );

  const renderEngagement = (engagement: Engagement) => (
    <ListItem className="modalEditListItem" key={engagement.id}>
      <ListItemText
        primary={
          <Link to={`/engagement/${engagement.id}`}>{engagement.name}</Link>
        }
      />

      <IconButton
        edge="end"
        aria-label="remove"
        onClick={() => handleRemoveEngagement(engagement)}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </ListItem>
  );

  const handleSubmit = async () => {
    try {
      if ("id" in editedEvidenceRequest) {
        await updateEvidenceRequest.mutateAsync(
          editedEvidenceRequest as EvidenceRequest
        );
        onClose();
      } else {
        await createEvidenceRequest.mutateAsync(
          editedEvidenceRequest as EvidenceRequest
        );
        onClose();
      }
    } catch (error) {}
  };

  const deleteEvidenceRequest = useDeleteEvidenceRequest();

  const triggerDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Evidence Request?"
    );
    if (confirmDelete) {
      try {
        await deleteEvidenceRequest.mutateAsync(
          editedEvidenceRequest.id as EvidenceRequest["id"]
        );
        navigate("/evidencerequests");
      } catch (error) {}
    }
  };
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabId: number) => {
    setActiveTab(tabId);
  };

  const pageModeContent = (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => handleTabClick(newValue)}
            aria-label="Switch tabs"
          >
            <Tab label="Predecessor EvidenceRequests" {...a11yProps(0)} />
          </Tabs>
        </Box>

        <TabPanel value={activeTab} index={0}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" component="div">
              EvidenceRequests
            </Typography>

            {/* {editedEvidenceRequest.id && (
              <PredecessorEvidenceRequestTable
                parentObjectUrlKey="evidencerequests"
                loadPartial={true}
                parentObject={editedEvidenceRequest}
                relatedChildFieldName="predecessor_evidencerequests"
              />
            )} */}
          </Box>
        </TabPanel>
      </Box>
    </>
  );

  const content = (
    <>
      <DialogContent>
        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            value={editedEvidenceRequest.title}
            onChange={(e) =>
              setEditedEvidenceRequest({
                ...editedEvidenceRequest,
                title: e.target.value,
              })
            }
            margin="normal"
          />
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <TextField
            fullWidth
            label="Notes"
            variant="outlined"
            value={editedEvidenceRequest.notes}
            onChange={(e) =>
              setEditedEvidenceRequest({
                ...editedEvidenceRequest,
                notes: e.target.value,
              })
            }
            margin="normal"
          />
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <Button
            onClick={toggleMode}
            variant="contained"
            startIcon={
              descriptionMode === "view" ? <EditIcon /> : <VisibilityIcon />
            }
            sx={{
              margin: 2,
              marginLeft: 0,
            }}
          >
            {descriptionMode === "view"
              ? "Edit Description"
              : "Preview Description"}
          </Button>
          {descriptionMode === "edit" ? (
            <TextField
              fullWidth
              multiline
              label="Evidence Request Description"
              variant="outlined"
              value={editedEvidenceRequest.description}
              onChange={(e) =>
                setEditedEvidenceRequest({
                  ...editedEvidenceRequest,
                  description: e.target.value,
                })
              }
              margin="normal"
            />
          ) : (
            <Typography
              component="div"
              variant="body1"
              sx={{
                lineHeight: "1em",
                padding: 2,
                border: 2,
                borderColor: "divider",
                borderRadius: 3,
                overflow: "wrap",
              }}
            >
              <ReactMarkdown>{editedEvidenceRequest.description}</ReactMarkdown>
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <TextField
            label="Due Date"
            type="date"
            variant="outlined"
            fullWidth
            value={formatDueDate(editedEvidenceRequest.due_date)}
            onChange={(e) =>
              setEditedEvidenceRequest({
                ...editedEvidenceRequest,
                due_date: e.target.value ? new Date(e.target.value) : null,
              })
            }
            InputLabelProps={{
              shrink: true,
            }}
            margin="normal"
          />
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <Typography variant="h6">Organization</Typography>
          {editedEvidenceRequest.organization?.id && (
            <Box display="flex" alignItems="center">
              <TextField
                fullWidth
                label="Organization"
                variant="outlined"
                value={editedEvidenceRequest.organization.name}
                disabled={
                  defaultEvidenceRequest && "id" in defaultEvidenceRequest
                }
                margin="normal"
              />

              {!editedEvidenceRequest.id && (
                <IconButton
                  onClick={() => {
                    setEditedEvidenceRequest((prev) => ({
                      ...prev,
                      organization: null,
                    }));
                  }}
                  sx={{ ml: 1 }}
                >
                  <ClearIcon />
                </IconButton>
              )}
            </Box>
          )}
          {!editedEvidenceRequest.organization?.id && (
            <Autocomplete
              options={searchResultsOrg || []}
              getOptionLabel={(option) => option.name || ""}
              filterSelectedOptions
              onChange={(event, newValue: Organization | null) => {
                if (newValue) {
                  handleAddOrg(newValue);
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Organizations"
                  variant="outlined"
                  onChange={(e) => setSearchQueryOrg(e.target.value)}
                />
              )}
            />
          )}
        </Box>

        {/* engagements */}
        {!isPageMode && (
          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <Typography variant="h6">Engagements</Typography>
            <Autocomplete
              multiple
              disabled={!editedEvidenceRequest.organization?.id}
              options={searchResultsEngagements}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              onChange={(event, newValue) => {
                newValue.forEach((engagement) => {
                  if (
                    !editedEvidenceRequest.engagements?.some(
                      (e) => e.id === engagement.id
                    )
                  ) {
                    handleAddEngagement(engagement);
                  }
                });
              }}
              renderTags={() => {
                return null;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Engagements"
                  variant="outlined"
                  onChange={(e) => setSearchQueryEngagements(e.target.value)}
                />
              )}
            />
            <PaginatedModalComponent
              items={
                editedEvidenceRequest.engagements?.map(
                  (engagement) => engagement
                ) ?? []
              }
              renderItem={renderEngagement}
            />
          </Box>
        )}

        {/* users on engagements */}

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <Typography variant="h6">Users</Typography>
          <Autocomplete
            multiple
            options={searchResultsUser}
            disabled={!editedEvidenceRequest.organization?.id}
            getOptionLabel={(option) => option.name + " - " + option.email}
            filterSelectedOptions
            onChange={(event, newValue) => {
              newValue.forEach((user) => {
                if (
                  !editedEvidenceRequest.users?.some((u) => u.id === user.id)
                ) {
                  handleAddUser(user);
                }
              });
            }}
            renderTags={() => {
              return null;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Users"
                variant="outlined"
                onChange={(e) => setSearchQueryUser(e.target.value)}
              />
            )}
          />
          <PaginatedModalComponent
            items={editedEvidenceRequest.users?.map((user) => user) ?? []}
            renderItem={renderUser}
          />
        </Box>

        {/* verifiers */}

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <Typography variant="h6">Verifiers</Typography>
          <Autocomplete
            multiple
            options={searchResultsVerifier}
            getOptionLabel={(option) => option.name + " - " + option.email}
            filterSelectedOptions
            onChange={(event, newValue) => {
              newValue.forEach((user) => {
                if (
                  !editedEvidenceRequest.verifiers?.some(
                    (u) => u.id === user.id
                  )
                ) {
                  handleAddVerifier(user);
                }
              });
            }}
            renderTags={() => {
              return null;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Verifiers"
                variant="outlined"
                onChange={(e) => setSearchQueryVerifier(e.target.value)}
              />
            )}
          />
          <PaginatedModalComponent
            items={editedEvidenceRequest.verifiers?.map((user) => user) ?? []}
            renderItem={renderUser}
          />
        </Box>
        {/* controls */}
        {!isPageMode && (
          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <Typography variant="h6">Controls</Typography>
            <Autocomplete
              multiple
              options={searchResultsControls}
              getOptionLabel={(option) => option.name}
              filterSelectedOptions
              onChange={(event, newValue) => {
                newValue.forEach((control) => {
                  if (
                    !editedEvidenceRequest.controls?.some(
                      (c) => c.id === control.id
                    )
                  ) {
                    handleAddControl(control);
                  }
                });
              }}
              renderTags={() => {
                return null;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Controls"
                  variant="outlined"
                  onChange={(e) => setSearchQueryControls(e.target.value)}
                />
              )}
            />
            <PaginatedModalComponent
              items={
                editedEvidenceRequest.controls?.map((control) => control) ?? []
              }
              renderItem={renderControl}
            />
          </Box>
        )}
      </DialogContent>
    </>
  );

  if (loadingError) {
    return (
      <Layout pageTitle="" parentUrl="" parentPageName="">
        <Typography variant="h5" sx={{ color: "black", padding: 3 }}>
          Evidence Request not found
        </Typography>
        ;
      </Layout>
    );
  } else
    return isPageMode ? (
      <Layout pageTitle="" parentUrl="" parentPageName="">
        <Box>
          <Box sx={{ paddingRight: "6vh" }}>
            <Typography fontSize={25} sx={{ color: "black", padding: 3 }}>
              {editedEvidenceRequest.title}
            </Typography>
            <CloseIcon
              onClick={onClose}
              style={{
                position: "absolute",
                right: 10,
                top: 10,
                fontSize: 30,
              }}
            />

            <DialogActions>
              <Button variant="contained" color="error" onClick={triggerDelete}>
                Delete Evidence Request
              </Button>

              <Button variant="contained" onClick={handleSubmit}>
                Update Evidence Request
              </Button>
            </DialogActions>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "100%",
                color: "black",
                border: 2,
                borderColor: "divider",
                margin: 2,
                borderRadius: 3,
              }}
            >
              {content}
            </Box>
            <Box
              sx={{
                width: "100%",
                maxWidth: "100%",
                color: "black",
                border: 2,
                borderColor: "divider",
                margin: 2,
                borderRadius: 3,
              }}
            >
              {pageModeContent}
            </Box>
          </Box>
        </Box>
      </Layout>
    ) : (
      <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography fontSize={25}>{editedEvidenceRequest.title}</Typography>
          <CloseIcon
            onClick={onClose}
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              fontSize: 30,
            }}
          />
        </DialogTitle>

        {content}

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    );
};

export default AdminEditEvidenceRequestModal;
