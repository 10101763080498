import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { getUserInfo } from "../../helpers/user";
import { useUpdateUser, useGetUser, useAcceptTOS } from "../../hooks/users";
import { User } from "../../types/users";
import { defaultUser } from "../../components/users/edit/admin";
import { fetchCurrentUser } from "../finish-login";

interface TermsAndConditionsPopupProps {
  open?: boolean;
}

const TermsAndConditionsPopup: React.FC<TermsAndConditionsPopupProps> = ({
  open = false,
}) => {
  const [editedUser, setEditedUser] = useState<User | Partial<User>>(
    defaultUser
  );

  useEffect(() => {
    const loadUserData = async () => {
      const userData = await fetchCurrentUser();
      if (userData) {
        setEditedUser(userData);
      }
    };
    loadUserData();
  }, []);

  const handleAccept = () => {
    if (editedUser.id) {
      const acceptTOS = useAcceptTOS(editedUser.id);
      acceptTOS();
    }
  };

  return (
    <Dialog
      open={true}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="scroll-dialog-title">Terms and Conditions</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ pr: { xs: 2, lg: 4 } }}>
          <Typography sx={{ pb: 2 }}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
            imperdiet, nulla et dictum interdum, nisi lorem egestas odio, vitae
            scelerisque enim ligula venenatis dolor. Maecenas nisl est, ultrices
            nec congue eget, auctor vitae massa. Fusce luctus vestibulum augue
            ut aliquet. Mauris ante ligula, facilisis sed ornare eu, lobortis in
            odio. Praesent convallis urna a lacus interdum ut hendrerit risus
            congue. Nunc sagittis dictum nisi, sed ullamcorper ipsum dignissim
            ac. In at libero sed nunc venenatis imperdiet sed ornare turpis.
            Donec vitae dui eget tellus gravida venenatis. Integer fringilla
            congue eros non fermentum. Sed dapibus pulvinar nibh tempor porta.
          </Typography>
          <Typography sx={{ pb: 2 }} variant="h5">
            Use of Services
          </Typography>
          <Typography sx={{ pb: 2 }}>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
            dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta
            ac consectetur ac, vestibulum at eros. Praesent commodo cursus
            magna, vel scelerisque nisl consectetur et.
          </Typography>
          <Typography sx={{ pb: 2 }} variant="h5">
            Privacy Policy
          </Typography>
          <Typography sx={{ pb: 2 }}>
            Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor
            auctor. Duis mollis, est non commodo luctus, nisi erat porttitor
            ligula, eget lacinia odio sem nec elit. Morbi leo risus, porta ac
            consectetur ac, vestibulum at eros.
          </Typography>
          <Typography sx={{ pb: 2 }} variant="h5">
            Limitation of Liability
          </Typography>
          <Typography sx={{ pb: 2 }}>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo
            cursus magna, vel scelerisque nisl consectetur et. Duis mollis, est
            non commodo luctus, nisi erat porttitor ligula, eget lacinia odio
            sem nec elit. Donec sed odio dui.
          </Typography>
          <Typography sx={{ pb: 2 }} variant="h5">
            Changes to Terms
          </Typography>
          <Typography sx={{ pb: 2 }}>
            Curabitur blandit tempus porttitor. Integer posuere erat a ante
            venenatis dapibus posuere velit aliquet. Duis mollis, est non
            commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem
            nec elit. Sed posuere consectetur est at lobortis.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAccept} variant="contained" color="primary">
          Accept
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TermsAndConditionsPopup;
