import React, { useState } from "react";
import Layout from "../../layout";
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { getUserInfo } from "../../helpers/user";
import AdminEvidenceRequestPage from "../evidencerequests/admin";
import EngagementCards from "../../components/engagements/cards";

const Home = () => {
  const user = getUserInfo();
  const hometitle = `Welcome, ${user.first_name}`;

  return (
    <Layout type="home" pageTitle={hometitle} parentUrl="" parentPageName="">
      <Box sx={{ flexGrow: 1, padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card variant="outlined" sx={{ borderColor: "transparent" }}>
              <CardContent>
                <Box
                  sx={{
                    mt: 2,
                    textAlign: "end",
                    backgroundColor: "grey.100",
                  }}
                >
                  <AdminEvidenceRequestPage cardMode={true} />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default Home;
