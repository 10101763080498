import React, { useEffect, useState } from "react";
import { Box, Button, Typography, TextField } from "@mui/material";
import { Control } from "../../../types/controls";
import ReactMarkdown from "react-markdown";

interface ControlVideosTranscriptComponentProps {
  control: Control | Partial<Control>;
  video_type: "control_description" | "evidence_description";
  setOpenModal: (open: boolean) => void;
}

export const UserControlVideosTranscriptComponent = ({
  control,
  video_type,
}: ControlVideosTranscriptComponentProps) => {
  const [descriptionMode, setDescriptionMode] = useState("");

  const [editedControl, setEditedControl] = useState<
    Control | Partial<Control>
  >(control);

  useEffect(() => {
    if (video_type === "evidence_description") {
      if (
        control.evidence_description_video_transcript &&
        control.evidence_description_video_transcript.length > 0
      ) {
        setDescriptionMode("view");
        return;
      }
    } else if (video_type === "control_description") {
      if (
        control.control_description_video_transcript &&
        control.control_description_video_transcript.length > 0
      ) {
        setDescriptionMode("view");
        return;
      }
    }
  }, [control]);

  return (
    <Box
      sx={{
        width: { xs: "100wh", lg: "40vh" },
        display: descriptionMode === "" ? "none" : "initial",
        overflow: "scroll",
        maxHeight: "50vh",
        border: 1,
        borderColor: "divider",
        padding: 2,
      }}
    >
      {video_type === "control_description" && (
        <>
          <Typography
            component="div"
            variant="body1"
            sx={{
              overflow: "wrap",
              border: 1,
              borderColor: "divider",
              padding: 2,
              borderRadius: 2,
            }}
          >
            <ReactMarkdown>
              {editedControl.control_description_video_transcript}
            </ReactMarkdown>
          </Typography>
        </>
      )}

      {video_type === "evidence_description" && (
        <>
          <Typography
            component="div"
            variant="body1"
            sx={{
              overflow: "wrap",
              border: 1,
              borderColor: "divider",
              padding: 2,
              borderRadius: 2,
            }}
          >
            <ReactMarkdown>
              {editedControl.evidence_description_video_transcript}
            </ReactMarkdown>
          </Typography>
        </>
      )}
    </Box>
  );
};
