import React from "react";
import { Navigate } from "react-router-dom";
import AdminHomePage from "./admin";
import UserHomePage from "./user";
import { getUserInfo } from "../../helpers/user";

const HomePageRouteWrapper = () => {
  const userInfo = getUserInfo();

  if (userInfo.role === "admin" || userInfo.role === "auditor") {
    return <AdminHomePage />;
  } else if (userInfo.role === "user" || userInfo.role === "org_admin") {
    return <UserHomePage />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default HomePageRouteWrapper;
