import React, { useState, useEffect, FunctionComponent } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  TextField,
  DialogActions,
  Button,
  ListItem,
  ListItemText,
  IconButton,
  Autocomplete,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import PaginatedModalComponent from "../../edit/PaginatedModalComponent";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Engagement } from "../../../types/engagements";

import { User } from "../../../types/users";
import {
  useGetEngagement,
  useUpdateEngagement,
} from "../../../hooks/engagements";
import { useSearchUsers } from "../../../hooks/users";

import CloseIcon from "@mui/icons-material/Close";

import { GridArrowDownwardIcon } from "@mui/x-data-grid";
import { getUserInfo } from "../../../helpers/user";

export const defaultEngagement: Partial<Engagement> = {
  name: "",
  description: "",
  due_date: null,
  organization: null,
  ticket_url: "",
  date_created: new Date(),
  last_updated: new Date(),
  evidence_requests: [],
  users: [],
  scopes: [],
  prev_engagements: [],
  peer_engagements: [],
};

interface EditEngagementModalProps {
  engagement?: Partial<Engagement> | null;
  isOpen?: boolean;
  mode?: "modal" | "page";
  onClose?: () => void;
}

const OrgAdminEditEngagementModal: FunctionComponent<
  EditEngagementModalProps
> = ({
  engagement = defaultEngagement,
  isOpen = true,
  mode = "modal",
  onClose = () => {},
}) => {
  const [editedEngagement, setEditedEngagement] = useState<
    Engagement | Partial<Engagement>
  >(engagement || defaultEngagement);

  const { id } = useParams<{ id: string }>();
  const [loadingError, setLoadingError] = useState(false);

  if (id) {
    const { data, isLoading: isFetching } = useGetEngagement({ id });
    useEffect(() => {
      if (mode === "page" && id && data && !isFetching) {
        if (data[0].engagements.length === 0) {
          setLoadingError(true);
        } else {
          setEditedEngagement(data[0].engagements[0]);
        }
      }
    }, [id, mode, data, isFetching]);
  }

  useEffect(() => {
    if (editedEngagement) {
      localStorage.setItem(
        "last_viewed_engagement",
        JSON.stringify(editedEngagement)
      );
    }
  }, [editedEngagement]);

  const [searchQueryUser, setSearchQueryUser] = useState("");
  const [searchResultsUser, setSearchResultsUser] = useState<User[]>([]);

  const updateEngagement = useUpdateEngagement();

  const { data: userSearchResults, refetch: refetchUsers } = useSearchUsers({
    q: searchQueryUser,
    organization: editedEngagement.organization?.id || "",
    intent: "users",
  });

  useEffect(() => {
    if (userSearchResults) {
      setSearchResultsUser(userSearchResults[0].users);
    }
  }, [userSearchResults]);

  useEffect(() => {
    refetchUsers();
  }, [searchQueryUser, refetchUsers]);

  const handleAddUser = (user: User): void => {
    let updatedUsers: User[];
    updatedUsers = [...(editedEngagement.users ?? []), user];
    setEditedEngagement((prev) => ({ ...prev, users: updatedUsers }));
  };

  const handleRemoveUser = (user: User): void => {
    const updatedUsers: User[] =
      editedEngagement.users?.filter((u) => u.id !== user.id) ?? [];

    setEditedEngagement((prev) => ({ ...prev, users: updatedUsers }));
  };

  const handleSubmit = async () => {
    try {
      if ("id" in editedEngagement) {
        await updateEngagement.mutateAsync(editedEngagement as Engagement);
        onClose();
      } else {
        onClose();
      }
    } catch (error) {}
  };

  const renderUser = (user: User) => (
    <ListItem className="modalEditListItem" key={user.id}>
      <ListItemText primary={user.name} />

      <IconButton
        edge="end"
        aria-label="remove"
        onClick={() => handleRemoveUser(user)}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </ListItem>
  );

  const content = (
    <>
      <Accordion defaultExpanded={mode == "modal" ? true : false}>
        {mode == "page" && (
          <AccordionSummary
            expandIcon={<GridArrowDownwardIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
          >
            <Typography sx={{ p: 2 }}> {editedEngagement.name} </Typography>
          </AccordionSummary>
        )}

        <AccordionDetails>
          {/* users on engagements */}
          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <Typography variant="h6">Users</Typography>
            <Autocomplete
              multiple
              options={searchResultsUser}
              getOptionLabel={(option) => option.name + " - " + option.email}
              filterSelectedOptions
              onChange={(event, newValue) => {
                newValue.forEach((user) => {
                  if (!editedEngagement.users?.some((u) => u.id === user.id)) {
                    handleAddUser(user);
                  }
                });
              }}
              renderTags={() => {
                return null;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Users"
                  variant="outlined"
                  onChange={(e) => setSearchQueryUser(e.target.value)}
                />
              )}
            />
            <PaginatedModalComponent
              items={editedEngagement.users?.map((user) => user) ?? []}
              renderItem={renderUser}
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography fontSize={25}>{editedEngagement.name}</Typography>
        <CloseIcon
          onClick={onClose}
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            fontSize: 30,
            zIndex: 999,
          }}
        />
      </DialogTitle>

      {content}

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrgAdminEditEngagementModal;
