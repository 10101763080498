import React, { useState, useEffect, FunctionComponent } from "react";
import { isEqual } from "lodash";
import { Link, useParams, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  ListItem,
  ListItemText,
  IconButton,
  Autocomplete,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
} from "@mui/material";
import PaginatedModalComponent from "../../edit/PaginatedModalComponent";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { ControlSet } from "../../../types/controlsets";

import {
  useCreateControlSet,
  useDeleteControlSet,
  useGetControlSet,
  useUpdateControlSet,
} from "../../../hooks/controlsets";

import CloseIcon from "@mui/icons-material/Close";
import Layout from "../../../layout";
import { Control } from "../../../types/controls";
import { useSearchControls } from "../../../hooks/controls";
import { ComplianceStandard } from "../../../types/compliancestandards";
import { useSearchComplianceStandards } from "../../../hooks/compliancestandards";
import ControlTable from "../../tables/controls";
import ComplianceStandardTable from "../../tables/compliance_standards";
import { TabPanel, a11yProps } from "../../../helpers/functions";
import { getUserInfo } from "../../../helpers/user";

export const defaultControlSet: Partial<ControlSet> = {
  title: "",
  description: "",
  client_specific: false,
  controls: [],
  date_created: new Date(),
};

interface EditControlSetModalProps {
  controlset?: Partial<ControlSet> | null;
  isOpen?: boolean;
  mode?: "modal" | "page";
  onClose?: () => void;
}

const AdminEditControlSetModal: FunctionComponent<EditControlSetModalProps> = ({
  controlset = defaultControlSet,
  isOpen = true,
  mode = "modal",
  onClose = () => {},
}) => {
  const user = getUserInfo();
  const isAdmin = user.role === "admin";

  const navigate = useNavigate();
  const [editedControlSet, setEditedControlSet] = useState<
    ControlSet | Partial<ControlSet>
  >(controlset || defaultControlSet);

  const [loadedControlSet, setLoadedControlSet] = useState<ControlSet>(
    controlset as ControlSet
  );

  const isPageMode = mode === "page";

  const { id } = useParams<{ id: string }>();
  const [loadingError, setLoadingError] = useState(false);
  const [descriptionMode, setDescriptionMode] = useState("edit");

  if (id) {
    const { data, isLoading: isFetching } = useGetControlSet({ id });
    useEffect(() => {
      if (mode === "page" && id && data && !isFetching) {
        if (data[0].controlsets.length === 0) {
          setLoadingError(true);
        } else {
          setEditedControlSet(data[0].controlsets[0]);
          setLoadedControlSet(data[0].controlsets[0]);
          setDescriptionMode(
            data[0].controlsets[0].description ? "view" : "edit"
          );
        }
      }
    }, [id, mode, data, isFetching]);
  }

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabId: number) => {
    setActiveTab(tabId);
  };

  const [searchQueryControls, setSearchQueryControls] = useState("");
  const [searchResultsControls, setSearchResultsControls] = useState<Control[]>(
    []
  );

  const [searchQueryComplianceStandards, setSearchQueryComplianceStandards] =
    useState("");
  const [
    searchResultsComplianceStandards,
    setSearchResultsComplianceStandards,
  ] = useState<ComplianceStandard[]>([]);

  const updateControlSet = useUpdateControlSet();
  const createControlSet = useCreateControlSet();

  const { data: controlSearchResults, refetch: refetchControls } =
    useSearchControls({
      q: searchQueryControls,
    });

  const {
    data: complianceStandardSearchResults,
    refetch: refetchComplianceStandards,
  } = useSearchComplianceStandards({
    q: searchQueryComplianceStandards,
  });

  const toggleMode = () => {
    setDescriptionMode(descriptionMode === "view" ? "edit" : "view");
  };

  useEffect(() => {
    if (controlSearchResults) {
      setSearchResultsControls(controlSearchResults[0].controls);
    }
    if (complianceStandardSearchResults) {
      setSearchResultsComplianceStandards(
        complianceStandardSearchResults[0].compliancestandards
      );
    }
  }, [controlSearchResults, complianceStandardSearchResults]);

  useEffect(() => {
    refetchControls();
  }, [searchQueryControls, refetchControls]);

  useEffect(() => {
    refetchComplianceStandards();
  }, [searchQueryComplianceStandards, refetchComplianceStandards]);

  const [edited, setEdited] = useState(false);

  useEffect(() => {
    const isChanged = !isEqual(editedControlSet, loadedControlSet);
    setEdited(isChanged);
  }, [editedControlSet, loadedControlSet]);

  const handleAddControl = (control: Control): void => {
    let updatedControls: Control[];
    updatedControls = [...(editedControlSet.controls ?? []), control];
    setEditedControlSet((prev) => ({ ...prev, controls: updatedControls }));
  };

  const handleRemoveControl = (control: Control): void => {
    const updatedControls: Control[] =
      editedControlSet.controls?.filter((c) => c.id !== control.id) ?? [];

    setEditedControlSet((prev) => ({ ...prev, controls: updatedControls }));
  };

  const handleAddComplianceStandard = (
    complianceStandard: ComplianceStandard
  ): void => {
    let updatedComplianceStandard: ComplianceStandard[];
    updatedComplianceStandard = [
      ...(editedControlSet.compliance_standards ?? []),
      complianceStandard,
    ];
    setEditedControlSet((prev) => ({
      ...prev,
      compliance_standards: updatedComplianceStandard,
    }));
  };

  const handleRemoveComplianceStandard = (
    complianceStandard: ComplianceStandard
  ): void => {
    const updatedComplianceStandard: ComplianceStandard[] =
      editedControlSet.compliance_standards?.filter(
        (c) => c.id !== complianceStandard.id
      ) ?? [];

    setEditedControlSet((prev) => ({
      ...prev,
      compliance_standards: updatedComplianceStandard,
    }));
  };

  const renderControl = (control: Control) => (
    <ListItem className="modalEditListItem" key={control.id}>
      <ListItemText
        primary={<Link to={`/control/${control.id}`}>{control.name}</Link>}
      />

      <IconButton
        edge="end"
        aria-label="remove"
        onClick={() => handleRemoveControl(control)}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </ListItem>
  );

  const renderComplianceStandard = (complianceStandard: ComplianceStandard) => (
    <ListItem className="modalEditListItem" key={complianceStandard.id}>
      <ListItemText primary={complianceStandard.title} />

      <IconButton
        edge="end"
        aria-label="remove"
        onClick={() => handleRemoveComplianceStandard(complianceStandard)}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </ListItem>
  );
  const handleSubmit = async () => {
    try {
      if ("id" in editedControlSet) {
        await updateControlSet.mutateAsync(editedControlSet as ControlSet);
        onClose();
      } else {
        await createControlSet.mutateAsync(editedControlSet as ControlSet);
        onClose();
      }
    } catch (error) {}
  };

  const deleteControlSet = useDeleteControlSet();

  const triggerDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this Control Set?"
    );
    if (confirmDelete) {
      try {
        await deleteControlSet.mutateAsync(
          editedControlSet.id as ControlSet["id"]
        );
        navigate("/control-sets");
      } catch (error) {}
    }
  };

  const pageModeContent = (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => handleTabClick(newValue)}
            aria-label="Switch tabs"
          >
            <Tab label="Controls" {...a11yProps(0)} />
            {/* <Tab label="Compliance Standards" {...a11yProps(1)} /> */}
          </Tabs>
        </Box>

        <TabPanel value={activeTab} index={0}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" component="div">
              Controls
            </Typography>

            {editedControlSet.id && (
              <ControlTable
                parentObjectUrlKey="controlsets"
                loadPartial={true}
                parentObject={editedControlSet}
                relatedChildFieldName="controls"
              />
            )}
          </Box>
        </TabPanel>

        {/* <TabPanel value={activeTab} index={1}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" component="div">
              Compliance Standards
            </Typography>

            {editedControlSet.id && (
              <ComplianceStandardTable
                parentObjectUrlKey="controlsets"
                loadPartial={true}
                parentObject={editedControlSet}
                relatedChildFieldName="compliance_standards"
              />
            )}
          </Box>
        </TabPanel> */}
      </Box>
    </>
  );

  const content = (
    <>
      <DialogContent>
        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            disabled={!isAdmin}
            value={editedControlSet.title}
            onChange={(e) =>
              setEditedControlSet({
                ...editedControlSet,
                title: e.target.value,
              })
            }
            margin="normal"
          />
        </Box>
        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <Button
            onClick={toggleMode}
            variant="contained"
            disabled={!isAdmin}
            startIcon={
              descriptionMode === "view" ? <EditIcon /> : <VisibilityIcon />
            }
            sx={{
              margin: 2,
              marginLeft: 0,
            }}
          >
            {descriptionMode === "view"
              ? "Edit Description"
              : "Preview Description"}
          </Button>
          {descriptionMode === "edit" ? (
            <TextField
              fullWidth
              multiline
              label="Control Set Description"
              variant="outlined"
              value={editedControlSet.description}
              onChange={(e) =>
                setEditedControlSet({
                  ...editedControlSet,
                  description: e.target.value,
                })
              }
              margin="normal"
            />
          ) : (
            <Typography
              component="div"
              variant="body1"
              sx={{
                lineHeight: "1em",
                padding: 2,
                border: 2,
                borderColor: "divider",
                borderRadius: 3,
                overflow: "wrap",
              }}
            >
              <ReactMarkdown>{editedControlSet.description}</ReactMarkdown>
            </Typography>
          )}
        </Box>
        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <FormControlLabel
            disabled={!isAdmin}
            control={
              <Checkbox
                checked={editedControlSet.client_specific}
                onChange={(e) =>
                  setEditedControlSet({
                    ...editedControlSet,
                    client_specific: e.target.checked,
                  })
                }
                name="client_specific"
              />
            }
            label="Client Specific"
          />
        </Box>
        {/* controls */}
        {!isPageMode && (
          <div>
            <Box
              sx={{
                border: 1,
                borderColor: "divider",
                padding: 2,
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              <Typography variant="h6">Controls</Typography>
              <Autocomplete
                multiple
                options={searchResultsControls}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                onChange={(event, newValue) => {
                  newValue.forEach((control) => {
                    if (
                      !editedControlSet.controls?.some(
                        (c) => c.id === control.id
                      )
                    ) {
                      handleAddControl(control);
                    }
                  });
                }}
                renderTags={() => {
                  return null;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Controls"
                    variant="outlined"
                    onChange={(e) => setSearchQueryControls(e.target.value)}
                  />
                )}
              />
              <PaginatedModalComponent
                items={
                  editedControlSet.controls?.map((control) => control) ?? []
                }
                renderItem={renderControl}
              />
            </Box>
            <Box
              sx={{
                border: 1,
                borderColor: "divider",
                padding: 2,
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              <Typography variant="h6">Compliance Standards</Typography>
              <Autocomplete
                multiple
                options={searchResultsComplianceStandards}
                getOptionLabel={(option) => option.title}
                filterSelectedOptions
                onChange={(event, newValue) => {
                  newValue.forEach((complianceStandard) => {
                    if (
                      !editedControlSet.compliance_standards?.some(
                        (c) => c.id === complianceStandard.id
                      )
                    ) {
                      handleAddComplianceStandard(complianceStandard);
                    }
                  });
                }}
                renderTags={() => {
                  return null;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Controls"
                    variant="outlined"
                    onChange={(e) =>
                      setSearchQueryComplianceStandards(e.target.value)
                    }
                  />
                )}
              />
              <PaginatedModalComponent
                items={
                  editedControlSet.compliance_standards?.map(
                    (complianceStandard) => complianceStandard
                  ) ?? []
                }
                renderItem={renderComplianceStandard}
              />
            </Box>
          </div>
        )}
      </DialogContent>
    </>
  );

  if (loadingError) {
    return (
      <Layout pageTitle="" parentUrl="" parentPageName="">
        <Typography variant="h5" sx={{ color: "black", padding: 3 }}>
          Control Set not found
        </Typography>
        ;
      </Layout>
    );
  } else
    return isPageMode ? (
      <Layout
        pageTitle="Control Set"
        parentUrl="/control-sets"
        parentPageName="Control Sets"
      >
        <Box>
          <Box sx={{ paddingRight: "6vh" }}>
            <Typography fontSize={25} sx={{ color: "black", padding: 3 }}>
              {editedControlSet.title}
            </Typography>

            <DialogActions>
              <Button
                disabled={!isAdmin}
                variant="contained"
                color="error"
                onClick={triggerDelete}
              >
                Delete Control Set
              </Button>

              <Button
                disabled={!edited && !isAdmin}
                variant="contained"
                onClick={handleSubmit}
              >
                Update Control Set
              </Button>
            </DialogActions>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "100%",
                color: "black",
                border: 2,
                borderColor: "divider",
                margin: 2,
                borderRadius: 3,
              }}
            >
              {content}
            </Box>
            <Box
              sx={{
                width: "100%",

                maxWidth: "100%",
                color: "black",
                border: 2,
                borderColor: "divider",
                margin: 2,
                borderRadius: 3,
              }}
            >
              {pageModeContent}
            </Box>
          </Box>
        </Box>
      </Layout>
    ) : (
      <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography fontSize={25}>{editedControlSet.title}</Typography>
          <CloseIcon
            onClick={onClose}
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              fontSize: 30,
            }}
          />
        </DialogTitle>

        {content}

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    );
};

export default AdminEditControlSetModal;
