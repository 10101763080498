import React, { useCallback, useState } from "react";
import axios from "axios";

import { apiClient } from "../../api/apiClient";
import { useQuery } from "@tanstack/react-query";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import {
  type ControlQueryResult,
  type Control,
  ControlTableUpdateArg,
} from "../../types/controls";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export function useCreateControl(table?: ControlTableUpdateArg) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (control: Control) => {
      const updatedControl = {
        ...control,
        predecessor_controls: control.predecessor_controls?.map(
          (predecessor_control) => predecessor_control.id
        ),
      };
      try {
        const response = await apiClient.post("/api/controls/", updatedControl);

        if (table) {
          table.table.setCreatingRow(null);
        }
        toast.success(
          `Control ${control.name} ${control.title} created successfully`
        );
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
          throw error;
        } else {
          toast.error(`Error creating control: ${error}`);
        }
      }
    },
    onSuccess: (newControl: Control) => {
      queryClient.setQueryData<Control[]>(["controls"], (prevControls = []) => [
        ...prevControls,
        newControl,
      ]);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["controls"] }),
  });
}

export function useDeleteControl() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (controlId: string) => {
      try {
        await apiClient.delete(`/api/controls/${controlId}`);
        toast.success("Control successfully deleted");
      } catch (error) {
        toast.error("Failed to delete the control");
      }
    },
    onMutate: (controlId: string) => {
      queryClient.setQueryData(["controls"], (prevControls: any) =>
        prevControls?.filter((control: Control) => control.id !== controlId)
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["controls"] }),
  });
}

export function useGetControl({ id }: { id: string }) {
  return useQuery<ControlQueryResult[]>({
    queryKey: ["controls", id],
    queryFn: async () => {
      try {
        const url = `/api/controls`;
        const params = new URLSearchParams({
          id: id,
        });

        const response = await apiClient.get(url, { params });

        return [
          {
            controls: response.data.results as Control[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ controls: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

export function useSearchControls({ q }: { q: string }) {
  return useQuery<ControlQueryResult[]>({
    queryKey: ["controls", q],
    queryFn: async () => {
      try {
        const url = `/api/controls?search=${q}`;

        const response = await apiClient.get(url);

        return [
          {
            controls: response.data.results as Control[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ controls: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

export function useUpdateControl(table?: ControlTableUpdateArg) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (control: Control) => {
      const updatedControl = {
        ...control,
        predecessor_controls: control.predecessor_controls?.map(
          (predecessor_control) => predecessor_control.id
        ),
      };
      try {
        await apiClient.patch(`/api/controls/${control.id}/`, updatedControl);

        if (table) {
          table.table.setEditingRow(null);
        }
        toast.success(
          `Control ${control.name} ${control.title} updated successfully`
        );
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
          throw error;
        } else {
          toast.error(`Error updating control ${error}`);
        }
      }
    },

    onMutate: (newControlInfo: Control) => {
      queryClient.setQueryData(["controls"], (prevControls: any) =>
        prevControls?.map((prevControl: Control) =>
          prevControl.id === newControlInfo.id ? newControlInfo : prevControl
        )
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["controls"] }),
  });
}

export const useExportControls = (columnFilters: any, sorting: any) => {
  const exportControls = async () => {
    try {
      const response = await apiClient.get("/api/controls/export/", {
        params: {
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;

      const now = new Date();
      const dateString = now.toISOString().split("T")[0];
      const timestamp = now.getTime();
      const filename = `Controls-${dateString}-${timestamp}.csv`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();

      toast.success(`${filename} downloaded successfully`);
    } catch (error) {
      toast.error(`Failed to download file : ${error}`);
    }
  };

  return exportControls;
};

export const useExportRelatedControls = (
  parentField: string,
  parentId: any
) => {
  const exportControls = async () => {
    try {
      const response = await apiClient.get("/api/controls/export-related/", {
        params: {
          parentField: parentField,
          parentId: parentId,
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;

      const now = new Date();
      const dateString = now.toISOString().split("T")[0];
      const timestamp = now.getTime();
      const filename = `Controls-${parentField}-${dateString}-${timestamp}.csv`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();

      toast.success(`${filename} downloaded successfully`);
    } catch (error) {
      toast.error(`Failed to download file : ${error}`);
    }
  };

  return exportControls;
};

export const useDeleteControls = (
  columnFilters: any,
  sorting: any,
  rowCount: number
) => {
  const deleteControls = async () => {
    try {
      await apiClient.get("/api/controls/delete/", {
        params: {
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
        },
      });

      toast.success(`Deleted ${rowCount} rows successfully`);
    } catch (error) {
      toast.error("Failed to delete rows");
    } finally {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return deleteControls;
};

interface ControlVideoS3UploadResponse {
  success: boolean;
  s3_video_upload_url?: string;
  error?: any;
  message?: string;
}

export const useS3ControlVideoUpload = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const uploadVideoToS3 = async (
    controlId: string,
    file: File,
    video_type: string,
    filename: string
  ): Promise<ControlVideoS3UploadResponse> => {
    setLoading(true);
    setError(null);

    try {
      const { data: s3Data, status } = await apiClient.post(
        `/api/controls/${controlId}/upload-video/`,
        {
          video_type: video_type,
        }
      );

      await axios.put(s3Data.s3_video_upload_url, file, {
        headers: { "Content-Type": "application/octet-stream" },
      });

      toast.success(`${filename} uploaded successfully.`);

      setLoading(false);
      return {
        success: true,
      };
    } catch (error) {
      setError(error);
      setLoading(false);
      return { success: false, error };
    }
  };

  return { uploadVideoToS3, loading, error };
};

export const useGetControlVideo = () => {
  const [url, setUrl] = useState("");
  const [videoExists, setVideoExists] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);

  const fetchDownloadUrl = useCallback(
    async (controlId: string, video_type: string) => {
      setDownloadLoading(true);
      if (videoExists) {
        setDownloadLoading(false);
        return;
      }
      try {
        const response = await apiClient.post(
          `/api/controls/${controlId}/get-video-url/`,
          { video_type }
        );
        setUrl(response.data.url);
      } catch (error: any) {
        if (error.response && error.response.status === 404) {
          setVideoExists(false);
        } else {
          console.error("Error fetching video URL:", error);
        }
      } finally {
        setDownloadLoading(false);
      }
    },
    []
  );

  return {
    fetchDownloadUrl,
    url,
    videoExists,
    setVideoExists,
    downloadLoading,
  };
};

export const useDeleteControlVideo = () => {
  const deleteVideo = useCallback(
    async (controlId: string, video_type: string) => {
      try {
        await apiClient.post(
          `/api/controls/${controlId}/delete-video/?video_type=${video_type}`
        );
        return true;
      } catch (error) {
        console.error("Failed to delete video:", error);
        return false;
      }
    },
    []
  );

  return deleteVideo;
};
