import { Box, Typography } from "@mui/material";
import React from "react";
import Sidebar from "../components/sidebar";
import { Link } from "react-router-dom";
import { Engagement } from "../types/engagements";
import EngagementDropdown from "./engagementDropDown";
import { isUserCompliantWithTOSRequirements } from "../helpers/user";
import TermsAndConditionsPopup from "../pages/termsandconditions";

interface LayoutProps {
  children: React.ReactNode;
  pageTitle: string;
  parentUrl: string;
  parentPageName: string;
  type?: string;
  engagements?: Engagement[];
}

const Layout: React.FC<LayoutProps> = ({
  pageTitle,
  parentUrl,
  parentPageName,
  children,
  engagements,
  type,
}) => {
  const userCompliesWithTOS = isUserCompliantWithTOSRequirements();

  if (!userCompliesWithTOS) {
    return <TermsAndConditionsPopup />;
  }
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: { xs: "column", md: "row", lg: "row" },
        gap: "3",
        overflowY: "hidden",
        overflowX: "hidden",
        height: "100vh",
      }}
    >
      <Sidebar />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          overflowX: "hidden",
          overflowY: "scroll",
        }}
      >
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#f0f2f5",
            color: "#333",
            padding: "16px",
            display: "flex",
            alignItems: "center",
            justifyContent: type === "home" ? "flex-end" : "unset",
          }}
        >
          <Box sx={{ pr: type === "home" ? 4 : 0, display: "flex" }}>
            {parentPageName && (
              <>
                <Link to={parentUrl}>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "bold", color: "#333" }}
                  >
                    {parentPageName}
                  </Typography>
                </Link>
                <Typography sx={{ mx: 2, fontSize: "16px" }}> / </Typography>
              </>
            )}
            {engagements && engagements[0].id && (
              <>
                <EngagementDropdown engagements={engagements} />
                <Typography sx={{ mx: 2, fontSize: "16px" }}> / </Typography>
              </>
            )}

            <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
              {pageTitle}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ flexGrow: 1 }}>{children}</Box>
      </Box>
    </Box>
  );
};

export default Layout;
