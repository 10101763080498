import React, { useState, useEffect, FunctionComponent } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Box,
  Typography,
  Autocomplete,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
} from "@mui/material";

import { User } from "../../../types/users";

import {
  useCreateUser,
  useDeleteUser,
  useGetUser,
  useResetUserPassword,
  useUpdateUser,
} from "../../../hooks/users";

import CloseIcon from "@mui/icons-material/Close";
import Layout from "../../../layout";
import { Organization } from "../../../types/organizations";
import { useSearchOrganizations } from "../../../hooks/organizations";
import { getUserInfo } from "../../../helpers/user";

export const defaultUser: Partial<User> = {
  id: "",
  name: "",
  first_name: "",
  last_name: "",
  email: "",
  organization: "",
  role: "",
  must_accept_tos: false,
  has_accepted_tos: false,
  access_enabled: true,
};

interface EditUserModalProps {
  user?: Partial<User> | null;
  isOpen?: boolean;
  mode?: "modal" | "page";
  onClose?: () => void;
}

const AdminEditUserModal: FunctionComponent<EditUserModalProps> = ({
  user = defaultUser,
  isOpen = true,
  mode = "modal",
  onClose = () => {},
}) => {
  const navigate = useNavigate();
  const [editedUser, setEditedUser] = useState<User | Partial<User>>(
    user || defaultUser
  );
  const isPageMode = mode === "page";

  const { id } = useParams<{ id: string }>();
  const [loadingError, setLoadingError] = useState(false);

  if (id) {
    const { data, isLoading: isFetching } = useGetUser({ id });
    useEffect(() => {
      if (mode === "page" && id && data && !isFetching) {
        if (data.users.length === 0) {
          setLoadingError(true);
        } else setEditedUser(data.users[0]);
      }
    }, [id, mode, data, isFetching]);
  }

  const userInfo = getUserInfo();

  const isMyUser = userInfo.id === editedUser.id;

  const updateUser = useUpdateUser();
  const createUser = useCreateUser();

  const [searchQueryOrg, setSearchQueryOrg] = useState("");
  const [searchResultsOrg, setSearchResultsOrg] = useState<Organization[]>([]);

  const { data: orgSearchResults, refetch: refetchOrgs } =
    useSearchOrganizations({
      q: searchQueryOrg,
    });

  useEffect(() => {
    if (orgSearchResults) {
      setSearchResultsOrg(orgSearchResults[0].organizations);
    }
  }, [orgSearchResults]);

  useEffect(() => {
    refetchOrgs();
  }, [searchQueryOrg, refetchOrgs]);

  const handleAddOrg = (org: Organization): void => {
    setEditedUser((prev) => ({ ...prev, organization: org.name }));
  };

  const handleSubmit = async () => {
    try {
      if ("id" in editedUser) {
        await updateUser.mutateAsync(editedUser as User);
        onClose();
      } else {
        await createUser.mutateAsync(editedUser as User);
        onClose();
      }
    } catch (error) {}
  };

  const deleteUser = useDeleteUser();

  const triggerDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this User?"
    );
    if (confirmDelete) {
      try {
        await deleteUser.mutateAsync(editedUser.id as User["id"]);

        navigate("/users");
      } catch (error) {}
    }
  };

  const handlePasswordReset = async () => {
    const confirmReset = window.confirm(
      "This will clear the user's current password and they will have to click on 'Forgot Password' during login"
    );

    if (editedUser.id && confirmReset) {
      const resetPassword = useResetUserPassword(editedUser.id);
      resetPassword();
    }
  };

  const content = (
    <>
      <DialogContent>
        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <TextField
            fullWidth
            label="First Name"
            variant="outlined"
            value={editedUser.first_name}
            disabled={editedUser.id ? true : false}
            onChange={(e) =>
              setEditedUser({
                ...editedUser,
                first_name: e.target.value,
              })
            }
            margin="normal"
          />
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <TextField
            fullWidth
            label="Last Name"
            variant="outlined"
            value={editedUser.last_name}
            disabled={editedUser.id ? true : false}
            onChange={(e) =>
              setEditedUser({
                ...editedUser,
                last_name: e.target.value,
              })
            }
            margin="normal"
          />
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            value={editedUser.email}
            disabled={editedUser.id ? true : false}
            onChange={(e) =>
              setEditedUser({
                ...editedUser,
                email: e.target.value,
              })
            }
            margin="normal"
          />
        </Box>

        {!editedUser.id && (
          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Role</FormLabel>
              <RadioGroup
                row
                aria-label="role"
                name="role"
                value={editedUser.role || ""}
                onChange={(e) =>
                  setEditedUser({
                    ...editedUser,
                    role: e.target.value,
                  })
                }
              >
                <FormControlLabel
                  value="admin"
                  control={<Radio />}
                  label="Admin"
                />
                <FormControlLabel
                  value="auditor"
                  control={<Radio />}
                  label="Auditor"
                />
                <FormControlLabel
                  value="org_admin"
                  control={<Radio />}
                  label="Org Admin"
                />
                <FormControlLabel
                  value="user"
                  control={<Radio />}
                  label="User"
                />
              </RadioGroup>
            </FormControl>
          </Box>
        )}

        {editedUser.role && ["user", "org_admin"].includes(editedUser.role) && (
          <Box
            sx={{
              border: 1,
              borderColor: "divider",
              padding: 2,
              marginTop: 2,
              marginBottom: 2,
            }}
          >
            <Typography variant="h6">Organization</Typography>
            {editedUser.organization && (
              <TextField
                fullWidth
                label="Organization"
                variant="outlined"
                value={editedUser.organization}
                disabled={defaultUser && "id" in defaultUser}
                margin="normal"
              />
            )}

            {!editedUser.organization && !editedUser.id && (
              <Autocomplete
                options={searchResultsOrg}
                getOptionLabel={(option) => option.name}
                filterSelectedOptions
                onChange={(event, newValue) => {
                  const lastSelectedOrg = newValue;
                  if (lastSelectedOrg) {
                    handleAddOrg(lastSelectedOrg);
                  }
                }}
                renderTags={() => null}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search Organizations"
                    variant="outlined"
                    onChange={(e) => setSearchQueryOrg(e.target.value)}
                  />
                )}
              />
            )}
          </Box>
        )}
        {!isMyUser && (
          <>
            <Box
              sx={{
                border: 1,
                borderColor: "divider",
                padding: 2,
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editedUser.access_enabled}
                    onChange={(e) =>
                      setEditedUser({
                        ...editedUser,
                        access_enabled: e.target.checked,
                      })
                    }
                    name="access_enabled"
                  />
                }
                label="Access enabled"
              />
            </Box>
          </>
        )}
        {!isMyUser && editedUser.role && editedUser.role === "org_admin" && (
          <>
            <Box
              sx={{
                border: 1,
                borderColor: "divider",
                padding: 2,
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editedUser.must_accept_tos}
                    onChange={(e) =>
                      setEditedUser({
                        ...editedUser,
                        must_accept_tos: e.target.checked,
                      })
                    }
                    name="must_accept_tos"
                  />
                }
                label="User must accept TOS"
              />
            </Box>
          </>
        )}
        {!isMyUser && (
          <>
            <Box
              sx={{
                border: 1,
                borderColor: "divider",
                padding: 2,
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              <Button
                title="This will allow the user to start receiving password reset emails"
                variant="contained"
                onClick={handlePasswordReset}
              >
                Fix Password Reset For User
              </Button>
            </Box>
          </>
        )}
      </DialogContent>
    </>
  );

  if (loadingError) {
    return (
      <Layout pageTitle="" parentUrl="" parentPageName="">
        <Typography variant="h5" sx={{ color: "black", padding: 3 }}>
          User not found
        </Typography>
        ;
      </Layout>
    );
  } else
    return isPageMode ? (
      <Layout pageTitle="User" parentUrl="/users" parentPageName="Users">
        <Box>
          <Box sx={{ paddingRight: "6vh" }}>
            <Typography fontSize={25} sx={{ color: "black", padding: 3 }}>
              {editedUser.name}
            </Typography>

            <DialogActions>
              <Button variant="contained" color="error" onClick={triggerDelete}>
                Delete User
              </Button>

              <Button variant="contained" onClick={handleSubmit}>
                Update User
              </Button>
            </DialogActions>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "100%",
                color: "black",
                border: 2,
                borderColor: "divider",
                margin: 2,
                borderRadius: 3,
              }}
            >
              {content}
            </Box>
            <Box
              sx={{
                width: "100%",
                maxWidth: "100%",
                color: "black",
                border: 2,
                borderColor: "divider",
                margin: 2,
                borderRadius: 3,
              }}
            >
              {" "}
            </Box>
          </Box>
        </Box>
      </Layout>
    ) : (
      <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography fontSize={25}>{editedUser.name}</Typography>
          <CloseIcon
            onClick={onClose}
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              fontSize: 30,
            }}
          />
        </DialogTitle>

        {content}

        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Save</Button>
        </DialogActions>
      </Dialog>
    );
};

export default AdminEditUserModal;
