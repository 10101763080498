import React, { useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { TextField } from "@mui/material";
import Layout from "../../layout";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useCreateComplianceStandard,
  useUpdateComplianceStandard,
  useDeleteComplianceStandard,
} from "../../hooks/compliancestandards";

import { ComplianceStandard } from "../../types/compliancestandards";
import { GridCloseIcon } from "@mui/x-data-grid";

import { Link } from "react-router-dom";
import { useGetObjects } from "../../hooks/common";

const AdminComplianceStandardPage = () => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [columnFilters]);

  const { mutateAsync: deleteComplianceStandard } =
    useDeleteComplianceStandard();

  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [currentComplianceStandard, setCurrentComplianceStandard] =
    useState<ComplianceStandard | null>(null);

  const [isCreatingComplianceStandard, setIsCreatingComplianceStandard] =
    useState<boolean>(false);

  const openEditModal = (compliancestandard: ComplianceStandard) => {
    setCurrentComplianceStandard(compliancestandard);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setCurrentComplianceStandard(null);
  };

  const openCreateModal = () => {
    setIsCreatingComplianceStandard(true);
    setEditModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreatingComplianceStandard(false);
    setCurrentComplianceStandard(null);
  };

  const getParams = {
    pagination,
    columnFilters,
    sorting,
    globalFilter,
  };

  const {
    data: compliancestandardQueryResults = [],
    isError: isLoadingComplianceStandardsError,
    isLoading: isLoadingComplianceStandards,
    isFetching: isFetchingComplianceStandards,
  } = useGetObjects<
    ComplianceStandard,
    [
      "compliancestandards",
      {
        pagination: MRT_PaginationState;
        columnFilters: MRT_ColumnFiltersState;
        sorting: MRT_SortingState;
        globalFilter: string | undefined;
      }
    ]
  >(
    [
      "compliancestandards",
      { pagination, columnFilters, sorting, globalFilter },
    ],
    "compliancestandards",
    getParams
  );

  const fetchedComplianceStandards =
    compliancestandardQueryResults[0]?.data || [];

  useEffect(() => {
    const count = compliancestandardQueryResults[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [compliancestandardQueryResults]);

  const tableName = "admin_compliancestandards_table";
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const tableState = JSON.parse(localStorage.getItem("tableState") || "{}");
    return (
      tableState[tableName]?.columnVisibility || {
        id: false,
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tableState") || "{}"),
        [tableName]: {
          columnVisibility,
        },
      })
    );
  }, [columnVisibility, tableName]);

  const handleCreateComplianceStandard: MRT_TableOptions<ComplianceStandard>["onCreatingRowSave"] =
    async ({ values, table }) => {
      const newValidationErrors = validateComplianceStandard(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await createComplianceStandard(values);
    };

  const handleSaveComplianceStandard: MRT_TableOptions<ComplianceStandard>["onEditingRowSave"] =
    async ({ values, table }) => {
      const newValidationErrors = validateComplianceStandard(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await updateComplianceStandard(values);
    };

  const openDeleteConfirmModal = (row: MRT_Row<ComplianceStandard>) => {
    if (
      window.confirm("Are you sure you want to delete this ComplianceStandard?")
    ) {
      deleteComplianceStandard(row.original.id);
    }
  };

  function validateComplianceStandard(compliancestandard: ComplianceStandard) {
    const validateRequired = (value: string) => !!value.trim().length;

    return {
      name: !validateRequired(compliancestandard.title)
        ? "Title is required"
        : "",
    };
  }

  const columns = useMemo<MRT_ColumnDef<ComplianceStandard>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
        enableSorting: false,
      },

      {
        accessorKey: "title",
        header: "Title",
        Cell: ({ row }) => {
          return <>{row.original.title}</>;
        },
      },
      {
        accessorKey: "version",
        header: "Version",
      },
      {
        accessorKey: "predecessor_standards",
        enableSorting: false,
        header: "Predecessor Standards",
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.predecessor_standards.map((standard, index) => (
                  <div key={index}>{standard.title}</div>
                ))}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "description_video",
        enableSorting: false,
        header: "Description Video",
      },
      {
        accessorKey: "date_created",
        header: "Created On",
        Cell: ({ row }) => {
          if (row.original.date_created) {
            const date = new Date(row.original.date_created);
            return new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }).format(date);
          }
        },
        muiEditTextFieldProps: {
          type: "Date",
          enableEditing: false,
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    enableGlobalFilter: false,
    data: fetchedComplianceStandards,
    initialState: {
      showColumnFilters: false,
      density: "compact",
      columnVisibility: columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    selectAllMode: "all",
    enableColumnResizing: true,
    getRowId: (row) => row.id,
    rowNumberDisplayMode: "original",
    muiToolbarAlertBannerProps: isLoadingComplianceStandardsError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateComplianceStandard,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveComplianceStandard,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">New Compliance Standard</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => table.setCreatingRow(null)}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <GridCloseIcon />
        </IconButton>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),

    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle
          variant="h3"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Edit Compliance Standard
          <IconButton
            aria-label="close"
            onClick={() => table.setEditingRow(null)}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <GridCloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    enableRowActions: true,
    renderRowActionMenuItems: ({ row, table }) => [
      <Box key={row.id}>
        <Tooltip title="Edit">
          <IconButton onClick={() => openEditModal(row.original)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>,
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            openCreateModal();
          }}
        >
          New Compliance Standard
        </Button>
      </Box>
    ),
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      columnVisibility: columnVisibility,
      isLoading: isLoadingComplianceStandards,
      showAlertBanner: isLoadingComplianceStandardsError,
      showProgressBars: isFetchingComplianceStandards,
    },
  });

  const { mutateAsync: updateComplianceStandard } = useUpdateComplianceStandard(
    { table }
  );
  const { mutateAsync: createComplianceStandard } = useCreateComplianceStandard(
    { table }
  );

  return (
    <Layout pageTitle="" parentUrl="" parentPageName="">
      <div className="fullPageTable">
        <MaterialReactTable table={table} />

        {/* {currentComplianceStandard && (
          <EditComplianceStandardModal
            compliancestandard={currentComplianceStandard}
            isOpen={isEditModalOpen}
            onClose={closeEditModal}
          />
        )}
        {isCreatingComplianceStandard && (
          <EditComplianceStandardModal
            compliancestandard={defaultComplianceStandard}
            isOpen={isEditModalOpen}
            onClose={closeCreateModal}
          />
        )} */}
      </div>
    </Layout>
  );
};

export default AdminComplianceStandardPage;
