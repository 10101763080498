import React from "react";
import Layout from "../../layout";
import UserTable from "../../components/tables/users/admin";
import OrganizationTable from "../../components/tables/organizations";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";

const AdminUserPage = () => {
  return (
    <Layout pageTitle="User Management" parentUrl="" parentPageName="">
      <Grid item xs={6}>
        <Card>
          <Box sx={{ mt: 0.2, textAlign: "end", backgroundColor: "grey.100" }}>
            <Typography sx={{ padding: "1rem", fontWeight: 700 }}>
              Users
            </Typography>
          </Box>
          <UserTable />
        </Card>
        <Card>
          <Box sx={{ mt: 2, textAlign: "end", backgroundColor: "grey.100" }}>
            <Typography sx={{ padding: "1rem", fontWeight: 700 }}>
              Organizations
            </Typography>
          </Box>
          <OrganizationTable />
        </Card>
      </Grid>
    </Layout>
  );
};

export default AdminUserPage;
