import React from "react";
import { Navigate } from "react-router-dom";
import AdminEditEngagementModal from "./admin";
import UserEditEngagementModal from "./user";
import { getUserInfo } from "../../../helpers/user";

const EditEngagementPageRouteWrapper = () => {
  const userInfo = getUserInfo();

  if (userInfo.role === "admin") {
    return <AdminEditEngagementModal mode="page" />;
  } else if (
    userInfo.role === "user" ||
    userInfo.role === "auditor" ||
    userInfo.role === "org_admin"
  ) {
    return <UserEditEngagementModal mode="page" />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default EditEngagementPageRouteWrapper;
