import React from "react";
import Layout from "../../layout";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { Box, Typography } from "@mui/material";
import OrgAdminUserTable from "../../components/tables/users/org_admin";

const OrgAdminUserPage = () => {
  return (
    <Layout pageTitle="User Management" parentUrl="" parentPageName="">
      <Grid item xs={6}>
        <Card>
          <Box sx={{ mt: 0.2, textAlign: "end", backgroundColor: "grey.100" }}>
            <Typography sx={{ padding: "1rem", fontWeight: 700 }}>
              Users
            </Typography>
          </Box>
          <OrgAdminUserTable />
        </Card>
      </Grid>
    </Layout>
  );
};

export default OrgAdminUserPage;
