import React, { useEffect, useMemo, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ReactMarkdown from "react-markdown";
import Layout from "../../layout";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";

import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";

import { EvidenceRequest } from "../../types/evidencerequests";
import { Link } from "react-router-dom";
import { useGetObjects } from "../../hooks/common";
import { getUserInfo } from "../../helpers/user";
import OrgAdminEditEvidenceRequestModal from "../../components/evidencerequests/edit/org_admin";
import { EvidenceRequestsPieChart } from "../../components/charts/EvidenceRequestsPieChart";
import EngagementCards from "../../components/engagements/cards";

interface UserEvidenceRequestPageProp {
  cardMode?: boolean;
}

const UserEvidenceRequestPage: React.FC<UserEvidenceRequestPageProp> = ({
  cardMode = false,
}) => {
  const showLayout = !cardMode;
  const user = getUserInfo();

  const isOrgAdmin = user.role == "org_admin";

  const [currentEvidenceRequest, setCurrentEvidenceRequest] =
    useState<EvidenceRequest | null>(null);
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);

  const openEditModal = (evidence_request: EvidenceRequest) => {
    setCurrentEvidenceRequest(evidence_request);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setCurrentEvidenceRequest(null);
  };

  const [showAttentionRequired, setShowAttentionRequired] =
    useState<boolean>(false);

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [columnFilters]);

  const getParams = {
    pagination,
    columnFilters,
    sorting,
    globalFilter,
    relatedParentField: undefined,
    relatedChildField: undefined,
    relatedParentId: undefined,
    showRelatedOnly: undefined,
    showAttentionRequired: showAttentionRequired,
  };

  const {
    data: evidencerequestQueryResults = [],
    isError: isLoadingEvidenceRequestsError,
    isLoading: isLoadingEvidenceRequests,
    isFetching: isFetchingEvidenceRequests,
  } = useGetObjects<
    EvidenceRequest,
    [
      "evidencerequests",
      {
        pagination: MRT_PaginationState;
        columnFilters: MRT_ColumnFiltersState;
        sorting: MRT_SortingState;
        globalFilter: string | undefined;
        relatedParentField: undefined;
        relatedChildField: undefined;
        relatedParentId: undefined;
        showRelatedOnly: undefined;
        showAttentionRequired: boolean | undefined;
      }
    ]
  >(
    [
      "evidencerequests",
      {
        pagination,
        columnFilters,
        sorting,
        globalFilter,
        relatedParentField: undefined,
        relatedChildField: undefined,
        relatedParentId: undefined,
        showRelatedOnly: undefined,
        showAttentionRequired: showAttentionRequired,
      },
    ],
    "evidencerequests",
    getParams
  );

  const fetchedEvidenceRequests = evidencerequestQueryResults[0]?.data || [];

  useEffect(() => {
    const count = evidencerequestQueryResults[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [evidencerequestQueryResults]);

  const tableName = "user_evidence_requests_page_table";
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const tableState = JSON.parse(localStorage.getItem("tableState") || "{}");
    return (
      tableState[tableName]?.columnVisibility || {
        id: false,
        controls: showLayout,
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tableState") || "{}"),
        [tableName]: {
          columnVisibility,
        },
      })
    );
  }, [columnVisibility, tableName]);

  const columns = useMemo<MRT_ColumnDef<EvidenceRequest>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
        enableSorting: false,
      },
      {
        accessorKey: "title",
        header: "Title",
        Cell: ({ row }) => {
          return (
            <>
              <Box sx={{ marginLeft: "10px" }}>
                <Link to={`/evidence-request/${row.original.id}`}>
                  {row.original.title}
                </Link>
              </Box>
            </>
          );
        },
      },

      {
        accessorKey: "status",
        header: "Status",
        filterVariant: "select",
        filterSelectOptions: [
          "New",
          "In Progress",
          "Submitted For Review",
          "In Review",
          "Followup Required",
          "Accepted",
          "Overdue",
        ],
      },
      {
        accessorKey: "due_date",
        enableColumnFilter: false,
        header: "Due Date",
        Cell: ({ row }) => {
          if (row.original.due_date) {
            const date = new Date(row.original.due_date);
            return new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour12: true,
            }).format(date);
          }
        },

        muiEditTextFieldProps: {
          type: "Date",
          required: false,
        },
      },
      {
        accessorKey: "verifiers",

        enableSorting: false,
        header: "Verifiers",
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.verifiers.map((user, index) => (
                  <div key={index}>
                    <div>{user.name}</div>
                  </div>
                ))}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "users",
        enableSorting: false,
        header: "Users",
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.users.map((user, index) => (
                  <div key={index}>
                    <div>{user.name}</div>
                  </div>
                ))}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "description",
        enableSorting: false,
        header: "Description",
        size: 240,

        Cell: ({ row }) => (
          <div
            style={{
              textAlign: "left",
              maxHeight: showLayout ? "5em" : "3em",
            }}
          >
            <ReactMarkdown>{row.original.description}</ReactMarkdown>
          </div>
        ),
      },
      {
        accessorKey: "last_action",
        enableColumnFilter: false,
        header: "Last Action",
        enableEditing: false,
        enableSorting: false,
      },

      {
        accessorKey: "controls",
        header: "Controls",
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.controls.map((control, index) => (
                  <div key={index}>
                    <Link to={`/control/${control.id}`}>{control.name}</Link>
                  </div>
                ))}
              </div>
            </>
          );
        },
      },

      {
        accessorKey: "engagements",
        header: "Engagements",
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.engagements.map((engagement, index) => (
                  <div key={index}>
                    <Link to={`/engagement/${engagement.id}`}>
                      {engagement.name}
                    </Link>
                  </div>
                ))}
              </div>
            </>
          );
        },
      },
    ],
    [validationErrors]
  );

  const table = useMaterialReactTable({
    columns,
    enableGlobalFilter: false,
    data: fetchedEvidenceRequests,
    initialState: {
      showColumnFilters: false,
      density: "compact",
      columnVisibility: columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    enableBottomToolbar: true,
    manualFiltering: true,
    enableRowActions: isOrgAdmin,
    renderEmptyRowsFallback: () => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <Typography gutterBottom>
          <i>No Evidence Requests found.</i>
        </Typography>
      </Box>
    ),
    renderRowActionMenuItems: ({ row, table }) => [
      <div key={row.id}>
        <Box>
          {isOrgAdmin && (
            <Tooltip title="Edit">
              <IconButton onClick={() => openEditModal(row.original)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </div>,
    ],
    manualPagination: true,
    manualSorting: true,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    selectAllMode: "all",
    enableColumnResizing: true,
    getRowId: (row) => row.id,
    rowNumberDisplayMode: "original",
    muiToolbarAlertBannerProps: isLoadingEvidenceRequestsError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },

    renderBottomToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      ></Box>
    ),
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      columnVisibility: columnVisibility,
      isLoading: isLoadingEvidenceRequests,
      showAlertBanner: isLoadingEvidenceRequestsError,
      showProgressBars: isFetchingEvidenceRequests,
    },
  });

  const tableContent = (
    <>
      <MaterialReactTable table={table} />
      {currentEvidenceRequest && isOrgAdmin && (
        <OrgAdminEditEvidenceRequestModal
          evidencerequest={currentEvidenceRequest}
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
        />
      )}
    </>
  );

  const cardModeContent = (
    <>
      <Grid
        sx={{ backgroundColor: "white", mt: 1, pl: 0 }}
        container
        spacing={2}
        padding={2}
      >
        <Grid item sm={12}>
          <EvidenceRequestsPieChart
            rowCount={rowCount}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            setShowAttentionRequired={setShowAttentionRequired}
            showAttentionRequired={showAttentionRequired}
          />
        </Grid>
      </Grid>
      {tableContent}
    </>
  );
  return (
    <>
      {showLayout ? (
        <Layout pageTitle="Evidence Requests" parentUrl="" parentPageName="">
          <div className="fullPageTable">{tableContent}</div>
        </Layout>
      ) : (
        <>
          <div className="cardMode">{cardModeContent}</div>
        </>
      )}
    </>
  );
};

export default UserEvidenceRequestPage;
