import React, { useState, ReactElement } from "react";

import { Button, List, Box } from "@mui/material";

interface PaginationProps<T> {
  items: T[];
  renderItem: (item: T) => ReactElement;
  itemsPerPage?: number;
}

export default function PaginatedModalComponent<T>({
  items,
  renderItem,
  itemsPerPage = 5,
}: PaginationProps<T>) {
  const [page, setPage] = useState(0);
  const displayItems = [...items].reverse();
  const startIndex = page * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = displayItems.slice(startIndex, endIndex);

  const totalPages = Math.ceil(items.length / itemsPerPage);

  const handleNext = () => {
    setPage((currentPage) => Math.min(currentPage + 1, totalPages - 1));
  };

  const handlePrevious = () => {
    setPage((currentPage) => Math.max(currentPage - 1, 0));
  };

  if (items.length === 0) {
    return null;
  }

  return (
    <Box>
      <List>{currentItems.map(renderItem)}</List>
      <Box display="flex" justifyContent="center" marginTop={2}>
        <Button onClick={handlePrevious} disabled={page === 0}>
          Previous
        </Button>
        <Button onClick={handleNext} disabled={page === totalPages - 1}>
          Next
        </Button>
      </Box>
    </Box>
  );
}
