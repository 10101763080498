import React, { useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { TextField, Typography } from "@mui/material";
import Layout from "../../layout";
import { toast } from "react-toastify";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";

import {
  Box,
  Modal,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useCreateControl,
  useUpdateControl,
  useDeleteControl,
  useExportControls,
  useDeleteControls,
} from "../../hooks/controls";
import { Control } from "../../types/controls";
import { GridCloseIcon } from "@mui/x-data-grid";
import AdminEditControlModal, {
  defaultControl,
} from "../../components/controls/edit/admin";
import { Link } from "react-router-dom";
import ImportFromCSV from "../../components/csv/importFromCSV";
import { useGetObjects } from "../../hooks/common";
import ControlVideosComponent from "../../components/controls/edit/videos";
import { getUserInfo } from "../../helpers/user";
import { baseURL } from "../../api/apiClient";

const AdminControlPage = () => {
  const userInfo = getUserInfo();
  const isAdmin = userInfo.role === "admin";

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [columnFilters]);

  const { mutateAsync: deleteControl } = useDeleteControl();

  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [currentControl, setCurrentControl] = useState<Control | null>(null);

  const [isCreatingControl, setIsCreatingControl] = useState<boolean>(false);

  const openEditModal = (control: Control) => {
    setCurrentControl(control);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setCurrentControl(null);
  };

  const openCreateModal = () => {
    setIsCreatingControl(true);
    setEditModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreatingControl(false);
    setCurrentControl(null);
  };

  const getParams = {
    pagination,
    columnFilters,
    sorting,
    globalFilter,
  };

  const {
    data: controlQueryResults = [],
    isError: isLoadingControlsError,
    isLoading: isLoadingControls,
    isFetching: isFetchingControls,
  } = useGetObjects<
    Control,
    [
      "controls",
      {
        pagination: MRT_PaginationState;
        columnFilters: MRT_ColumnFiltersState;
        sorting: MRT_SortingState;
        globalFilter: string | undefined;
      }
    ]
  >(
    ["controls", { pagination, columnFilters, sorting, globalFilter }],
    "controls",
    getParams
  );

  const fetchedControls = controlQueryResults[0]?.data || [];

  const tableName = "admin_controls_page_table";
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const tableState = JSON.parse(localStorage.getItem("tableState") || "{}");
    return (
      tableState[tableName]?.columnVisibility || {
        id: false,
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tableState") || "{}"),
        [tableName]: {
          columnVisibility,
        },
      })
    );
  }, [columnVisibility, tableName]);

  useEffect(() => {
    const count = controlQueryResults[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [controlQueryResults]);

  const handleCreateControl: MRT_TableOptions<Control>["onCreatingRowSave"] =
    async ({ values, table }) => {
      const newValidationErrors = validateControl(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await createControl(values);
    };

  const handleSaveControl: MRT_TableOptions<Control>["onEditingRowSave"] =
    async ({ values, table }) => {
      const newValidationErrors = validateControl(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await updateControl(values);
    };

  const openDeleteConfirmModal = (row: MRT_Row<Control>) => {
    if (window.confirm("Are you sure you want to delete this Control?")) {
      deleteControl(row.original.id);
    }
  };

  const exportControls = useExportControls(columnFilters, sorting);
  const deleteControls = useDeleteControls(columnFilters, sorting, rowCount);

  const triggerDelete = async () => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete all currently filtered Controls?"
    );
    if (confirmDelete) {
      try {
        await deleteControls();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleExport = async () => {
    try {
      await exportControls();
    } catch (error) {
      console.error(error);
      toast.error("Failed to export");
    } finally {
    }
  };

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const openImportModal = () => setIsImportModalOpen(true);
  const closeImportModal = () => setIsImportModalOpen(false);

  function validateControl(control: Control) {
    const validateRequired = (value: string) => !!value.trim().length;

    return {
      name: !validateRequired(control.name) ? "Name is required" : "",
      description: !validateRequired(control.description)
        ? "Description is required"
        : "",
    };
  }

  const columns = useMemo<MRT_ColumnDef<Control>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
        enableSorting: false,
      },

      {
        accessorKey: "name",
        header: "Name",
        Cell: ({ row }) => {
          return (
            <>
              <Link to={`/control/${row.original.id}`}>
                {row.original.name}
              </Link>
            </>
          );
        },

        muiEditTextFieldProps: {
          type: "text",
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              name: undefined,
            }),
        },
      },
      {
        accessorKey: "title",
        header: "Title",

        muiEditTextFieldProps: {
          type: "text",
          required: true,
          error: !!validationErrors?.title,
          helperText: validationErrors?.title,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              title: undefined,
            }),
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        enableSorting: false,
        size: 240,

        Cell: ({ row }) => (
          <div style={{ textAlign: "left", maxHeight: "110px" }}>
            <ReactMarkdown>{row.original.description}</ReactMarkdown>
          </div>
        ),
        muiEditTextFieldProps: {
          type: "text",
          required: true,
          error: !!validationErrors?.description,
          helperText: validationErrors?.description,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              description: undefined,
            }),
        },
      },

      {
        accessorKey: "preventive",
        header: "Preventive",
        accessorFn: (row) => (row.preventive ? "Yes" : "No"),
        muiEditTextFieldProps: {
          type: "text",
        },
      },

      {
        accessorKey: "administrative",
        header: "Administrative",
        accessorFn: (row) => (row.administrative ? "Yes" : "No"),
        muiEditTextFieldProps: {
          type: "text",
        },
      },

      {
        accessorKey: "detective",
        header: "Detective",
        accessorFn: (row) => (row.detective ? "Yes" : "No"),
        muiEditTextFieldProps: {
          type: "text",
        },
      },
      {
        accessorKey: "control_description_video_exists",
        header: "Control Description Video",
        enableColumnFilter: false,
        enableSorting: false,

        Cell: ({ row }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <ControlVideosComponent
              video_type="control_description"
              control={row.original}
              tableMode={true}
            />
          </Box>
        ),
      },

      {
        accessorKey: "predecessor_controls",
        header: "Predecessor Controls",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.predecessor_controls.map(
                  (predecessor_control, index) => (
                    <div key={index}>
                      <Link to={`/control/${predecessor_control.id}`}>
                        {predecessor_control.name}
                      </Link>
                    </div>
                  )
                )}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "date_created",
        enableColumnFilter: false,
        header: "Created On",
        Cell: ({ row }) => {
          if (row.original.date_created) {
            const date = new Date(row.original.date_created);
            return new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }).format(date);
          }
        },
        muiEditTextFieldProps: {
          type: "Date",
          enableEditing: false,
        },
      },
    ],
    [validationErrors]
  );

  const table = useMaterialReactTable({
    columns,
    enableGlobalFilter: false,
    data: fetchedControls,
    initialState: {
      showColumnFilters: false,
      density: "compact",
      columnVisibility: columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    selectAllMode: "all",
    enableColumnResizing: true,
    getRowId: (row) => row.id,
    rowNumberDisplayMode: "original",
    muiToolbarAlertBannerProps: isLoadingControlsError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateControl,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveControl,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        {isAdmin && (
          <>
            <DialogTitle variant="h3">New Control</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => table.setCreatingRow(null)}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <GridCloseIcon />
            </IconButton>
            <DialogContent
              sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              {internalEditComponents}
            </DialogContent>
            <DialogActions>
              <MRT_EditActionButtons variant="text" table={table} row={row} />
            </DialogActions>
          </>
        )}
      </>
    ),

    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        {isAdmin && (
          <>
            <DialogTitle
              variant="h3"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Edit Control
              <IconButton
                aria-label="close"
                onClick={() => table.setEditingRow(null)}
                sx={{ position: "absolute", right: 8, top: 8 }}
              >
                <GridCloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent
              sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
            >
              {internalEditComponents}
            </DialogContent>
            <DialogActions>
              <MRT_EditActionButtons variant="text" table={table} row={row} />
            </DialogActions>
          </>
        )}
      </>
    ),
    enableRowActions: isAdmin,
    enableBottomToolbar: true,

    renderRowActionMenuItems: ({ row, table }) => [
      <div key={row.id}>
        {isAdmin && (
          <Box>
            <Tooltip title="Edit">
              <IconButton onClick={() => openEditModal(row.original)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                color="error"
                onClick={() => openDeleteConfirmModal(row)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        )}
      </div>,
    ],
    renderBottomToolbarCustomActions: ({ table }) => (
      <>
        {isAdmin && (
          <>
            {" "}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  openCreateModal();
                }}
              >
                New Control
              </Button>
              <Button variant="contained" color="error" onClick={triggerDelete}>
                Delete {rowCount} Rows
              </Button>

              <Button variant="contained" onClick={handleExport}>
                Export {rowCount} Rows
              </Button>

              <Button variant="contained" onClick={openImportModal}>
                Import Controls
              </Button>
              <Modal
                open={isImportModalOpen}
                onClose={closeImportModal}
                id="modal"
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "90vh",
                    width: "90vw",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: "8px",
                    overflow: "auto",
                  }}
                >
                  <Typography id="modal-title" variant="h6" component="h2">
                    CSV Import
                  </Typography>
                  <hr />
                  <Typography
                    id="modal-description"
                    sx={{ mt: 2 }}
                  ></Typography>
                  <ImportFromCSV
                    apiUrl={`${baseURL}/api/controls/import/`}
                    importType="controls"
                    onClose={closeImportModal}
                  />

                  <Button onClick={closeImportModal} sx={{ mt: 2 }}>
                    Close
                  </Button>
                </Box>
              </Modal>
            </Box>
          </>
        )}
      </>
    ),
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      columnVisibility: columnVisibility,
      isLoading: isLoadingControls,
      showAlertBanner: isLoadingControlsError,
      showProgressBars: isFetchingControls,
    },
  });

  const { mutateAsync: updateControl } = useUpdateControl({ table });
  const { mutateAsync: createControl } = useCreateControl({ table });

  return (
    <Layout pageTitle="Controls" parentUrl="" parentPageName="">
      <div className="fullPageTable">
        <MaterialReactTable table={table} />
        {currentControl && isAdmin && (
          <AdminEditControlModal
            control={currentControl}
            isOpen={isEditModalOpen}
            onClose={closeEditModal}
          />
        )}
        {isCreatingControl && isAdmin && (
          <AdminEditControlModal
            control={defaultControl}
            isOpen={isEditModalOpen}
            onClose={closeCreateModal}
          />
        )}
      </div>
    </Layout>
  );
};

export default AdminControlPage;
