import React from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { apiClient, baseURL } from "../../api/apiClient";

export const Logout: React.FC = () => {
  clearUserCredentials();
  SendLogoutApiRequest();
  return <Navigate to="/login" />;
};

const clearUserCredentials = (): boolean => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user_data");
  localStorage.removeItem("last_viewed_engagement");

  return true;
};

const SendLogoutApiRequest = async () => {
  try {
    const response = await apiClient.get(`${baseURL}/auth/cognito-logout/`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    if (response && response.data.logout_url) {
      window.location.href = response.data.logout_url;
      return true;
    }
  } catch (error) {
    return false;
  }
};
