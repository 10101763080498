import React, { useState, useEffect, FunctionComponent } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Tabs,
  Tab,
  Grid,
} from "@mui/material";

import { useGetControl } from "../../../hooks/controls";

import CloseIcon from "@mui/icons-material/Close";
import Layout from "../../../layout";
import { Control } from "../../../types/controls";
import { TabPanel, a11yProps } from "../../../helpers/functions";
import PredecessorControlTable from "../../tables/predecessor_controls";
import ControlVideosComponent from "./videos";
import { defaultControl } from "./admin";

interface EditControlModalProps {
  control?: Partial<Control> | null;
  isOpen?: boolean;
  mode?: "modal" | "page";
  onClose?: () => void;
}

const UserViewControlModal: FunctionComponent<EditControlModalProps> = ({
  control = defaultControl,
  isOpen = true,
  mode = "page",
  onClose = () => {},
}) => {
  const [editedControl, setEditedControl] = useState<
    Control | Partial<Control>
  >(control || defaultControl);
  const isPageMode = mode === "page";

  const { id } = useParams<{ id: string }>();
  const [loadingError, setLoadingError] = useState(false);

  if (id) {
    const { data, isLoading: isFetching } = useGetControl({ id });
    useEffect(() => {
      if (mode === "page" && id && data && !isFetching) {
        if (data[0].controls.length === 0) {
          setLoadingError(true);
        } else {
          const deepCopiedControl = JSON.parse(
            JSON.stringify(data[0].controls[0])
          );
          setEditedControl(deepCopiedControl);
        }
      }
    }, [id, mode, data, isFetching]);
  }

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabId: number) => {
    setActiveTab(tabId);
  };

  const pageModeContent = (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => handleTabClick(newValue)}
            aria-label="Switch tabs"
          >
            <Tab label="Predecessor Controls" {...a11yProps(0)} />
            <Tab label="Control Videos" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={activeTab} index={0}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" component="div">
              Controls
            </Typography>

            {editedControl.id && (
              <PredecessorControlTable
                parentObjectUrlKey="controls"
                loadPartial={true}
                parentObject={editedControl}
                relatedChildFieldName="predecessor_controls"
              />
            )}
          </Box>
        </TabPanel>

        <TabPanel value={activeTab} index={1}>
          <Box sx={{ padding: 2 }}>
            {editedControl.id && (
              <>
                <Grid container gap={2}>
                  <ControlVideosComponent
                    video_type="control_description"
                    control={editedControl}
                  />
                  {/* <ControlVideosComponent
                    video_type="evidence_description"
                    control={editedControl}
                  /> */}
                </Grid>
              </>
            )}
          </Box>
        </TabPanel>
      </Box>
    </>
  );

  const content = (
    <>
      <DialogContent>
        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <TextField
            fullWidth
            label="Name"
            variant="outlined"
            value={editedControl.name}
            disabled={true}
            margin="normal"
          />
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <TextField
            fullWidth
            label="Title"
            variant="outlined"
            value={editedControl.title}
            disabled={true}
            margin="normal"
          />
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <Typography
            component="div"
            variant="body1"
            sx={{
              lineHeight: "1em",
              padding: 2,
              border: 2,
              borderColor: "divider",
              borderRadius: 3,
              overflow: "wrap",
            }}
          >
            <ReactMarkdown>{editedControl.description}</ReactMarkdown>
          </Typography>
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={editedControl.preventive}
                disabled={true}
                name="preventive"
              />
            }
            label="Preventive"
          />
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={editedControl.administrative}
                disabled={true}
                name="administrative"
              />
            }
            label="Administrative"
          />
        </Box>

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={editedControl.detective}
                disabled={true}
                name="detective"
              />
            }
            label="Detective"
          />
        </Box>
      </DialogContent>
    </>
  );

  if (loadingError) {
    return (
      <Layout pageTitle="" parentUrl="" parentPageName="">
        <Typography variant="h5" sx={{ color: "black", padding: 3 }}>
          Control not found
        </Typography>
        ;
      </Layout>
    );
  } else
    return isPageMode ? (
      <Layout pageTitle="Control" parentUrl="/" parentPageName="Controls">
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "100%",
                color: "black",
                border: 2,
                borderColor: "divider",
                margin: 2,
                borderRadius: 3,
              }}
            >
              {content}
            </Box>
            <Box
              sx={{
                width: "100%",
                maxWidth: "100%",
                color: "black",
                border: 2,
                borderColor: "divider",
                margin: 2,
                borderRadius: 3,
              }}
            >
              {pageModeContent}
            </Box>
          </Box>
        </Box>
      </Layout>
    ) : (
      <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography fontSize={25}>{editedControl.name}</Typography>
          <CloseIcon
            onClick={onClose}
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              fontSize: 30,
              cursor: "pointer",
            }}
          />
        </DialogTitle>

        {content}
      </Dialog>
    );
};

export default UserViewControlModal;
