import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Grid,
  LinearProgress,
  Modal,
  IconButton,
} from "@mui/material";
import { Control } from "../../../types/controls";
import {
  useGetControlVideo,
  useS3ControlVideoUpload,
} from "../../../hooks/controls";
import { GridCloseIcon } from "@mui/x-data-grid";
import { getUserInfo } from "../../../helpers/user";

import { useUpdateControl } from "../../../hooks/controls";
import { AdminControlVideosTranscriptComponent } from "./video_transcripts_admin";
import { UserControlVideosTranscriptComponent } from "./video_transcripts_user";

interface ControlVideosComponentProps {
  control: Control | Partial<Control>;
  video_type: "control_description" | "evidence_description";
  tableMode?: boolean;
}

const ControlVideosComponent = ({
  control,
  video_type,
  tableMode = false,
}: ControlVideosComponentProps) => {
  const { uploadVideoToS3, loading, error } = useS3ControlVideoUpload();

  const {
    fetchDownloadUrl,
    url,
    videoExists,
    setVideoExists,
    downloadLoading,
  } = useGetControlVideo();

  const [editedControl, setEditedControl] = useState<
    Control | Partial<Control>
  >(control);

  const user = getUserInfo();
  const isAdmin = user.role === "admin";
  const videoRef = useRef<HTMLVideoElement>(null);

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [playRequested, setPlayRequested] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (url && playRequested) {
      videoRef.current?.load();
      videoRef.current?.play();
    }
  }, [url, playRequested]);

  const videoTypeText = video_type
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  useEffect(() => {
    if (control.id) {
      checkVideoExists();
    }

    if (control.id && playRequested && !url) {
      fetchDownloadUrl(control.id, video_type);
    }
  }, [control, video_type, url, playRequested, videoExists]);

  const checkVideoExists = () => {
    const exists =
      video_type === "control_description"
        ? control.control_description_video_exists
        : control.evidence_description_video_exists;

    setVideoExists(!!exists);
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file && control.id) {
      const uploadResponse = await uploadVideoToS3(
        control.id,
        file,
        video_type,
        file.name
      );

      if (uploadResponse.success) {
        setSelectedFile(file);
        setPlayRequested(false);

        fetchDownloadUrl(control.id, video_type);
      } else {
        console.error("Upload failed", uploadResponse.error);
      }
    }
  };

  const handleUploadClick = async () => {
    if (selectedFile && control.id) {
      const uploadResponse = await uploadVideoToS3(
        control.id,
        selectedFile,
        video_type,
        selectedFile.name
      );
      if (uploadResponse.success) {
        setPlayRequested(false);
        fetchDownloadUrl(control.id, video_type);
      } else {
        console.error("Upload failed", uploadResponse.error);
      }
    }
  };

  const handlePlayClick = () => {
    setPlayRequested(true);
    setOpenModal(true);
    if (!url && control.id) {
      fetchDownloadUrl(control.id, video_type);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Box
        sx={{
          border: !tableMode ? 2 : 0,
          display: !videoExists && !isAdmin ? "none" : "block",
          borderColor: !tableMode ? "divider" : "transparent",
          boxShadow: !tableMode
            ? "2px 2px 2px 1px rgba(0, 0, 255, .1)"
            : "none",
          padding: !tableMode ? 2 : 0,
          position: !tableMode ? "relative" : "static",
        }}
      >
        {loading && <LinearProgress />}
        {!tableMode && videoExists && (
          <Typography variant="h6" gutterBottom>
            {videoTypeText} Video
          </Typography>
        )}

        {control.id && !videoExists && isAdmin && !tableMode && (
          <Grid container spacing={2} alignItems="center">
            <>
              <Grid item xs={12}>
                <input
                  type="file"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id={`raised-button-file-${video_type}`}
                />
                <label htmlFor={`raised-button-file-${video_type}`}>
                  <Button
                    variant="contained"
                    component="span"
                    onClick={handleUploadClick}
                    disabled={loading}
                  >
                    {loading
                      ? "Uploading..."
                      : video_type === "control_description"
                      ? "Upload Control Description Video"
                      : "Upload Evidence Description Video"}
                  </Button>
                </label>
                {error && (
                  <Typography color="error">{error.message}</Typography>
                )}
              </Grid>
            </>
          </Grid>
        )}
        {videoExists && (
          <>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={handlePlayClick}
                  disabled={downloadLoading}
                >
                  {downloadLoading ? <CircularProgress size={24} /> : `Play `}
                </Button>
              </Grid>
            </Grid>
          </>
        )}

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          closeAfterTransition
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "auto",
            p: 1,
          }}
        >
          <Box
            sx={{
              position: "relative",
              background: "white",
              p: 4,
              width: "90%",
              maxWidth: "1400px",
              borderRadius: 2,
              boxShadow: 24,
            }}
          >
            <IconButton
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
              aria-label="close"
            >
              <GridCloseIcon />
            </IconButton>
            <Typography
              gutterBottom
              id="modal-modal-title"
              variant="h5"
              component="h2"
            >
              {videoTypeText} Video
            </Typography>
            <Typography
              gutterBottom
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              {control.title} / {control.name}
            </Typography>

            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                    gap: 2,
                    mt: 2,
                  }}
                >
                  <Box sx={{ flex: 1, width: { xs: "100%", sm: "auto" } }}>
                    <video ref={videoRef} width="100%" controls autoPlay>
                      <source src={url} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </Box>
                  {isAdmin && (
                    <AdminControlVideosTranscriptComponent
                      control={control}
                      video_type={video_type}
                      setOpenModal={setOpenModal}
                    />
                  )}
                  {!isAdmin && (
                    <UserControlVideosTranscriptComponent
                      control={control}
                      video_type={video_type}
                      setOpenModal={setOpenModal}
                    />
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default ControlVideosComponent;
