import React, { useEffect, useRef } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { apiClient, sessionExpiry } from "../../api/apiClient";
import { User } from "../../types/users";

export const fetchCurrentUser = async () => {
  if (!localStorage.getItem("access_token")) {
    return;
  }

  try {
    const response = await apiClient.get("/api/users/me/", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    const userData: User = response.data;

    localStorage.setItem("user_data", JSON.stringify(userData));
    return userData;
  } catch (error) {
    //
    return null;
  }
};

const FinishLogin: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const tokenExchanged = useRef(false);

  useEffect(() => {
    if (code && !tokenExchanged.current) {
      exchangeCodeForTokens(code);
      tokenExchanged.current = true;
    }
  }, [code, navigate]);

  const exchangeCodeForTokens = async (code: string) => {
    try {
      const response = await apiClient.post("/api/exchange-token/", {
        code,
      });

      const { access_token, refresh_token } = response.data;

      localStorage.setItem("access_token", access_token);
      localStorage.setItem("refresh_token", refresh_token);
      localStorage.setItem("session_expiry", sessionExpiry());
      await fetchCurrentUser();
      navigate("/");
    } catch (error) {
      console.error("Error during token exchange:", error);
      navigate("/login");
    }
  };

  fetchCurrentUser();

  return <div>Logging in...</div>;
};

export default FinishLogin;
