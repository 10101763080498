import React from "react";
import axios from "axios";

import { apiClient } from "../../api/apiClient";
import { useQuery } from "@tanstack/react-query";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import {
  type UserQueryResult,
  type User,
  UserTableUpdateArg,
} from "../../types/users";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { fetchCurrentUser } from "../../pages/finish-login";

export function useCreateUser(table?: UserTableUpdateArg) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user: User) => {
      try {
        const response = await apiClient.post("/api/users/crud", user);

        if (table) {
          table.table.setCreatingRow(null);
        }
        toast.success(
          `User ${user.first_name} ${user.last_name} created successfully`
        );
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
          throw error;
        } else {
          toast.error(`Error creating user: ${error}`);
        }
      }
    },
    onSuccess: (newUser: User) => {
      queryClient.setQueryData<User[]>(["users"], (prevUsers = []) => [
        ...prevUsers,
        newUser,
      ]);
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["users"] }),
  });
}

export function useDeleteUser() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (userId: string) => {
      try {
        await apiClient.post(`/api/users/delete-user/`, { userId });
        toast.success("User successfully deleted");
      } catch (error) {
        toast.error("Failed to delete the user");
      }
    },
    onMutate: (userId: string) => {
      queryClient.setQueryData(["users"], (prevUsers: any) =>
        prevUsers?.filter((user: User) => user.id !== userId)
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["users"] }),
  });
}

export function useGetUser({ id }: { id: string }) {
  return useQuery<UserQueryResult>({
    queryKey: ["users", id],
    queryFn: async () => {
      try {
        const url = `/api/users?id=${id}`;
        const response = await apiClient.get(url);

        return {
          users: response.data.results as User[],
          rowCount: response.data.count,
        };
      } catch (error) {
        //
        return { users: [], rowCount: 0 };
      }
    },
    refetchOnWindowFocus: true,
  });
}

export function useGetUsersAdmin({
  pagination,
  columnFilters,
  sorting,
  globalFilter,
  exportCsv,
}: {
  pagination: MRT_PaginationState;
  columnFilters: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
  globalFilter: string | undefined;
  exportCsv?: boolean;
}) {
  return useQuery<UserQueryResult[]>({
    queryKey: [
      "users",
      pagination,
      columnFilters,
      sorting,
      globalFilter,
      exportCsv,
    ],
    queryFn: async () => {
      try {
        const url = `/api/users`;
        const params = new URLSearchParams({
          page: (pagination.pageIndex + 1).toString(),
          limit: pagination.pageSize.toString(),
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
          intent: "all",
          globalFilter: globalFilter ?? "",
        });

        const response = await apiClient.get(url, { params });

        return [
          {
            users: response.data.results as User[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ users: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

export function useSearchUsers({
  q,
  organization,
  intent,
}: {
  q: string;
  organization: string;
  intent?: string;
}) {
  return useQuery<UserQueryResult[]>({
    queryKey: ["users", q, intent],
    queryFn: async () => {
      try {
        let url = `/api/users?search=${encodeURIComponent(q)}`;
        if (intent) {
          url += `&intent=${encodeURIComponent(intent)}`;
        }
        if (organization) {
          url += `&organization=${encodeURIComponent(organization)}`;
        }

        const response = await apiClient.get(url);

        return [
          {
            users: response.data.results as User[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ users: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

export function useUpdateUser(table?: UserTableUpdateArg) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (user: User) => {
      try {
        await apiClient.post(`/api/users/crud/`, user);

        if (table) {
          table.table.setEditingRow(null);
        }
        toast.success(
          `User ${user.first_name} ${user.last_name} updated successfully`
        );
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
          throw error;
        } else {
          toast.error(`Error updating user ${error}`);
        }
      }
    },

    onMutate: (newUserInfo: User) => {
      queryClient.setQueryData(["users"], (prevUsers: any) =>
        prevUsers?.map((prevUser: User) =>
          prevUser.id === newUserInfo.id ? newUserInfo : prevUser
        )
      );
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["users"] }),
  });
}

export const useExportUsers = (columnFilters: any, sorting: any) => {
  const exportUsers = async () => {
    try {
      const response = await apiClient.get("/api/users/export/", {
        params: {
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;

      const now = new Date();
      const dateString = now.toISOString().split("T")[0];
      const timestamp = now.getTime();
      const filename = `Users-${dateString}-${timestamp}.csv`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();

      toast.success(`${filename} downloaded successfully`);
    } catch (error) {
      toast.error(`Failed to download file : ${error}`);
    }
  };

  return exportUsers;
};

export const useResetUserPassword = (id: string) => {
  const resetPassword = async () => {
    try {
      await apiClient.post("/api/users/reset-password/", { id });
      toast.success("Password reset successfully");
    } catch (error) {
      toast.error("Failed to reset password");
    }
  };

  return resetPassword;
};

export const useAcceptTOS = (id: string) => {
  const acceptTOS = async () => {
    try {
      await apiClient.post("/api/users/accept-tos/", { id });
    } catch (error) {
      //
    } finally {
      fetchCurrentUser();
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return acceptTOS;
};

export const useDeleteUsers = (
  columnFilters: any,
  sorting: any,
  rowCount: number
) => {
  const deleteUsers = async () => {
    try {
      await apiClient.get("/api/users/delete/", {
        params: {
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
        },
      });

      toast.success(`Deleted ${rowCount} rows successfully`);
    } catch (error) {
      toast.error("Failed to delete rows");
    } finally {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return deleteUsers;
};
