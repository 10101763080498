import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";

import { Control } from "../../../types/controls";
import { Link } from "react-router-dom";
import { Box, Button } from "@mui/material";
import {
  useGeneralPatch,
  useExportRelatedObjects,
  useGetObjects,
} from "../../../hooks/common";
import { toast } from "react-toastify";
import CSVPatch from "../../controlsets/edit/import";
import ControlVideosComponent from "../../controls/edit/videos";
import { getUserInfo } from "../../../helpers/user";

interface HasControls {
  id?: string;
  predecessor_controls?: Control[];
}

interface PredecessorControlTableProps {
  parentObject: Partial<Control>;
  loadPartial: boolean;
  parentObjectUrlKey: string;
  relatedChildFieldName: string;
}

const PredecessorControlTable = ({
  parentObject,
  loadPartial,
  parentObjectUrlKey,
  relatedChildFieldName,
}: PredecessorControlTableProps) => {
  const user = getUserInfo();
  const isAdmin = user.role === "admin";

  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [editedParentObject, setEditedParentObject] =
    useState<HasControls>(parentObject);

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const [controlIdToAdd, setControlIdToAdd] = useState<string | null>(null);
  const [controlIdToRemove, setControlIdToRemove] = useState<string | null>(
    null
  );
  const [submitFlag, setSubmitFlag] = useState(false);
  const prevRowSelectionRef = useRef<Record<string, boolean>>({});

  const addPatchObject = useGeneralPatch("add");
  const removePatchObject = useGeneralPatch("remove");

  useEffect(() => {
    const currentSelectedIds = Object.keys(rowSelection);
    const previousSelectedIds = Object.keys(prevRowSelectionRef.current);
    const newlySelectedIds = currentSelectedIds.filter(
      (id) => rowSelection[id] && !prevRowSelectionRef.current[id]
    );
    const newlyDeselectedIds = previousSelectedIds.filter(
      (id) => !rowSelection[id] && prevRowSelectionRef.current[id]
    );

    if (newlySelectedIds.length > 0 && !initialLoad) {
      const controlId = newlySelectedIds[0];

      setControlIdToAdd(controlId);
      setSubmitFlag(true);
    } else if (newlyDeselectedIds.length > 0 && !initialLoad) {
      const controlId = newlyDeselectedIds[0];
      setControlIdToRemove(controlId);
      setSubmitFlag(true);
    }

    prevRowSelectionRef.current = { ...rowSelection };
  }, [rowSelection, initialLoad]);

  useEffect(() => {
    if (submitFlag) {
      const controlIdadd = controlIdToAdd;
      const controlIdremove = controlIdToRemove;

      const parentObjectId = editedParentObject.id;

      if (parentObjectId) {
        if (controlIdadd) {
          addPatchObject.mutate(
            {
              parentObjectId: parentObjectId,
              parentObjectType: parentObjectUrlKey,
              childObjectType: relatedChildFieldName,
              childObjectId: controlIdadd,
            },
            {
              onSuccess: () => {
                setSubmitFlag(false);
                setControlIdToAdd(null);
                setControlIdToRemove(null);
              },
            }
          );
        }
        if (controlIdremove) {
          removePatchObject.mutate(
            {
              parentObjectId: parentObjectId,
              parentObjectType: parentObjectUrlKey,
              childObjectType: relatedChildFieldName,
              childObjectId: controlIdremove,
            },

            {
              onSuccess: () => {
                setSubmitFlag(false);
                setControlIdToRemove(null);
                setControlIdToAdd(null);
              },
            }
          );
        }
      }
    }
  }, [submitFlag, controlIdToRemove, controlIdToAdd, rowSelection]);

  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [columnFilters]);

  useEffect(() => {
    const rowSelection: Record<string, boolean> = {};

    parentObject.predecessor_controls?.forEach((control: Control) => {
      const id = control.id;
      rowSelection[id] = true;
    });

    setRowSelection(rowSelection);
    setTimeout(() => {
      setInitialLoad(false);
    }, 1000);
  }, []);

  const tableName = "predecessor_controls_table";
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const tableState = JSON.parse(localStorage.getItem("tableState") || "{}");
    return (
      tableState[tableName]?.columnVisibility || {
        id: false,
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tableState") || "{}"),
        [tableName]: {
          columnVisibility,
        },
      })
    );
  }, [columnVisibility, tableName]);

  const initialRowSelectionAndPinning = useMemo(() => {
    const rowSelection: Record<string, boolean> = {};
    const topPinning: string[] = [];

    parentObject.predecessor_controls?.forEach((control: Control) => {
      const id = control.id;
      rowSelection[id] = true;
      topPinning.push(id);
    });

    return { rowSelection };
  }, [parentObject]);

  useEffect(() => {
    setEditedParentObject(parentObject);
  }),
    [parentObject];

  const relatedParentField = parentObjectUrlKey;
  const relatedChildField = relatedChildFieldName;
  const relatedParentId = parentObject.id;
  const [showRelatedOnly, setShowRelatedOnly] = useState(loadPartial);

  useEffect(() => {
    pagination.pageIndex = 0;
  }, [showRelatedOnly]);

  const getParams = {
    pagination,
    columnFilters,
    sorting,
    globalFilter,
    relatedParentField,
    relatedChildField,
    relatedParentId,
    showRelatedOnly,
  };

  const {
    data: controlQueryResults = [],
    isError: isLoadingControlsError,
    isLoading: isLoadingControls,
    isFetching: isFetchingControls,
  } = useGetObjects<
    Control,
    [
      "controls",
      {
        pagination: MRT_PaginationState;
        columnFilters: MRT_ColumnFiltersState;
        sorting: MRT_SortingState;
        globalFilter: string | undefined;
        relatedParentField: string | undefined;
        relatedChildField: string | undefined;
        relatedParentId: string | undefined;
        showRelatedOnly: boolean;
      }
    ]
  >(
    [
      "controls",
      {
        pagination,
        columnFilters,
        sorting,
        globalFilter,
        relatedParentField,
        relatedChildField,
        relatedParentId,
        showRelatedOnly,
      },
    ],
    "controls",
    getParams
  );

  const fetchedControls = controlQueryResults[0]?.data || [];

  useEffect(() => {
    const count = controlQueryResults[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [controlQueryResults]);

  const toggleShowRelatedOnly = () => {
    setShowRelatedOnly(!showRelatedOnly);
  };

  const exportRelatedObjects = useExportRelatedObjects(
    relatedParentField,
    relatedParentId,
    "predecessor_controls"
  );

  const handleExportRelatedObjects = async () => {
    try {
      await exportRelatedObjects();
    } catch (error) {
      console.error(error);
      toast.error("Failed to export");
    } finally {
    }
  };

  const columns = useMemo<MRT_ColumnDef<Control>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
        enableSorting: false,
      },

      {
        accessorKey: "name",
        header: "Name",
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <>
              <Link to={`/control/${row.original.id}`}>
                {row.original.name}
              </Link>
            </>
          );
        },
      },
      {
        accessorKey: "title",
        header: "Title",
        enableSorting: false,
      },
      {
        accessorKey: "description",
        header: "Description",
        enableSorting: false,
        Cell: ({ row }) => (
          <div style={{ textAlign: "left", maxHeight: "110px" }}>
            <ReactMarkdown>{row.original.description}</ReactMarkdown>
          </div>
        ),
      },

      {
        accessorKey: "preventive",
        header: "Preventive",
        enableSorting: false,
        accessorFn: (row) => (row.preventive ? "Yes" : "No"),
      },

      {
        accessorKey: "administrative",
        header: "Administrative",
        enableSorting: false,
        accessorFn: (row) => (row.administrative ? "Yes" : "No"),
      },

      {
        accessorKey: "detective",
        header: "Detective",
        enableSorting: false,
        accessorFn: (row) => (row.detective ? "Yes" : "No"),
      },
      {
        accessorKey: "control_description_video_exists",
        header: "Control Description Video",
        enableSorting: false,
        enableColumnFilter: false,

        Cell: ({ row }) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "100%",
            }}
          >
            <ControlVideosComponent
              video_type="control_description"
              control={row.original}
              tableMode={true}
            />
          </Box>
        ),
      },

      {
        accessorKey: "date_created",
        enableColumnFilter: false,
        enableSorting: false,
        header: "Created On",
        Cell: ({ row }) => {
          if (row.original.date_created) {
            const date = new Date(row.original.date_created);
            return new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }).format(date);
          }
        },
        muiEditTextFieldProps: {
          type: "Date",
          enableEditing: false,
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    enableGlobalFilter: false,
    data: fetchedControls,
    positionToolbarAlertBanner: "none",
    enableRowSelection: true,

    initialState: {
      rowSelection: initialRowSelectionAndPinning.rowSelection,
      showColumnFilters: false,
      density: "compact",
      columnVisibility: columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableSelectAll: false,
    enableColumnResizing: true,
    getRowId: (row) => row.id,
    rowNumberDisplayMode: "original",
    muiToolbarAlertBannerProps: isLoadingControlsError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    muiTableContainerProps: {
      sx: {
        minHeight: "100px",
      },
    },

    state: {
      columnFilters,
      globalFilter,
      rowSelection,
      pagination,
      sorting,
      columnVisibility: columnVisibility,
      isLoading: isLoadingControls,
      showAlertBanner: isLoadingControlsError,
      showProgressBars: isFetchingControls,
    },
  });

  if (initialLoad) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loader"></div>
      </div>
    );
  } else {
    return (
      <div style={{ opacity: 1, transition: "opacity 3s" }}>
        <div className="fullPageTable">
          <MaterialReactTable table={table} />
          <Button
            sx={{ margin: 2, marginLeft: 0 }}
            variant="contained"
            onClick={toggleShowRelatedOnly}
          >
            {showRelatedOnly ? "Show All" : "Show Related Only"}
          </Button>

          {isAdmin && (
            <>
              <Button
                sx={{ margin: 2, marginLeft: 0 }}
                variant="contained"
                onClick={handleExportRelatedObjects}
              >
                Export Related
              </Button>

              <Box
                sx={{
                  marginTop: 2,
                  gap: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    padding: 2,
                    border: 2,
                    borderColor: "divider",
                  }}
                >
                  <details>
                    <summary>Add Predecessor Controls in Bulk</summary>
                    <CSVPatch
                      mode="controls"
                      action="add"
                      parentObjectType={parentObjectUrlKey}
                      parentObjectId={editedParentObject.id}
                      childObjectType={relatedChildFieldName}
                    />
                  </details>
                </Box>

                <Box
                  sx={{
                    padding: 2,
                    border: 2,
                    borderColor: "divider",
                  }}
                >
                  <details>
                    <summary>Remove Predecessor Controls in Bulk</summary>
                    <CSVPatch
                      mode="controls"
                      action="remove"
                      parentObjectType={parentObjectUrlKey}
                      parentObjectId={editedParentObject.id}
                      childObjectType={relatedChildFieldName}
                    />
                  </details>
                </Box>
              </Box>
            </>
          )}
        </div>
      </div>
    );
  }
};

export default PredecessorControlTable;
