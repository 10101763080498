import React, { useState, useEffect, FunctionComponent } from "react";
import { Link, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import {
  Dialog,
  DialogTitle,
  TextField,
  Button,
  ListItem,
  ListItemText,
  Box,
  Typography,
  Tabs,
  Tab,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import PaginatedModalComponent from "../../edit/PaginatedModalComponent";

import { Engagement } from "../../../types/engagements";

import { User } from "../../../types/users";
import { useGetEngagement } from "../../../hooks/engagements";

import CloseIcon from "@mui/icons-material/Close";
import Layout from "../../../layout";
import EvidenceRequestTable from "../../tables/evidencerequests";

import { TabPanel, a11yProps } from "../../../helpers/functions";
import SecondaryControlTable from "../../tables/controls/secondary";
import { formatDueDate } from "./admin";
import Accordion from "@mui/material/Accordion";
import { GridArrowDownwardIcon } from "@mui/x-data-grid";
export const defaultEngagement: Partial<Engagement> = {
  name: "",
  description: "",
  due_date: null,
  organization: null,
  ticket_url: "",
  date_created: new Date(),
  last_updated: new Date(),
  evidence_requests: [],
  users: [],
  scopes: [],
  prev_engagements: [],
  peer_engagements: [],
};

interface EditEngagementModalProps {
  engagement?: Partial<Engagement> | null;
  isOpen?: boolean;
  mode?: "modal" | "page";
  onClose?: () => void;
}

const UserEditEngagementModal: FunctionComponent<EditEngagementModalProps> = ({
  engagement = defaultEngagement,
  isOpen = true,
  mode = "modal",
  onClose = () => {},
}) => {
  const [editedEngagement, setEditedEngagement] = useState<
    Engagement | Partial<Engagement>
  >(engagement || defaultEngagement);
  const isPageMode = mode === "page";

  const { id } = useParams<{ id: string }>();
  const [loadingError, setLoadingError] = useState(false);
  const [
    evidenceRequestsTablePartialMode,
    setEvidenceRequestsTablePartialMode,
  ] = useState(true);

  if (id) {
    const { data, isLoading: isFetching } = useGetEngagement({ id });
    useEffect(() => {
      if (mode === "page" && id && data && !isFetching) {
        if (data[0].engagements.length === 0) {
          setLoadingError(true);
        } else {
          setEditedEngagement(data[0].engagements[0]);
        }
      }
    }, [id, mode, data, isFetching]);
  }

  useEffect(() => {
    if (editedEngagement) {
      localStorage.setItem(
        "last_viewed_engagement",
        JSON.stringify(editedEngagement)
      );
    }
  }, [editedEngagement]);

  const renderPeerEngagement = (engagement: Engagement) => (
    <ListItem className="modalEditListItem" key={engagement.id}>
      <ListItemText
        primary={
          <Link to={`/engagement/${engagement.id}`}>{engagement.name}</Link>
        }
      />
    </ListItem>
  );

  const renderPerson = (person: User) => (
    <ListItem className="modalEditListItem" key={person.id}>
      <ListItemText primary={person.name} />
    </ListItem>
  );

  const renderPrevEngagement = (engagement: Engagement) => (
    <ListItem className="modalEditListItem" key={engagement.id}>
      <ListItemText
        primary={
          <Link to={`/engagement/${engagement.id}`}>{engagement.name}</Link>
        }
      />
    </ListItem>
  );

  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabId: number) => {
    setActiveTab(tabId);
  };

  const pageModeContent = (
    <>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={(event, newValue) => handleTabClick(newValue)}
            aria-label="Switch tabs"
          >
            <Tab label="Evidence Requests" {...a11yProps(0)} />
            <Tab label="Controls" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <TabPanel value={activeTab} index={0}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" component="div">
              Evidence Requests
            </Typography>

            {editedEngagement.id && (
              <EvidenceRequestTable
                parentObjectUrlKey="engagements"
                loadPartial={true}
                parentObject={editedEngagement}
                relatedChildFieldName="evidence_requests"
              />
            )}
          </Box>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Box sx={{ padding: 2 }}>
            <Typography variant="h6" component="div">
              Controls
            </Typography>

            {editedEngagement.id && (
              <SecondaryControlTable
                parentObjectUrlKey="engagements"
                throughObjectKey="controlsets"
                parentObject={editedEngagement}
                relatedChildFieldName="controls"
              />
            )}
          </Box>
        </TabPanel>
      </Box>
    </>
  );

  const content = (
    <>
      <Accordion>
        <AccordionSummary
          expandIcon={<GridArrowDownwardIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <Typography sx={{ p: 2 }}> {editedEngagement.name} </Typography>
        </AccordionSummary>

        <AccordionDetails>
          {editedEngagement.description && (
            <Box
              sx={{
                border: 1,
                borderColor: "divider",
                padding: 2,
              }}
            >
              <Typography
                component="div"
                variant="body1"
                sx={{
                  lineHeight: "1em",
                  overflow: "wrap",
                }}
              >
                <ReactMarkdown>{editedEngagement.description}</ReactMarkdown>
              </Typography>
            </Box>
          )}

          {editedEngagement.due_date && (
            <Box
              sx={{
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              <TextField
                label="Due Date"
                type="date"
                variant="outlined"
                fullWidth
                value={formatDueDate(editedEngagement.due_date)}
                disabled={true}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="normal"
              />
            </Box>
          )}

          {editedEngagement.users && editedEngagement.users.length > 0 && (
            <Box
              sx={{
                border: 1,
                borderColor: "divider",
                padding: 2,
                marginTop: 2,
                marginBottom: 2,
              }}
            >
              <Typography variant="h6">Users</Typography>
              <PaginatedModalComponent
                items={editedEngagement.users.map((user) => user) ?? []}
                renderItem={renderPerson}
              />
            </Box>
          )}

          {editedEngagement.prev_engagements &&
            editedEngagement.prev_engagements.length > 0 && (
              <Box
                sx={{
                  border: 1,
                  borderColor: "divider",
                  padding: 2,
                  marginTop: 2,
                  marginBottom: 2,
                }}
              >
                <Typography variant="h6">Previous Engagements</Typography>

                <PaginatedModalComponent
                  items={
                    editedEngagement.prev_engagements?.map(
                      (prev_engagement) => prev_engagement
                    ) ?? []
                  }
                  renderItem={renderPrevEngagement}
                />
              </Box>
            )}

          {editedEngagement.peer_engagements &&
            editedEngagement.peer_engagements.length > 0 && (
              <Box
                sx={{
                  border: 1,
                  borderColor: "divider",
                  padding: 2,
                  marginTop: 2,
                  marginBottom: 2,
                }}
              >
                <Typography variant="h6">Peer Engagements</Typography>

                <PaginatedModalComponent
                  items={
                    editedEngagement.peer_engagements?.map(
                      (peer_engagement) => peer_engagement
                    ) ?? []
                  }
                  renderItem={renderPeerEngagement}
                />
              </Box>
            )}
        </AccordionDetails>
      </Accordion>
    </>
  );

  if (loadingError) {
    return (
      <Layout pageTitle="" parentUrl="" parentPageName="">
        <Typography variant="h5" sx={{ color: "black", padding: 3 }}>
          Engagement not found
        </Typography>
        ;
      </Layout>
    );
  } else
    return isPageMode ? (
      <Layout
        pageTitle="Engagement"
        parentUrl="/engagements"
        parentPageName="Engagements"
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "100%",
                color: "black",
                border: 2,
                borderColor: "divider",
                margin: 2,
                borderRadius: 2,
              }}
            >
              {content}
            </Box>
            <Box sx={{ p: 2 }}>
              {editedEngagement.completed && (
                <Button variant="outlined" color="success">
                  Engagement completed
                </Button>
              )}
              {!editedEngagement.completed && (
                <Button variant="outlined" color="primary">
                  Engagement in progress
                </Button>
              )}
            </Box>
            <Box
              sx={{
                width: "100%",
                maxWidth: "100%",
                color: "black",
                border: 2,
                borderColor: "divider",
                margin: 2,
                borderRadius: 3,
              }}
            >
              {pageModeContent}
            </Box>
          </Box>
        </Box>
      </Layout>
    ) : (
      <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Typography fontSize={25}>{editedEngagement.name}</Typography>
          <CloseIcon
            onClick={onClose}
            style={{
              position: "absolute",
              right: 10,
              top: 10,
              fontSize: 30,
            }}
          />
        </DialogTitle>

        {content}
      </Dialog>
    );
};

export default UserEditEngagementModal;
