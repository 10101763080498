import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { apiClient } from "../../api/apiClient";
import axios from "axios";

import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";

type FetchParams = {
  pagination?: MRT_PaginationState | undefined;
  columnFilters?: MRT_ColumnFiltersState | undefined;
  sorting?: MRT_SortingState | undefined;
  globalFilter?: string | undefined;
  exportCsv?: boolean | undefined;
  relatedParentField?: string | undefined;
  relatedChildField?: string | undefined;
  relatedParentId?: string | undefined;
  throughObjectKey?: string | undefined;
  showRelatedOnly?: boolean;
  showAttentionRequired?: boolean;
  showStats?: boolean;
};

type QueryResultBase<T> = {
  data: T[];
  rowCount: number;
};

export function useGetObjects<T, TQueryKey extends readonly any[]>(
  queryKey: TQueryKey,
  endpoint: string,
  params?: FetchParams,
  options?: Omit<
    UseQueryOptions<
      QueryResultBase<T>[],
      unknown,
      QueryResultBase<T>[],
      TQueryKey
    >,
    "queryKey" | "queryFn"
  >
) {
  return useQuery<
    QueryResultBase<T>[],
    unknown,
    QueryResultBase<T>[],
    TQueryKey
  >({
    ...options,
    queryKey,
    queryFn: async () => {
      try {
        const queryParams = new URLSearchParams();
        if (params?.pagination) {
          queryParams.append(
            "page",
            (params.pagination.pageIndex + 1).toString()
          );
          queryParams.append("limit", params.pagination.pageSize.toString());
        }
        if (params?.globalFilter) {
          queryParams.append("globalFilter", params.globalFilter);
        }
        if (params?.columnFilters) {
          queryParams.append("filters", JSON.stringify(params.columnFilters));
        }
        if (params?.sorting) {
          queryParams.append("sorting", JSON.stringify(params.sorting));
        }

        if (params?.relatedParentField) {
          queryParams.append(
            "relatedParentField",
            params?.relatedParentField || ""
          );
        }
        if (params?.relatedParentId) {
          queryParams.append("relatedParentId", params?.relatedParentId || "");
        }

        if (params?.relatedChildField) {
          queryParams.append(
            "relatedChildField",
            params?.relatedChildField || ""
          );
        }

        if (params?.showRelatedOnly) {
          queryParams.append(
            "showRelatedOnly",
            params?.showRelatedOnly ? "true" : "false"
          );
        }

        if (params?.showAttentionRequired) {
          queryParams.append(
            "showAttentionRequired",
            params?.showAttentionRequired ? "true" : "false"
          );
        }

        if (params?.showStats) {
          queryParams.append("showStats", params?.showStats ? "true" : "false");
        }

        if (params?.throughObjectKey) {
          queryParams.append("throughObjectKey", params?.throughObjectKey);
        }

        const { data } = await apiClient.get(`/api/${endpoint}`, {
          params: queryParams,
        });
        return [
          {
            data: data.results as T[],
            rowCount: data.count,
          },
        ];
      } catch (error) {
        //
        throw new Error("Fetching data failed");
      }
    },
  });
}

export const useExportRelatedObjects = (
  parentField: string,
  parentId: any,
  childField: string
) => {
  const exportObjs = async () => {
    try {
      const response = await apiClient.get(
        `/api/${parentField}/export-related/`,
        {
          params: {
            childField: childField,
            parentId: parentId,
          },
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;

      const now = new Date();
      const dateString = now.toISOString().split("T")[0];
      const timestamp = now.getTime();
      const filename = `${parentField}-${childField}-${dateString}-${timestamp}.csv`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();

      toast.success(`${filename} downloaded successfully`);
    } catch (error) {
      toast.error(`Failed to download file : ${error}`);
    }
  };

  return exportObjs;
};

type PatchGeneralInput = {
  parentObjectId: string;
  parentObjectType: string;
  childObjectId: string;
  childObjectType: string;
};

export function useGeneralPatch(action: "add" | "remove") {
  return useMutation({
    mutationFn: async ({
      parentObjectId,
      parentObjectType,
      childObjectId,
      childObjectType,
    }: PatchGeneralInput) => {
      const patchData: any = {};

      const endpoint = `/api/${parentObjectType}/${action}-related/`;

      try {
        const response = await apiClient.patch(endpoint, patchData, {
          params: {
            ["parentId"]: parentObjectId,
            ["childField"]: childObjectType,
            ["childId"]: childObjectId,
          },
        });
        toast.success(response.data);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
        } else {
          toast.error(`Error updating ${parentObjectType}: ${error}`);
        }
      }
    },
  });
}

type PatchMultipleGeneralInput = {
  parentObjectId: string;
  parentObjectType: string;
  childObjects: string[];
  childObjectType: string;
};

export function useMultiplePatch(action: "add" | "remove") {
  return useMutation({
    mutationFn: async ({
      parentObjectId,
      parentObjectType,
      childObjects,
      childObjectType,
    }: PatchMultipleGeneralInput) => {
      const endpoint = `/api/${parentObjectType}/${action}-related-multiple/`;
      const patchData = {
        childObjects,
      };

      try {
        const response = await apiClient.post(endpoint, patchData, {
          params: {
            parentId: parentObjectId,
            childField: childObjectType,
          },
        });
        toast.success(response.data);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          toast.error(
            `Error updating ${parentObjectType}: ${error.response.data}`
          );
        }
      }
    },
  });
}
