import { MRT_TableInstance } from "material-react-table";
import { Control } from "../controls";
import { Engagement } from "../engagements";
import { User } from "../users";
import { Organization } from "../organizations";

export interface EvidenceRequestTableUpdateArg {
  table: MRT_TableInstance<EvidenceRequest>;
}

export type EvidenceRequest = {
  id: string;
  title: string;
  description: string;
  due_date: Date | null;
  notes: string;
  status: string;
  message_history: string;
  files: Array<string>;
  controls: Control[];
  users: Array<User>;
  organization: Organization | null;
  verifiers: Array<User>;
  engagements: Engagement[];
  date_created?: Date;
};

export const defaultEvidenceRequest: Partial<EvidenceRequest> = {
  title: "",
  description: "",
  due_date: null,
  notes: "",
  status: "",
  controls: [],
  users: [],
  verifiers: [],
  organization: null,
  engagements: [],
  date_created: new Date(),
};

export type EvidenceRequestQueryResult = {
  evidencerequests: EvidenceRequest[];
  rowCount: number;
};

export type EvidenceRequestApiResponse = {
  count: number;
  next: string;
  previous: string;
  results: Array<EvidenceRequest>;
};

export function isEvidenceRequest(object: any): object is EvidenceRequest {
  return "evidenceRequestSpecificProperty" in object;
}
