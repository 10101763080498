import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Box, Button, Typography, TextField } from "@mui/material";
import { Control } from "../../../types/controls";
import ReactMarkdown from "react-markdown";
import {
  useDeleteControlVideo,
  useGetControl,
  useUpdateControl,
} from "../../../hooks/controls";
import { GridDeleteIcon } from "@mui/x-data-grid";
import { toast } from "react-toastify";

interface ControlVideosTranscriptComponentProps {
  control: Control | Partial<Control>;
  video_type: "control_description" | "evidence_description";
  setOpenModal: (open: boolean) => void;
}

export const AdminControlVideosTranscriptComponent = ({
  control,
  video_type,
  setOpenModal,
}: ControlVideosTranscriptComponentProps) => {
  const [descriptionMode, setDescriptionMode] = useState("");

  useEffect(() => {
    if (video_type === "control_description") {
      if (control.control_description_video_transcript?.length === 0) {
        setDescriptionMode("edit");
        return;
      }
    }
    if (video_type === "evidence_description") {
      if (control.evidence_description_video_transcript?.length === 0) {
        setDescriptionMode("edit");
        return;
      }
    }

    setDescriptionMode("view");
  }, []);

  const [editedControl, setEditedControl] = useState<
    Control | Partial<Control>
  >(control);

  const updateControl = useUpdateControl();

  const deleteVideo = useDeleteControlVideo();

  const handleDeleteClick = async (controlId: string, videoType: string) => {
    const success = await deleteVideo(controlId, videoType);
    if (success) {
      toast.success("Video deleted successfully");
      setOpenModal(false);
    } else {
      //
    }
  };

  const handleSubmit = async () => {
    try {
      if ("id" in editedControl) {
        await updateControl.mutateAsync(editedControl as Control);
      }
    } catch (error) {}
  };

  const toggleMode = () => {
    setDescriptionMode(descriptionMode === "view" ? "edit" : "view");
  };

  return (
    <Box
      sx={{
        width: { xs: "100wh", lg: "40vh" },
        display: descriptionMode === "" ? "none" : "initial",
        overflow: "scroll",
        maxHeight: "50vh",
        border: 1,
        borderColor: "divider",
        padding: 2,
      }}
    >
      <Button
        onClick={toggleMode}
        variant="outlined"
        startIcon={
          descriptionMode === "view" ? (
            <EditIcon sx={{ mb: 0.4 }} />
          ) : (
            <VisibilityIcon sx={{ mb: 0.4 }} />
          )
        }
        sx={{
          margin: 2,
          marginLeft: 0,
        }}
      >
        {descriptionMode === "view" ? "Edit" : "Preview"}
      </Button>

      {descriptionMode === "edit" && (
        <Button
          onClick={() =>
            handleDeleteClick(editedControl.id as string, video_type)
          }
          variant="contained"
          color="error"
          startIcon={<GridDeleteIcon sx={{ mb: 0.4 }} />}
          sx={{
            margin: 2,
            marginLeft: 0,
          }}
        >
          Delete
        </Button>
      )}
      {video_type === "control_description" && (
        <>
          {descriptionMode === "edit" ? (
            <>
              <Button variant="contained" onClick={handleSubmit}>
                Save
              </Button>

              <TextField
                sx={{ width: "100%" }}
                multiline
                variant="outlined"
                value={editedControl.control_description_video_transcript}
                onChange={(e) =>
                  setEditedControl({
                    ...editedControl,
                    control_description_video_transcript: e.target.value,
                  })
                }
                margin="normal"
              />
            </>
          ) : (
            <Typography
              component="div"
              variant="body1"
              sx={{
                overflow: "wrap",
                border: 1,
                borderColor: "divider",
                padding: 2,
                borderRadius: 2,
              }}
            >
              <ReactMarkdown>
                {editedControl.control_description_video_transcript}
              </ReactMarkdown>
            </Typography>
          )}
        </>
      )}

      {video_type === "evidence_description" && (
        <>
          {descriptionMode === "edit" ? (
            <>
              <Button variant="contained" onClick={handleSubmit}>
                Save
              </Button>

              <TextField
                sx={{ width: "100%" }}
                multiline
                variant="outlined"
                value={editedControl.evidence_description_video_transcript}
                onChange={(e) =>
                  setEditedControl({
                    ...editedControl,
                    evidence_description_video_transcript: e.target.value,
                  })
                }
                margin="normal"
              />
            </>
          ) : (
            <Typography
              component="div"
              variant="body1"
              sx={{
                overflow: "wrap",
                border: 1,
                borderColor: "divider",
                padding: 2,
                borderRadius: 2,
              }}
            >
              <ReactMarkdown>
                {editedControl.evidence_description_video_transcript}
              </ReactMarkdown>
            </Typography>
          )}
        </>
      )}
    </Box>
  );
};
