import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { isUserAllowed } from "../../helpers/user";

interface RequireAuthProps {
  children: JSX.Element;
}

const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  const isAuthenticated = checkAuthentication();

  return isAuthenticated ? children : <Navigate to="/login" />;
};

export const checkAuthentication = (): boolean => {
  const sessionExpiryString = localStorage.getItem("session_expiry");
  if (!sessionExpiryString) return false;

  const accessToken = localStorage.getItem("access_token");
  if (!accessToken) return false;

  const sessionExpiry = new Date(sessionExpiryString);
  const now = new Date();

  if (now > sessionExpiry) {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("session_expiry");
    localStorage.removeItem("last_viewed_engagement");
    return false;
  }

  const accessEnabled = isUserAllowed();
  if (!accessEnabled) {
    window.alert(
      "Your access to the portal has been disabled. Please contact your Organization Administrator."
    );
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("session_expiry");
    localStorage.removeItem("last_viewed_engagement");
    return false;
  }

  return true;
};
export default RequireAuth;
