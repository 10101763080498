import React from "react";
import { Navigate } from "react-router-dom";
import AdminControlPage from "./admin";
import { getUserInfo } from "../../helpers/user";
import Error from "../error";

const ControlPageRouteWrapper = () => {
  const userInfo = getUserInfo();

  if (userInfo.role === "admin" || userInfo.role === "auditor") {
    return <AdminControlPage />;
  } else if (userInfo.role === "user") {
    return <Error />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default ControlPageRouteWrapper;
