import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { apiClient } from "../../api/apiClient";
import { toast } from "react-toastify";
import {
  EvidenceFile,
  EvidenceFileApiResponse,
} from "../../types/evidencefiles";

interface S3UploadResponse {
  success: boolean;
  s3_url?: string;
  evidence_file_id?: string;
  error?: any;
  message?: string;
}

export const useS3Upload = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const uploadToS3AndMarkUploaded = async (
    evidenceRequestId: string,
    file: File,
    filename: string,
    sha256: string,
    filetype: string
  ): Promise<S3UploadResponse> => {
    setLoading(true);
    setError(null);

    try {
      const { data: s3Data, status } = await apiClient.post(
        "/api/evidencefiles/upload/",
        {
          evidence_request: evidenceRequestId,
          filename,
          sha256,
          filetype,
        }
      );

      if (status === 204) {
        setLoading(false);
        toast.success(`${filename} uploaded successfully`);
        return { success: true, message: "File uploaded successfully." };
      }

      await axios.put(s3Data.s3_url, file, {
        headers: { "Content-Type": "application/octet-stream" },
      });

      await apiClient.post("/api/evidencefiles/mark-uploaded/", {
        evidence_file: s3Data.evidence_file_id,
      });
      toast.success(`${filename} uploaded successfully.`);

      setLoading(false);
      return {
        success: true,
        s3_url: s3Data.s3_url,
        evidence_file_id: s3Data.evidence_file_id,
      };
    } catch (error) {
      setError(error);
      setLoading(false);
      return { success: false, error };
    }
  };

  return { uploadToS3AndMarkUploaded, loading, error };
};

export const useGetEvidenceFiles = (evidenceRequestId: string) => {
  const [items, setItems] = useState<EvidenceFile[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>(null);

  const fetchData = useCallback(async (url: string) => {
    setLoading(true);
    try {
      const response = await apiClient.get<EvidenceFileApiResponse>(url);
      setItems((prevItems) => [...prevItems, ...response.data.results]);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    if (evidenceRequestId) {
      const initialUrl = `/api/evidencefiles/?evidencerequest=${evidenceRequestId}&limit=500`;
      fetchData(initialUrl);
    }
  }, [evidenceRequestId, fetchData]);

  return {
    data: items,
    loading,
    error,
  };
};

export const useGetEvidenceFile = () => {
  const [url, setUrl] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [downloadError, setDownloadError] = useState(false);

  const fetchDownloadUrl = useCallback(async (evidenceFileId: string) => {
    setDownloadLoading(true);
    try {
      const response = await apiClient.get(
        `/api/evidencefiles/download/?id=${evidenceFileId}`
      );
      setUrl(response.data.url);
      setDownloadLoading(false);
      return response.data.url;
    } catch (error) {
      console.error("Error fetching file URL:", error);
      setDownloadError(error as any);
      setDownloadError(false);
      return null;
    }
  }, []);

  return { fetchDownloadUrl, url, downloadLoading, downloadError };
};

export const useExportRelatedEvidenceRequests = (
  parentField: string,
  parentId: any
) => {
  const exportRelatedEvidenceRequests = async () => {
    try {
      const response = await apiClient.get(
        "/api/evidencerequests/export-related/",
        {
          params: {
            parentField: parentField,
            parentId: parentId,
          },
          responseType: "blob",
        }
      );

      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;

      const now = new Date();
      const dateString = now.toISOString().split("T")[0];
      const timestamp = now.getTime();
      const filename = `Evidence Requests-${parentField}-${dateString}-${timestamp}.csv`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();

      toast.success(`${filename} downloaded successfully`);
    } catch (error) {
      toast.error(`Failed to download file : ${error}`);
    }
  };

  return exportRelatedEvidenceRequests;
};
