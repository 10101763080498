import React, { useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { Modal, TextField, Typography } from "@mui/material";
import Layout from "../../layout";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useCreateEngagement,
  useUpdateEngagement,
  useDeleteEngagement,
  useExportEngagements,
} from "../../hooks/engagements";

import { Engagement } from "../../types/engagements";
import { GridCloseIcon } from "@mui/x-data-grid";
import AdminEditEngagementModal, {
  defaultEngagement,
} from "../../components/engagements/edit/admin";
import { Link } from "react-router-dom";
import { useGetObjects } from "../../hooks/common";
import { toast } from "react-toastify";
import ImportFromCSV from "../../components/csv/importFromCSV";
import { getUserInfo } from "../../helpers/user";
import { baseURL } from "../../api/apiClient";

interface AdminEngagementPageProp {
  cardMode?: boolean;
}

const AdminEngagementPage: React.FC<AdminEngagementPageProp> = ({
  cardMode,
}) => {
  const user = getUserInfo();
  const isAdmin = user.role === "admin";
  const isAuditor = user.role === "auditor";

  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);
  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [columnFilters]);

  const { mutateAsync: deleteEngagement } = useDeleteEngagement();

  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [currentEngagement, setCurrentEngagement] = useState<Engagement | null>(
    null
  );
  const showLayout = !cardMode;

  const [isCreatingEngagement, setIsCreatingEngagement] =
    useState<boolean>(false);

  const openEditModal = (engagement: Engagement) => {
    setCurrentEngagement(engagement);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setCurrentEngagement(null);
  };

  const openCreateModal = () => {
    setIsCreatingEngagement(true);
    setEditModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreatingEngagement(false);
    setCurrentEngagement(null);
  };

  const getParams = {
    pagination,
    columnFilters,
    sorting,
    globalFilter,
  };

  const {
    data: engagementQueryResults = [],
    isError: isLoadingEngagementsError,
    isLoading: isLoadingEngagements,
    isFetching: isFetchingEngagements,
  } = useGetObjects<
    Engagement,
    [
      "engagements",
      {
        pagination: MRT_PaginationState;
        columnFilters: MRT_ColumnFiltersState;
        sorting: MRT_SortingState;
        globalFilter: string | undefined;
      }
    ]
  >(
    ["engagements", { pagination, columnFilters, sorting, globalFilter }],
    "engagements",
    getParams
  );

  const fetchedEngagements = engagementQueryResults[0]?.data || [];

  useEffect(() => {
    const count = engagementQueryResults[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [engagementQueryResults]);

  const handleCreateEngagement: MRT_TableOptions<Engagement>["onCreatingRowSave"] =
    async ({ values, table }) => {
      const newValidationErrors = validateEngagement(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await createEngagement(values);
    };

  const handleSaveEngagement: MRT_TableOptions<Engagement>["onEditingRowSave"] =
    async ({ values, table }) => {
      const newValidationErrors = validateEngagement(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await updateEngagement(values);
    };

  const openDeleteConfirmModal = (row: MRT_Row<Engagement>) => {
    if (window.confirm("Are you sure you want to delete this Engagement?")) {
      deleteEngagement(row.original.id);
    }
  };

  function validateEngagement(engagement: Engagement) {
    const validateRequired = (value: string) => !!value.trim().length;

    return {
      name: !validateRequired(engagement.name) ? "Name is required" : "",
      description: !validateRequired(engagement.description)
        ? "Description is required"
        : "",
    };
  }

  const tableName = "admin_engagements_page_table";
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const tableState = JSON.parse(localStorage.getItem("tableState") || "{}");
    return (
      tableState[tableName]?.columnVisibility || {
        id: false,
        completed: showLayout,
        description: showLayout,
        organization: showLayout,
        date_created: showLayout,
        users: showLayout,
        evidence_requests: showLayout,
        scopes: showLayout,
        prev_engagements: showLayout,
        peer_engagements: showLayout,
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tableState") || "{}"),
        [tableName]: {
          columnVisibility,
        },
      })
    );
  }, [columnVisibility, tableName]);

  const columns = useMemo<MRT_ColumnDef<Engagement>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
        enableSorting: false,
      },

      {
        accessorKey: "name",
        header: "Name",
        Cell: ({ row }) => {
          return (
            <>
              <Link to={`/engagement/${row.original.id}`}>
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    color: row.original.completed ? "grey" : "inherit",
                  }}
                >
                  {row.original.name}
                </Typography>
              </Link>
            </>
          );
        },

        muiEditTextFieldProps: {
          type: "text",
          required: true,
          error: !!validationErrors?.name,
          helperText: validationErrors?.name,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              name: undefined,
            }),
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        enableSorting: false,
        size: 240,

        Cell: ({ row }) => (
          <div style={{ textAlign: "left", maxHeight: "110px" }}>
            <ReactMarkdown>{row.original.description}</ReactMarkdown>
          </div>
        ),
        muiEditTextFieldProps: {
          type: "text",
          required: true,
          error: !!validationErrors?.description,
          helperText: validationErrors?.description,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              description: undefined,
            }),
        },
      },
      {
        accessorKey: "due_date",
        header: "Due Date",
        Cell: ({ row }) => {
          if (row.original.due_date) {
            const date = new Date(row.original.due_date);
            return new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour12: true,
            }).format(date);
          }
        },
        muiEditTextFieldProps: {
          type: "Date",
          required: false,
        },
      },
      {
        accessorKey: "organization.name",
        header: "Organization",
        Cell: ({ row }) => {
          return <>{row.original.organization?.name || ""}</>;
        },
      },
      {
        accessorKey: "completed",
        header: "Completed",
        accessorFn: (row) => (row.completed ? "Yes" : "No"),
      },

      {
        accessorKey: "date_created",
        enableColumnFilter: false,
        header: "Created On",
        Cell: ({ row }) => {
          if (row.original.date_created) {
            const date = new Date(row.original.date_created);
            return new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }).format(date);
          }
        },
        muiEditTextFieldProps: {
          type: "Date",
          enableEditing: false,
        },
      },

      {
        accessorKey: "users",
        enableSorting: false,
        header: "Users",
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.users.map((user, index) => (
                  <div key={index}>{user.name}</div>
                ))}
              </div>
            </>
          );
        },
      },

      {
        accessorKey: "evidence_requests",
        enableSorting: false,
        header: "Evidence Requests",
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.evidence_requests.map(
                  (evidence_request, index) => (
                    <div key={index}>
                      <Link to={`/evidence-request/${evidence_request.id}`}>
                        {evidence_request.title}
                      </Link>
                    </div>
                  )
                )}
              </div>
            </>
          );
        },
      },

      {
        accessorKey: "scopes",
        enableSorting: false,
        header: "Scopes",
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.scopes.map((scope, index) => (
                  <div key={index}>{scope.title}</div>
                ))}
              </div>
            </>
          );
        },
      },

      {
        accessorKey: "prev_engagements",
        enableSorting: false,
        header: "Previous Engagements",
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.prev_engagements.map((prev_engagement, index) => (
                  <div key={index}>
                    <Link to={`/engagement/${prev_engagement.id}`}>
                      {prev_engagement.name}
                    </Link>
                  </div>
                ))}
              </div>
            </>
          );
        },
      },

      {
        accessorKey: "peer_engagements",
        enableSorting: false,
        header: "Peer Engagements",
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.peer_engagements.map((peer_engagement, index) => (
                  <div key={index}>
                    <Link to={`/engagement/${peer_engagement.id}`}>
                      {peer_engagement.name}
                    </Link>
                  </div>
                ))}
              </div>
            </>
          );
        },
      },
    ],
    [validationErrors]
  );

  const exportEngagements = useExportEngagements(columnFilters, sorting);

  const handleExport = async () => {
    try {
      await exportEngagements();
    } catch (error) {
      console.error(error);
      toast.error("Failed to export");
    } finally {
    }
  };

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const openImportModal = () => setIsImportModalOpen(true);
  const closeImportModal = () => setIsImportModalOpen(false);

  const table = useMaterialReactTable({
    columns,
    enableGlobalFilter: false,
    data: fetchedEngagements,
    initialState: {
      showColumnFilters: false,
      density: "compact",
      columnVisibility: columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    selectAllMode: "all",
    enableColumnResizing: true,
    getRowId: (row) => row.id,
    rowNumberDisplayMode: "original",
    muiToolbarAlertBannerProps: isLoadingEngagementsError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateEngagement,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveEngagement,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        {isAdmin && (
          <>
            <DialogTitle variant="h3">New Engagement</DialogTitle>
            <IconButton
              aria-label="close"
              onClick={() => table.setCreatingRow(null)}
              sx={{ position: "absolute", right: 8, top: 8 }}
            >
              <GridCloseIcon />
            </IconButton>
            <DialogContent
              sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              {internalEditComponents}
            </DialogContent>
            <DialogActions>
              <MRT_EditActionButtons variant="text" table={table} row={row} />
            </DialogActions>
          </>
        )}
      </>
    ),

    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        {isAdmin && (
          <>
            <DialogTitle
              variant="h3"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Edit Engagement
              <IconButton
                aria-label="close"
                onClick={() => table.setEditingRow(null)}
                sx={{ position: "absolute", right: 8, top: 8 }}
              >
                <GridCloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent
              sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
            >
              {internalEditComponents}
            </DialogContent>
            <DialogActions>
              <MRT_EditActionButtons variant="text" table={table} row={row} />
            </DialogActions>
          </>
        )}
      </>
    ),
    enableRowActions: isAdmin || isAuditor,
    enableBottomToolbar: !cardMode,
    renderRowActionMenuItems: ({ row, table }) => [
      <div key={row.id}>
        <Box>
          {user.role && ["admin", "auditor"].includes(user.role) && (
            <Tooltip title="Edit">
              <IconButton onClick={() => openEditModal(row.original)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          )}

          {isAdmin && (
            <Tooltip title="Delete">
              <IconButton
                color="error"
                onClick={() => openDeleteConfirmModal(row)}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </div>,
    ],
    renderBottomToolbarCustomActions: ({ table }) => (
      <>
        {isAdmin && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "1rem",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  openCreateModal();
                }}
              >
                New Engagement
              </Button>

              <Button variant="contained" onClick={handleExport}>
                Export {rowCount} Rows
              </Button>

              <Button variant="contained" onClick={openImportModal}>
                Import Engagements
              </Button>
              <Modal
                open={isImportModalOpen}
                onClose={closeImportModal}
                id="modal"
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "90vh",
                    width: "90vw",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: "8px",
                    overflow: "auto",
                  }}
                >
                  <Typography id="modal-title" variant="h6" component="h2">
                    CSV Import
                  </Typography>
                  <hr />
                  <Typography
                    id="modal-description"
                    sx={{ mt: 2 }}
                  ></Typography>
                  <ImportFromCSV
                    apiUrl={`${baseURL}/api/engagements/import/`}
                    importType="engagements"
                    onClose={closeImportModal}
                  />

                  <Button onClick={closeImportModal} sx={{ mt: 2 }}>
                    Close
                  </Button>
                </Box>
              </Modal>
            </Box>
          </>
        )}
      </>
    ),
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      columnVisibility: columnVisibility,
      isLoading: isLoadingEngagements,
      showAlertBanner: isLoadingEngagementsError,
      showProgressBars: isFetchingEngagements,
    },
  });

  const { mutateAsync: updateEngagement } = useUpdateEngagement({ table });
  const { mutateAsync: createEngagement } = useCreateEngagement({ table });

  const tableContent = (
    <>
      <MaterialReactTable table={table} />

      {currentEngagement &&
        user.role &&
        ["admin", "auditor"].includes(user.role) && (
          <AdminEditEngagementModal
            engagement={currentEngagement}
            isOpen={isEditModalOpen}
            onClose={closeEditModal}
          />
        )}
      {isCreatingEngagement && isAdmin && (
        <AdminEditEngagementModal
          engagement={defaultEngagement}
          isOpen={isCreatingEngagement}
          onClose={closeCreateModal}
        />
      )}
    </>
  );
  return (
    <>
      {showLayout ? (
        <Layout pageTitle="Engagements" parentUrl="" parentPageName="">
          <div className="fullPageTable">{tableContent}</div>
        </Layout>
      ) : (
        <>
          <div className="cardMode">{tableContent}</div>
        </>
      )}
    </>
  );
};

export default AdminEngagementPage;
