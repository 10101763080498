import React from "react";
import axios from "axios";

import { apiClient } from "../../api/apiClient";
import { useQuery } from "@tanstack/react-query";

import {
  type EngagementQueryResult,
  type Engagement,
  EngagementTableUpdateArg,
} from "../../types/engagements";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export function useCreateEngagement(table?: EngagementTableUpdateArg) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (engagement: Engagement) => {
      const updatedEngagement = {
        ...engagement,
        users: engagement.users?.map((user) => user.id),
        organization: engagement.organization?.id || null,
        scopes: engagement.scopes?.map((scope) => scope.id),
        prev_engagements: engagement.prev_engagements?.map(
          (prev_engagement) => prev_engagement.id
        ),
        peer_engagements: engagement.peer_engagements?.map(
          (peer_engagement) => peer_engagement.id
        ),
        evidence_requests: engagement.evidence_requests?.map(
          (evidence_request) => evidence_request.id
        ),
      };

      try {
        const response = await apiClient.post(
          "/api/engagements/",
          updatedEngagement
        );

        if (table) {
          table.table.setCreatingRow(null);
        }

        toast.success(`Engagement ${engagement.name} created successfully`);
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
          throw error;
        } else {
          toast.error(`Error creating engagement: ${error}`);
        }
      }
    },
    onSuccess: (newEngagement: Engagement) => {
      queryClient.setQueryData<Engagement[]>(
        ["engagements"],
        (prevEngagements = []) => [...prevEngagements, newEngagement]
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["engagements"] }),
  });
}

export function useDeleteEngagement() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (engagementId: string) => {
      try {
        await apiClient.delete(`/api/engagements/${engagementId}/`);
        toast.success("Engagement successfully deleted");
      } catch (error) {
        toast.error("Failed to delete the engagement");
      }
    },
    onMutate: (engagementId: string) => {
      queryClient.setQueryData(["engagements"], (prevEngagements: any) =>
        prevEngagements?.filter(
          (engagement: Engagement) => engagement.id !== engagementId
        )
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["engagements"] }),
  });
}

export function useToggleCompleteEngagement() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (engagementId: string) => {
      try {
        await apiClient.post(
          `/api/engagements/${engagementId}/toggle-complete/`
        );
        toast.success("Engagement marked as completed");
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
        }
        // toast.error("Failed to mark engagement as completed");
      }
    },
    onMutate: (engagementId: string) => {
      queryClient.setQueryData(["engagements"], (prevEngagements: any) =>
        prevEngagements?.filter(
          (engagement: Engagement) => engagement.id !== engagementId
        )
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["engagements"] }),
  });
}

export function useGetEngagement({ id }: { id: string }) {
  return useQuery<EngagementQueryResult[]>({
    queryKey: ["engagements", id],
    queryFn: async () => {
      try {
        const url = `/api/engagements`;
        const params = new URLSearchParams({
          id: id,
        });

        const response = await apiClient.get(url, { params });

        return [
          {
            engagements: response.data.results as Engagement[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ engagements: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

export function useUpdateEngagement(table?: EngagementTableUpdateArg) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (engagement: Engagement) => {
      const updatedEngagement = {
        ...engagement,
        users: engagement.users?.map((user) => user.id),
        organization: engagement.organization?.id || null,
        scopes: engagement.scopes?.map((scope) => scope.id),
        prev_engagements: engagement.prev_engagements?.map(
          (prev_engagement) => prev_engagement.id
        ),
        peer_engagements: engagement.peer_engagements?.map(
          (peer_engagement) => peer_engagement.id
        ),
        evidence_requests: engagement.evidence_requests?.map(
          (evidence_request) => evidence_request.id
        ),
      };

      try {
        await apiClient.patch(
          `/api/engagements/${engagement.id}/`,
          updatedEngagement
        );

        if (table) {
          table.table.setEditingRow(null);
        }
        toast.success(`Engagement ${engagement.name}  updated successfully`);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
          throw error;
        } else {
          toast.error(`Error updating engagement ${error}`);
        }
      }
    },

    onMutate: (newEngagementInfo: Engagement) => {
      queryClient.setQueryData(["engagements"], (prevEngagements: any) =>
        prevEngagements?.map((prevEngagement: Engagement) =>
          prevEngagement.id === newEngagementInfo.id
            ? newEngagementInfo
            : prevEngagement
        )
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["engagements"] }),
  });
}

export function useSearchEngagements({
  q,
  organization,
}: {
  q: string;
  organization?: string;
}) {
  return useQuery<EngagementQueryResult[]>({
    queryKey: ["engagements", q],
    queryFn: async () => {
      try {
        let url = `/api/engagements/?search=${q}`;
        if (organization) {
          url += `&organization=${encodeURIComponent(organization)}`;
        }

        const response = await apiClient.get(url);

        return [
          {
            engagements: response.data.results as Engagement[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ engagements: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

export const useExportEngagements = (columnFilters: any, sorting: any) => {
  const exportEngagements = async () => {
    try {
      const response = await apiClient.get("/api/engagements/export/", {
        params: {
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;

      const now = new Date();
      const dateString = now.toISOString().split("T")[0];
      const timestamp = now.getTime();
      const filename = `Engagements-${dateString}-${timestamp}.csv`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();

      toast.success(`${filename} downloaded successfully`);
    } catch (error) {
      toast.error(`Failed to download file : ${error}`);
    }
  };

  return exportEngagements;
};

export const useDeleteEngagements = (
  columnFilters: any,
  sorting: any,
  rowCount: number
) => {
  const deleteEngagements = async () => {
    try {
      await apiClient.get("/api/engagements/delete/", {
        params: {
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
        },
      });

      toast.success(`Deleted ${rowCount} rows successfully`);
    } catch (error) {
      toast.error("Failed to delete rows");
    } finally {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return deleteEngagements;
};

type PatchEngagementInput = {
  engagementId: string;
  evidenceRequestId?: string;
};

export function usePatchEngagement() {
  return useMutation({
    mutationFn: async ({
      engagementId,
      evidenceRequestId,
    }: PatchEngagementInput) => {
      const patchData: any = {};
      if (evidenceRequestId) {
        patchData.evidence_request = evidenceRequestId;
      }

      try {
        await apiClient.patch(`/api/engagements/add/`, patchData, {
          params: { engagement: engagementId },
        });
        toast.success(`Engagement updated successfully`);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
        } else {
          toast.error(`Error updating Engagement: ${error}`);
        }
      }
    },
  });
}

export function useUnPatchEngagement() {
  return useMutation({
    mutationFn: async ({
      engagementId,
      evidenceRequestId,
    }: PatchEngagementInput) => {
      const patchData: any = {};
      if (evidenceRequestId) {
        patchData.evidence_request = evidenceRequestId;
      }

      try {
        await apiClient.patch(`/api/engagements/remove/`, patchData, {
          params: { engagement: engagementId },
        });
        toast.success(`Engagement updated successfully`);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
        } else {
          toast.error(`Error updating Engagement: ${error}`);
        }
      }
    },
  });
}

type PatchEngagementByTitleInput = {
  engagementId: string;
  evidencerequestTitles?: string[];
};

export function usePatchEngagementByTitle() {
  return useMutation({
    mutationFn: async ({
      engagementId,
      evidencerequestTitles,
    }: PatchEngagementByTitleInput) => {
      const patchData: any = {};
      if (evidencerequestTitles) {
        patchData.evidence_requests = evidencerequestTitles;
      }

      try {
        await apiClient.patch(`/api/engagements/add-by-title/`, patchData, {
          params: { engagement: engagementId },
        });
        toast.success(`Engagement updated successfully`);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
        } else {
          toast.error(`Error updating Engagement: ${error}`);
        }
      }
    },
  });
}

export function useUnPatchEngagementByTitle() {
  return useMutation({
    mutationFn: async ({
      engagementId,
      evidencerequestTitles,
    }: PatchEngagementByTitleInput) => {
      const patchData: any = {};
      if (evidencerequestTitles) {
        patchData.evidence_requests = evidencerequestTitles;
      }

      try {
        await apiClient.patch(`/api/engagements/remove-by-title/`, patchData, {
          params: { engagement: engagementId },
        });
        toast.success(`Engagement updated successfully`);
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
        } else {
          toast.error(`Error updating Engagement: ${error}`);
        }
      }
    },
  });
}
