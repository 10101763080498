import React, { useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import Layout from "../../layout";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Modal,
  Tooltip,
  Typography,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useCreateEvidenceRequest,
  useUpdateEvidenceRequest,
  useDeleteEvidenceRequest,
  useExportEvidenceRequests,
} from "../../hooks/evidencerequests";

import {
  EvidenceRequest,
  defaultEvidenceRequest,
} from "../../types/evidencerequests";
import { GridCloseIcon } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { useGetObjects } from "../../hooks/common";
import AdminEditEvidenceRequestModal from "../../components/evidencerequests/edit";
import { toast } from "react-toastify";
import ImportFromCSV from "../../components/csv/importFromCSV";
import { EvidenceRequestsPieChart } from "../../components/charts/EvidenceRequestsPieChart";
import { baseURL } from "../../api/apiClient";

interface AdminEvidenceRequestPageProp {
  cardMode?: boolean;
}

const AdminEvidenceRequestPage: React.FC<AdminEvidenceRequestPageProp> = ({
  cardMode = false,
}) => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );

  const showLayout = !cardMode;
  const [showAttentionRequired, setShowAttentionRequired] =
    useState<boolean>(false);

  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [columnFilters]);

  const [statusFilterApplied, setStatusFilterApplied] =
    useState<boolean>(false);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [columnFilters]);

  const applyStatusFilter = (columnId: string, newFilterValues: string[]) => {
    setColumnFilters((currentFilters: MRT_ColumnFiltersState) => {
      const isFilterApplied = currentFilters.some((filter) => {
        const filterValues = filter.value as string[];
        return (
          filter.id === columnId &&
          newFilterValues.every((value) => filterValues.includes(value)) &&
          filterValues.length === newFilterValues.length
        );
      });

      if (isFilterApplied) {
        return currentFilters.filter((filter) => filter.id !== columnId);
      } else {
        const updatedFilter = {
          id: columnId,
          value: newFilterValues,
        };
        return [
          ...currentFilters.filter((filter) => filter.id !== columnId),
          updatedFilter,
        ];
      }
    });
    setStatusFilterApplied(!statusFilterApplied);
  };

  const tableName = "admin_evidencerequests_page_table";
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const tableState = JSON.parse(localStorage.getItem("tableState") || "{}");
    return (
      tableState[tableName]?.columnVisibility || {
        id: false,
        controls: !cardMode,
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tableState") || "{}"),
        [tableName]: {
          columnVisibility,
        },
      })
    );
  }, [columnVisibility, tableName]);

  const { mutateAsync: deleteEvidenceRequest } = useDeleteEvidenceRequest();

  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [currentEvidenceRequest, setCurrentEvidenceRequest] =
    useState<EvidenceRequest | null>(null);

  const [isCreatingEvidenceRequest, setIsCreatingEvidenceRequest] =
    useState<boolean>(false);

  const openEditModal = (evidencerequest: EvidenceRequest) => {
    setCurrentEvidenceRequest(evidencerequest);
    setEditModalOpen(true);
  };

  const closeEditModal = () => {
    setEditModalOpen(false);
    setCurrentEvidenceRequest(null);
  };

  const openCreateModal = () => {
    setIsCreatingEvidenceRequest(true);
    setEditModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreatingEvidenceRequest(false);
    setCurrentEvidenceRequest(null);
  };

  const getParams = {
    pagination,
    columnFilters,
    sorting,
    globalFilter,
    relatedParentField: undefined,
    relatedChildField: undefined,
    relatedParentId: undefined,
    showRelatedOnly: undefined,
    showAttentionRequired: showAttentionRequired,
  };

  const {
    data: evidencerequestQueryResults = [],
    isError: isLoadingEvidenceRequestsError,
    isLoading: isLoadingEvidenceRequests,
    isFetching: isFetchingEvidenceRequests,
  } = useGetObjects<
    EvidenceRequest,
    [
      "evidencerequests",
      {
        pagination: MRT_PaginationState;
        columnFilters: MRT_ColumnFiltersState;
        sorting: MRT_SortingState;
        globalFilter: string | undefined;
        relatedParentField: undefined;
        relatedChildField: undefined;
        relatedParentId: undefined;
        showRelatedOnly: undefined;
        showAttentionRequired: boolean | undefined;
      }
    ]
  >(
    [
      "evidencerequests",
      {
        pagination,
        columnFilters,
        sorting,
        globalFilter,
        relatedParentField: undefined,
        relatedChildField: undefined,
        relatedParentId: undefined,
        showRelatedOnly: undefined,
        showAttentionRequired: showAttentionRequired,
      },
    ],
    "evidencerequests",
    getParams
  );

  const fetchedEvidenceRequests = evidencerequestQueryResults[0]?.data || [];

  useEffect(() => {
    const count = evidencerequestQueryResults[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [evidencerequestQueryResults]);

  const handleCreateEvidenceRequest: MRT_TableOptions<EvidenceRequest>["onCreatingRowSave"] =
    async ({ values, table }) => {
      const newValidationErrors = validateEvidenceRequest(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await createEvidenceRequest(values);
    };

  const handleSaveEvidenceRequest: MRT_TableOptions<EvidenceRequest>["onEditingRowSave"] =
    async ({ values, table }) => {
      const newValidationErrors = validateEvidenceRequest(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await updateEvidenceRequest(values);
    };

  const openDeleteConfirmModal = (row: MRT_Row<EvidenceRequest>) => {
    if (
      window.confirm("Are you sure you want to delete this Evidence Request?")
    ) {
      deleteEvidenceRequest(row.original.id);
    }
  };

  function validateEvidenceRequest(evidencerequest: EvidenceRequest) {
    const validateRequired = (value: string) => !!value.trim().length;

    return {
      name: !validateRequired(evidencerequest.title) ? "Title is required" : "",
    };
  }

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const exportEvidenceRequests = useExportEvidenceRequests(
    columnFilters,
    sorting
  );
  const openImportModal = () => setIsImportModalOpen(true);
  const closeImportModal = () => setIsImportModalOpen(false);

  const handleExport = async () => {
    try {
      await exportEvidenceRequests();
    } catch (error) {
      console.error(error);
      toast.error("Failed to export");
    } finally {
    }
  };

  const columns = useMemo<MRT_ColumnDef<EvidenceRequest>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
        enableSorting: false,
      },

      {
        accessorKey: "title",
        header: "Title",

        Cell: ({ row }) => {
          return (
            <>
              <Link to={`/evidence-request/${row.original.id}`}>
                {row.original.title}
              </Link>
            </>
          );
        },
        muiEditTextFieldProps: {
          type: "text",
          required: true,
          error: !!validationErrors?.title,
          helperText: validationErrors?.title,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              title: undefined,
            }),
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        filterVariant: "select",
        filterSelectOptions: [
          "New",
          "In Progress",
          "Submitted For Review",
          "In Review",
          "Followup Required",
          "Accepted",
          "Overdue",
        ],
      },
      {
        accessorKey: "due_date",
        header: "Due Date",
        Cell: ({ row }) => {
          if (row.original.due_date) {
            const date = new Date(row.original.due_date);
            return new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour12: true,
            }).format(date);
          }
        },
        muiEditTextFieldProps: {
          type: "Date",
          required: false,
        },
      },
      {
        accessorKey: "organization.name",
        header: "Organization",
      },
      {
        accessorKey: "verifiers",
        enableSorting: false,
        header: "Verifiers",
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.verifiers.map((user, index) => (
                  <div key={index}>{user.name}</div>
                ))}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "users",
        enableSorting: false,
        header: "Users",
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.users.map((user, index) => (
                  <div key={index}>{user.name}</div>
                ))}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        enableSorting: false,
        size: 240,

        Cell: ({ row }) => (
          <div
            style={{
              textAlign: "left",
              maxHeight: !cardMode ? "5em" : "3em",
            }}
          >
            <ReactMarkdown>{row.original.description}</ReactMarkdown>
          </div>
        ),
      },
      {
        accessorKey: "last_action",
        enableColumnFilter: false,
        header: "Last Action",
        enableEditing: false,
      },
      {
        accessorKey: "notes",
        enableSorting: false,
        header: "Notes",
        muiEditTextFieldProps: {
          type: "textarea",
        },
      },

      {
        accessorKey: "controls",
        enableSorting: false,
        header: "Controls",
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.controls.map((control, index) => (
                  <div key={index}>
                    <Link to={`/control/${control.id}`}>{control.name}</Link>
                  </div>
                ))}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "engagements",
        enableSorting: false,
        header: "Engagements",
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.engagements.map((engagement, index) => (
                  <div key={index}>
                    <Link to={`/engagement/${engagement.id}`}>
                      {engagement.name}
                    </Link>
                  </div>
                ))}
              </div>
            </>
          );
        },
      },
    ],
    [validationErrors]
  );

  const table = useMaterialReactTable({
    columns,
    enableGlobalFilter: false,
    data: fetchedEvidenceRequests,
    initialState: {
      showColumnFilters: false,
      density: "compact",
      columnVisibility: columnVisibility,
    },

    enableBottomToolbar: true,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    selectAllMode: "all",
    enableColumnResizing: true,
    getRowId: (row) => row.id,
    rowNumberDisplayMode: "original",
    muiToolbarAlertBannerProps: isLoadingEvidenceRequestsError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateEvidenceRequest,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveEvidenceRequest,

    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle
          variant="h3"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Edit Evidence Request
          <IconButton
            aria-label="close"
            onClick={() => table.setEditingRow(null)}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <GridCloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    enableRowActions: true,
    renderRowActionMenuItems: ({ row, table }) => [
      <Box key={row.id}>
        <Tooltip title="Edit">
          <IconButton onClick={() => openEditModal(row.original)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>,
    ],

    renderBottomToolbarCustomActions: ({ table }) => (
      <>
        {showLayout && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{
                backgroundColor: showAttentionRequired
                  ? "primary.light"
                  : "primary.main",
              }}
              variant="contained"
              onClick={() => setShowAttentionRequired(!showAttentionRequired)}
            >
              {showAttentionRequired ? "Show All" : "Show Action Required"}
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                openCreateModal();
              }}
            >
              New Evidence Request
            </Button>
            <Button variant="contained" onClick={handleExport}>
              Export {rowCount} Rows
            </Button>

            <Button variant="contained" onClick={openImportModal}>
              Import Evidence Requests
            </Button>

            <Modal
              open={isImportModalOpen}
              onClose={closeImportModal}
              id="modal"
              aria-labelledby="modal-title"
              aria-describedby="modal-description"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  height: "90vh",
                  width: "90vw",
                  bgcolor: "background.paper",
                  boxShadow: 24,
                  p: 4,
                  borderRadius: "8px",
                  overflow: "auto",
                }}
              >
                <Typography id="modal-title" variant="h6" component="h2">
                  CSV Import
                </Typography>
                <hr />
                <Typography id="modal-description" sx={{ mt: 2 }}></Typography>
                <ImportFromCSV
                  apiUrl={`${baseURL}/api/evidencerequests/import/`}
                  importType="evidencerequests"
                  onClose={closeImportModal}
                />

                <Button onClick={closeImportModal} sx={{ mt: 2 }}>
                  Close
                </Button>
              </Box>
            </Modal>
          </Box>
        )}

        {cardMode && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          ></Box>
        )}
      </>
    ),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      columnVisibility: columnVisibility,
      isLoading: isLoadingEvidenceRequests,
      showAlertBanner: isLoadingEvidenceRequestsError,
      showProgressBars: isFetchingEvidenceRequests,
    },
  });

  const { mutateAsync: updateEvidenceRequest } = useUpdateEvidenceRequest({
    table,
  });
  const { mutateAsync: createEvidenceRequest } = useCreateEvidenceRequest({
    table,
  });

  const tableContent = (
    <>
      <MaterialReactTable table={table} />

      {currentEvidenceRequest && (
        <AdminEditEvidenceRequestModal
          evidencerequest={currentEvidenceRequest}
          isOpen={isEditModalOpen}
          onClose={closeEditModal}
        />
      )}
      {isCreatingEvidenceRequest && (
        <AdminEditEvidenceRequestModal
          evidencerequest={defaultEvidenceRequest}
          isOpen={isEditModalOpen}
          onClose={closeCreateModal}
        />
      )}
    </>
  );

  return (
    <>
      {showLayout ? (
        <Layout pageTitle="Evidence Requests" parentUrl="" parentPageName="">
          <div className="fullPageTable">{tableContent}</div>
        </Layout>
      ) : (
        <>
          <div className="cardMode">
            <Grid
              sx={{ backgroundColor: "white", mt: 1, pl: 0 }}
              container
              spacing={2}
              padding={2}
            >
              <Grid item sm={12}>
                <EvidenceRequestsPieChart
                  rowCount={rowCount}
                  columnFilters={columnFilters}
                  setColumnFilters={setColumnFilters}
                  setShowAttentionRequired={setShowAttentionRequired}
                  showAttentionRequired={showAttentionRequired}
                />
              </Grid>
            </Grid>
            {tableContent}
          </div>
        </>
      )}
    </>
  );
};

export default AdminEvidenceRequestPage;
