import React, { useState, useEffect, FunctionComponent } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  ListItem,
  ListItemText,
  IconButton,
  Autocomplete,
  Box,
  Typography,
} from "@mui/material";
import PaginatedModalComponent from "../../edit/PaginatedModalComponent";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

import {
  useGetEvidenceRequest,
  useUpdateEvidenceRequest,
} from "../../../hooks/evidencerequests";

import CloseIcon from "@mui/icons-material/Close";
import {
  EvidenceRequest,
  defaultEvidenceRequest,
} from "../../../types/evidencerequests";
import { User } from "../../../types/users";
import { useSearchUsers } from "../../../hooks/users";

interface EditEvidenceRequestModalProps {
  evidencerequest?: Partial<EvidenceRequest> | null;
  isOpen?: boolean;
  mode?: "modal" | "page";
  onClose?: () => void;
}

const OrgAdminEditEvidenceRequestModal: FunctionComponent<
  EditEvidenceRequestModalProps
> = ({
  evidencerequest = defaultEvidenceRequest,
  isOpen = true,
  mode = "modal",
  onClose = () => {},
}) => {
  const [editedEvidenceRequest, setEditedEvidenceRequest] = useState<
    EvidenceRequest | Partial<EvidenceRequest>
  >(evidencerequest || defaultEvidenceRequest);
  const isPageMode = mode === "page";

  const { id } = useParams<{ id: string }>();
  const [loadingError, setLoadingError] = useState(false);

  if (id) {
    const { data, isLoading: isFetching } = useGetEvidenceRequest({ id });
    useEffect(() => {
      if (mode === "page" && id && data && !isFetching) {
        if (data.evidencerequests.length === 0) {
          setLoadingError(true);
        } else {
          const deepCopiedEvidenceRequest = JSON.parse(
            JSON.stringify(data.evidencerequests[0])
          );
          setEditedEvidenceRequest(deepCopiedEvidenceRequest);
        }
      }
    }, [id, mode, data, isFetching]);
  }

  const [searchQueryUser, setSearchQueryUser] = useState("");
  const [searchResultsUser, setSearchResultsUser] = useState<User[]>([]);

  const updateEvidenceRequest = useUpdateEvidenceRequest();

  const org = editedEvidenceRequest.engagements?.[0]?.organization?.id;

  const { data: userSearchResults, refetch: refetchUsers } = useSearchUsers({
    q: searchQueryUser,
    organization: org || "",
    intent: "users",
  });

  useEffect(() => {
    if (userSearchResults) {
      setSearchResultsUser(userSearchResults[0].users);
    }
  }, [userSearchResults]);

  useEffect(() => {
    refetchUsers();
  }, [searchQueryUser, refetchUsers]);

  const handleAddUser = (user: User): void => {
    let updatedUsers: User[];
    updatedUsers = [...(editedEvidenceRequest.users ?? []), user];
    setEditedEvidenceRequest((prev) => ({ ...prev, users: updatedUsers }));
  };

  const handleRemoveUser = (user: User): void => {
    const updatedUsers: User[] =
      editedEvidenceRequest.users?.filter((u) => u.id !== user.id) ?? [];

    setEditedEvidenceRequest((prev) => ({ ...prev, users: updatedUsers }));
  };

  const renderUser = (user: User) => (
    <ListItem className="modalEditListItem" key={user.id}>
      <ListItemText primary={user.name} />

      <IconButton
        edge="end"
        aria-label="remove"
        onClick={() => handleRemoveUser(user)}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    </ListItem>
  );

  const handleSubmit = async () => {
    try {
      if ("id" in editedEvidenceRequest) {
        await updateEvidenceRequest.mutateAsync(
          editedEvidenceRequest as EvidenceRequest
        );
        onClose();
      } else {
        onClose();
      }
    } catch (error) {
      //
    }
  };

  const content = (
    <>
      <DialogContent>
        {/* users on engagements */}

        <Box
          sx={{
            border: 1,
            borderColor: "divider",
            padding: 2,
            marginTop: 2,
            marginBottom: 2,
          }}
        >
          <Typography variant="h6">Users</Typography>
          <Autocomplete
            multiple
            options={searchResultsUser}
            getOptionLabel={(option) => option.name + " - " + option.email}
            filterSelectedOptions
            onChange={(event, newValue) => {
              newValue.forEach((user) => {
                if (
                  !editedEvidenceRequest.users?.some((u) => u.id === user.id)
                ) {
                  handleAddUser(user);
                }
              });
            }}
            renderTags={() => {
              return null;
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Users"
                variant="outlined"
                onChange={(e) => setSearchQueryUser(e.target.value)}
              />
            )}
          />
          <PaginatedModalComponent
            items={editedEvidenceRequest.users?.map((user) => user) ?? []}
            renderItem={renderUser}
          />
        </Box>
      </DialogContent>
    </>
  );

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Typography fontSize={25}>{editedEvidenceRequest.title}</Typography>
        <CloseIcon
          onClick={onClose}
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            fontSize: 30,
          }}
        />
      </DialogTitle>

      {content}

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrgAdminEditEvidenceRequestModal;
