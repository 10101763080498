import React from "react";
import { Navigate } from "react-router-dom";
import AdminEvidenceRequest from "./admin";
import UserEvidenceRequest from "./user";
import { getUserInfo } from "../../helpers/user";

const EvidenceRequestsPageRouteWrapper = () => {
  const userInfo = getUserInfo();

  if (userInfo.role === "admin" || userInfo.role === "auditor") {
    return <AdminEvidenceRequest />;
  } else if (userInfo.role === "user" || userInfo.role === "org_admin") {
    return <UserEvidenceRequest />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default EvidenceRequestsPageRouteWrapper;
