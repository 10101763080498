import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
} from "@mui/material";
import {
  useEvidenceRequestChat,
  useToggleMessageHidden,
} from "../../hooks/evidencerequests";
import { getUserInfo } from "../../helpers/user";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const EvidenceRequestChatInterface: React.FC<{
  conversationId: string;
  refetch: CallableFunction;
}> = ({ conversationId, refetch }) => {
  const { conversation, isLoading, sendMessage } =
    useEvidenceRequestChat(conversationId);
  const [currentMessage, setCurrentMessage] = useState("");
  const userInfo = getUserInfo();

  const { toggleHidden } = useToggleMessageHidden(conversationId);

  const handleSendMessage = (event: React.FormEvent) => {
    event.preventDefault();
    if (!currentMessage.trim()) return;
    sendMessage(currentMessage);
    setCurrentMessage("");
  };

  useEffect(() => {
    refetch();
  }, [currentMessage]);

  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    const scrollHeight = messagesContainerRef.current?.scrollHeight;
    const height = messagesContainerRef.current?.clientHeight;
    const maxScrollTop = scrollHeight! - height!;
    messagesContainerRef.current?.scrollTo({
      top: maxScrollTop,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [conversation?.messages]);

  const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentMessage(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && !event.shiftKey) {
      handleSendMessage(event);
    }
  };

  if (isLoading) return <div>Loading conversation...</div>;

  return (
    <Box sx={{ maxWidth: "600px", margin: "0 auto" }}>
      <Box
        ref={messagesContainerRef}
        sx={{ maxHeight: "45vh", overflow: "auto", marginBottom: "1rem" }}
      >
        <List>
          {conversation?.messages?.map((message) => {
            const formattedDate = new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }).format(new Date(message.timestamp));
            return (
              <ListItem
                key={message.id}
                sx={{
                  display: "flex",
                  justifyContent:
                    message.sender.id === userInfo.id
                      ? "flex-end"
                      : "flex-start",
                }}
              >
                <Box
                  sx={{
                    maxWidth: "80%",
                    padding: "8px 12px",
                    backgroundColor:
                      message.sender.id === userInfo.id ? "#e0f7fa" : "#f1f8e9",
                    borderRadius: "10px",
                  }}
                >
                  <ListItemText
                    title={message.sender.email}
                    primary={`${
                      message.sender.id === userInfo.id
                        ? "You"
                        : message.sender.name +
                          " " +
                          "(" +
                          message.sender.organization +
                          ")"
                    }: ${message.content}`}
                    primaryTypographyProps={{
                      style: { wordWrap: "break-word" },
                    }}
                    secondary={`Sent at ${formattedDate}`}
                  />
                  {message.sender.organization == "Securisea" &&
                    userInfo.role === "admin" && (
                      <>
                        {message.hidden ? (
                          <VisibilityOff
                            color="action"
                            onClick={() => toggleHidden(message.id)}
                          />
                        ) : (
                          <Visibility
                            color="action"
                            onClick={() => toggleHidden(message.id)}
                          />
                        )}
                      </>
                    )}
                </Box>
              </ListItem>
            );
          })}
        </List>
      </Box>
      <Box
        component="form"
        sx={{
          display: "flex",
          alignItems: "center",
          "& > :not(style)": { m: 1 },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSendMessage}
      >
        <TextField
          fullWidth
          label="Type a message..."
          variant="outlined"
          value={currentMessage}
          onChange={handleMessageChange}
          onKeyDown={handleKeyPress}
        />
        <Button type="submit" variant="contained">
          Send
        </Button>
      </Box>
    </Box>
  );
};

export default EvidenceRequestChatInterface;
