import React from "react";
import { Navigate } from "react-router-dom";
import AdminEditControlModal from "./admin";
import { getUserInfo } from "../../../helpers/user";
import UserViewControlModal from "./user";

const EditControlPageRouteWrapper = () => {
  const userInfo = getUserInfo();

  if (userInfo.role === "admin") {
    return <AdminEditControlModal mode="page" />;
  } else if (userInfo.role === "user" || userInfo.role === "auditor") {
    return <UserViewControlModal />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default EditControlPageRouteWrapper;
