import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";

import { Link } from "react-router-dom";
import { Box, Button, Modal, Typography } from "@mui/material";
import {
  useGeneralPatch,
  useExportRelatedObjects,
  useGetObjects,
} from "../../../hooks/common";
import { EvidenceRequest } from "../../../types/evidencerequests";
import { toast } from "react-toastify";

import { Engagement } from "../../../types/engagements";
import CSVPatch from "../../controlsets/edit/import";
import { get } from "lodash";
import { getUserInfo } from "../../../helpers/user";
import ImportFromCSV from "../../csv/importFromCSV";
import { baseURL } from "../../../api/apiClient";

interface HasEvidenceRequests {
  id?: string;
  evidencerequests?: EvidenceRequest[];
}

interface EvidenceRequestTableProps {
  parentObject: Partial<Engagement>;
  loadPartial: boolean;
  parentObjectUrlKey: string;
  relatedChildFieldName: string;
}

const EvidenceRequestTable = ({
  parentObject,
  loadPartial,
  parentObjectUrlKey,
  relatedChildFieldName,
}: EvidenceRequestTableProps) => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );

  const userInfo = getUserInfo();
  const [adminMode, setAdminMode] = useState<boolean>(false);

  useEffect(() => {
    if (userInfo.role === "admin") {
      setAdminMode(true);
    }
  }, []);

  const [editedParentObject, setEditedParentObject] =
    useState<HasEvidenceRequests>(parentObject);

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const [evidencerequestIdToAdd, setEvidenceRequestIdToAdd] = useState<
    string | null
  >(null);
  const [evidencerequestIdToRemove, setEvidenceRequestIdToRemove] = useState<
    string | null
  >(null);
  const [submitFlag, setSubmitFlag] = useState(false);
  const prevRowSelectionRef = useRef<Record<string, boolean>>({});

  const addPatchObject = useGeneralPatch("add");
  const removePatchObject = useGeneralPatch("remove");

  useEffect(() => {
    if (adminMode) {
      const currentSelectedIds = Object.keys(rowSelection);
      const previousSelectedIds = Object.keys(prevRowSelectionRef.current);
      const newlySelectedIds = currentSelectedIds.filter(
        (id) => rowSelection[id] && !prevRowSelectionRef.current[id]
      );
      const newlyDeselectedIds = previousSelectedIds.filter(
        (id) => !rowSelection[id] && prevRowSelectionRef.current[id]
      );

      if (newlySelectedIds.length > 0 && !initialLoad) {
        const evidencerequestId = newlySelectedIds[0];

        setEvidenceRequestIdToAdd(evidencerequestId);
        setSubmitFlag(true);
      } else if (newlyDeselectedIds.length > 0 && !initialLoad) {
        const evidencerequestId = newlyDeselectedIds[0];
        setEvidenceRequestIdToRemove(evidencerequestId);
        setSubmitFlag(true);
      }

      prevRowSelectionRef.current = { ...rowSelection };
    }
  }, [rowSelection, initialLoad]);

  useEffect(() => {
    if (adminMode) {
      if (submitFlag) {
        const evidencerequestIdadd = evidencerequestIdToAdd;
        const evidencerequestIdremove = evidencerequestIdToRemove;

        const parentObjectId = editedParentObject.id;

        if (parentObjectId) {
          if (evidencerequestIdadd) {
            addPatchObject.mutate(
              {
                parentObjectId: parentObjectId,
                parentObjectType: parentObjectUrlKey,
                childObjectType: relatedChildFieldName,
                childObjectId: evidencerequestIdadd,
              },
              {
                onSuccess: () => {
                  setSubmitFlag(false);
                  setEvidenceRequestIdToAdd(null);
                  setEvidenceRequestIdToRemove(null);
                },
              }
            );
          }
          if (evidencerequestIdremove) {
            removePatchObject.mutate(
              {
                parentObjectId: parentObjectId,
                parentObjectType: parentObjectUrlKey,
                childObjectType: relatedChildFieldName,
                childObjectId: evidencerequestIdremove,
              },

              {
                onSuccess: () => {
                  setSubmitFlag(false);
                  setEvidenceRequestIdToRemove(null);
                  setEvidenceRequestIdToAdd(null);
                },
              }
            );
          }
        }
      }
    }
  }, [
    submitFlag,
    evidencerequestIdToRemove,
    evidencerequestIdToAdd,
    rowSelection,
  ]);

  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [columnFilters]);

  useEffect(() => {
    const rowSelection: Record<string, boolean> = {};

    parentObject.evidence_requests?.forEach(
      (evidencerequest: EvidenceRequest) => {
        const id = evidencerequest.id;
        rowSelection[id] = true;
      }
    );

    if (userInfo.role === "admin") {
      setRowSelection(rowSelection);
    }
    setTimeout(() => {
      setInitialLoad(false);
    }, 1000);
  }, []);

  const tableName = "evidencerequests_table";
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const tableState = JSON.parse(localStorage.getItem("tableState") || "{}");
    return (
      tableState[tableName]?.columnVisibility || {
        id: false,
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tableState") || "{}"),
        [tableName]: {
          columnVisibility,
        },
      })
    );
  }, [columnVisibility, tableName]);

  const initialRowSelectionAndPinning = useMemo(() => {
    const rowSelection: Record<string, boolean> = {};
    const topPinning: string[] = [];

    parentObject.evidence_requests?.forEach(
      (evidencerequest: EvidenceRequest) => {
        const id = evidencerequest.id;
        rowSelection[id] = true;
        topPinning.push(id);
      }
    );

    return { rowSelection };
  }, [parentObject]);

  useEffect(() => {
    setEditedParentObject(parentObject);
  }),
    [parentObject];

  const relatedParentField = parentObjectUrlKey;
  const relatedChildField = relatedChildFieldName;
  const relatedParentId = parentObject.id;
  const [showRelatedOnly, setShowRelatedOnly] = useState(loadPartial);
  const [showAttentionRequired, setShowAttentionRequired] =
    useState(loadPartial);

  useEffect(() => {
    pagination.pageIndex = 0;
  }, [showRelatedOnly]);

  const getParams = {
    pagination,
    columnFilters,
    sorting,
    globalFilter,
    relatedParentField,
    relatedChildField,
    relatedParentId,
    showRelatedOnly,
    showAttentionRequired,
  };

  const {
    data: evidencerequestQueryResults = [],
    isError: isLoadingEvidenceRequestsError,
    isLoading: isLoadingEvidenceRequests,
    isFetching: isFetchingEvidenceRequests,
  } = useGetObjects<
    EvidenceRequest,
    [
      "evidencerequests",
      {
        pagination: MRT_PaginationState;
        columnFilters: MRT_ColumnFiltersState;
        sorting: MRT_SortingState;
        globalFilter: string | undefined;
        relatedParentField: string | undefined;
        relatedChildField: string | undefined;
        relatedParentId: string | undefined;
        showRelatedOnly: boolean;
        showAttentionRequired: boolean;
      }
    ]
  >(
    [
      "evidencerequests",
      {
        pagination,
        columnFilters,
        sorting,
        globalFilter,
        relatedParentField,
        relatedChildField,
        relatedParentId,
        showRelatedOnly,
        showAttentionRequired,
      },
    ],
    "evidencerequests",
    getParams
  );

  const fetchedEvidenceRequests = evidencerequestQueryResults[0]?.data || [];

  useEffect(() => {
    const count = evidencerequestQueryResults[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [evidencerequestQueryResults]);

  const toggleShowRelatedOnly = () => {
    setShowRelatedOnly(!showRelatedOnly);
  };

  const exportRelatedObjects = useExportRelatedObjects(
    relatedParentField,
    relatedParentId,
    "evidence_requests"
  );

  const handleExportRelatedObjects = async () => {
    try {
      await exportRelatedObjects();
    } catch (error) {
      console.error(error);
      toast.error("Failed to export");
    } finally {
    }
  };

  const columns = useMemo<MRT_ColumnDef<EvidenceRequest>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
        enableSorting: false,
      },
      {
        accessorKey: "title",
        header: "Title",
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <>
              <Link to={`/evidence-request/${row.original.id}`}>
                {row.original.title}
              </Link>
            </>
          );
        },
      },
      {
        accessorKey: "status",
        header: "Status",
        enableSorting: false,
      },
      {
        accessorKey: "due_date",
        header: "Due Date",
        enableColumnFilter: false,
        enableSorting: false,
        enableEditing: false,
        Cell: ({ row }) => {
          if (row.original.date_created) {
            const date = new Date(row.original.date_created);
            return new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }).format(date);
          }
        },
      },
      {
        accessorKey: "description",
        enableSorting: false,
        header: "Description",
        size: 240,

        Cell: ({ row }) => (
          <div
            style={{
              textAlign: "left",
              maxHeight: "5em",
            }}
          >
            <ReactMarkdown>{row.original.description}</ReactMarkdown>
          </div>
        ),
      },
      {
        accessorKey: "engagements",
        header: "Engagements",
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.engagements.map((engagement, index) => (
                  <div key={index}>
                    <Link to={`/engagement/${engagement.id}`}>
                      {engagement.name}
                    </Link>
                  </div>
                ))}
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    enableGlobalFilter: false,
    data: fetchedEvidenceRequests,
    positionToolbarAlertBanner: "none",
    enableRowSelection: userInfo.role === "admin",

    initialState: {
      rowSelection: initialRowSelectionAndPinning.rowSelection,
      showColumnFilters: false,
      density: "compact",
      columnVisibility: columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: adminMode ? setRowSelection : undefined,

    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableSelectAll: false,
    enableColumnResizing: true,
    getRowId: (row) => row.id,
    renderBottomToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          sx={{
            backgroundColor: showAttentionRequired
              ? "primary.light"
              : "primary.main",
          }}
          variant="contained"
          onClick={() => setShowAttentionRequired(!showAttentionRequired)}
        >
          {showAttentionRequired ? "Show All" : "Show Action Required"}
        </Button>
      </Box>
    ),
    rowNumberDisplayMode: "original",
    muiToolbarAlertBannerProps: isLoadingEvidenceRequestsError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    muiTableContainerProps: {
      sx: {
        minHeight: "100px",
      },
    },

    state: {
      columnFilters,
      globalFilter,
      rowSelection,
      pagination,
      sorting,
      columnVisibility: columnVisibility,
      isLoading: isLoadingEvidenceRequests,
      showAlertBanner: isLoadingEvidenceRequestsError,
      showProgressBars: isFetchingEvidenceRequests,
    },
  });
  const openImportModal = () => setIsImportModalOpen(true);
  const closeImportModal = () => setIsImportModalOpen(false);

  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  if (initialLoad) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loader"></div>
      </div>
    );
  } else {
    return (
      <div style={{ opacity: 1, transition: "opacity 3s" }}>
        <div className="fullPageTable">
          <MaterialReactTable table={table} />

          {adminMode && (
            <>
              <Button
                sx={{ margin: 2, marginLeft: 0 }}
                variant="contained"
                onClick={toggleShowRelatedOnly}
              >
                {showRelatedOnly ? "Show All" : "Show Related Only"}
              </Button>

              <Button
                sx={{ margin: 2, marginLeft: 0 }}
                variant="contained"
                onClick={handleExportRelatedObjects}
              >
                Export Related
              </Button>

              <Button
                sx={{ margin: 2, marginLeft: 0 }}
                variant="contained"
                onClick={openImportModal}
              >
                Import Evidence Requests
              </Button>
              <Modal
                open={isImportModalOpen}
                onClose={closeImportModal}
                id="modal"
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    height: "90vh",
                    width: "90vw",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                    borderRadius: "8px",
                    overflow: "auto",
                  }}
                >
                  <Typography id="modal-title" variant="h6" component="h2">
                    CSV Import
                  </Typography>
                  <hr />
                  <Typography
                    id="modal-description"
                    sx={{ mt: 2 }}
                  ></Typography>
                  <ImportFromCSV
                    apiUrl={`${baseURL}/api/evidencerequests/import/?engagement=${editedParentObject.id}`}
                    importType="evidencerequests"
                    onClose={closeImportModal}
                  />

                  <Button onClick={closeImportModal} sx={{ mt: 2 }}>
                    Close
                  </Button>
                </Box>
              </Modal>
              <Box
                sx={{
                  marginTop: 2,
                  gap: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    padding: 2,
                    border: 2,
                    borderColor: "divider",
                  }}
                >
                  <details>
                    <summary>Add Evidence Requests in Bulk</summary>
                    <CSVPatch
                      mode="evidencerequests"
                      action="add"
                      parentObjectType={parentObjectUrlKey}
                      parentObjectId={editedParentObject.id}
                      childObjectType={relatedChildFieldName}
                    />
                  </details>
                </Box>

                <Box
                  sx={{
                    padding: 2,
                    border: 2,
                    borderColor: "divider",
                  }}
                >
                  <details>
                    <summary>Remove Evidence Requests in Bulk</summary>
                    <CSVPatch
                      mode="evidencerequests"
                      action="remove"
                      parentObjectType={parentObjectUrlKey}
                      parentObjectId={editedParentObject.id}
                      childObjectType={relatedChildFieldName}
                    />
                  </details>
                </Box>
              </Box>
            </>
          )}
        </div>
      </div>
    );
  }
};

export default EvidenceRequestTable;
