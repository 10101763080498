import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../layout";
import {
  MRT_EditActionButtons,
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_TableOptions,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
} from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useGetScopes,
  useCreateScope,
  useUpdateScope,
  useDeleteScope,
} from "../../hooks/scopes";
import { Scope } from "../../types/scopes";
import { GridCloseIcon } from "@mui/x-data-grid";

const AdminScopePage = () => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );
  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [columnFilters]);

  const { mutateAsync: deleteScope } = useDeleteScope();

  const {
    data: scopeQueryResults = [],
    isError: isLoadingScopesError,
    isFetching: isFetchingScopes,
    isLoading: isLoadingScopes,
  } = useGetScopes({ pagination, columnFilters, sorting, globalFilter });

  const fetchedScopes = scopeQueryResults[0]?.scopes || [];

  useEffect(() => {
    const count = scopeQueryResults[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [scopeQueryResults]);

  const handleCreateScope: MRT_TableOptions<Scope>["onCreatingRowSave"] =
    async ({ values, table }) => {
      const newValidationErrors = validateScope(values);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      await createScope(values);
    };

  const handleSaveScope: MRT_TableOptions<Scope>["onEditingRowSave"] = async ({
    values,
    table,
  }) => {
    const newValidationErrors = validateScope(values);
    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }
    setValidationErrors({});
    await updateScope(values);
  };

  const openDeleteConfirmModal = (row: MRT_Row<Scope>) => {
    if (window.confirm("Are you sure you want to delete this Scope?")) {
      deleteScope(row.original.id);
    }
  };

  function validateScope(scope: Scope) {
    const validateRequired = (value: string) => !!value.trim().length;

    return {
      name: !validateRequired(scope.title) ? "Title is required" : "",
    };
  }

  const tableName = "admin_scopes_page_table";
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const tableState = JSON.parse(localStorage.getItem("tableState") || "{}");
    return (
      tableState[tableName]?.columnVisibility || {
        id: false,
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tableState") || "{}"),
        [tableName]: {
          columnVisibility,
        },
      })
    );
  }, [columnVisibility, tableName]);

  const columns = useMemo<MRT_ColumnDef<Scope>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
        enableSorting: false,
      },
      {
        accessorKey: "title",
        header: "Title",
        muiEditTextFieldProps: {
          type: "text",
          required: true,
          error: !!validationErrors?.title,
          helperText: validationErrors?.title,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              title: undefined,
            }),
        },
      },
      {
        accessorKey: "persons",
        enableSorting: false,
        header: "Persons",
        accessorFn: (row) => {
          if (Array.isArray(row.persons)) {
            return row.persons.join(", ");
          }
          return "";
        },
        muiEditTextFieldProps: {
          type: "text",
        },
      },

      {
        accessorKey: "networks",
        enableSorting: false,
        header: "Networks",
        accessorFn: (row) => {
          if (Array.isArray(row.networks)) {
            return row.networks.join(", ");
          }
          return "";
        },
        muiEditTextFieldProps: {
          type: "text",
        },
      },

      {
        accessorKey: "technologies",
        enableSorting: false,
        header: "Technologies",
        accessorFn: (row) => {
          if (Array.isArray(row.technologies)) {
            return row.technologies.join(", ");
          }
          return "";
        },
        muiEditTextFieldProps: {
          type: "text",
        },
      },

      {
        accessorKey: "physical_addresses",
        enableSorting: false,
        header: "Previous Scopes",
        accessorFn: (row) => {
          if (Array.isArray(row.physical_addresses)) {
            return row.physical_addresses.join(", ");
          }
          return "";
        },
        muiEditTextFieldProps: {
          type: "text",
        },
      },
      {
        accessorKey: "date_created",
        enableColumnFilter: false,
        header: "Date",
        enableEditing: false,
      },
    ],
    [validationErrors]
  );

  const table = useMaterialReactTable({
    columns,
    enableGlobalFilter: false,
    data: fetchedScopes,
    initialState: {
      showColumnFilters: false,
      density: "compact",
      columnVisibility: columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    selectAllMode: "all",
    enableColumnResizing: true,
    getRowId: (row) => row.id,
    rowNumberDisplayMode: "original",
    muiToolbarAlertBannerProps: isLoadingScopesError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateScope,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveScope,
    renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle variant="h3">New Scope</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => table.setCreatingRow(null)}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <GridCloseIcon />
        </IconButton>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),

    renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
      <>
        <DialogTitle
          variant="h3"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          Edit Scope
          <IconButton
            aria-label="close"
            onClick={() => table.setEditingRow(null)}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <GridCloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{ display: "flex", flexDirection: "column", gap: "1.5rem" }}
        >
          {internalEditComponents}
        </DialogContent>
        <DialogActions>
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </DialogActions>
      </>
    ),
    enableRowActions: true,
    renderRowActionMenuItems: ({ row, table }) => [
      <Box key={row.id}>
        <Tooltip title="Edit">
          <IconButton onClick={() => table.setEditingRow(row)}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton color="error" onClick={() => openDeleteConfirmModal(row)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Box>,
    ],
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            table.setCreatingRow(true);
          }}
        >
          New Scope
        </Button>
      </Box>
    ),
    state: {
      columnFilters,
      globalFilter,
      pagination,
      sorting,
      columnVisibility: columnVisibility,
      isLoading: isLoadingScopes,
      showAlertBanner: isLoadingScopesError,
      showProgressBars: isFetchingScopes,
    },
  });

  const { mutateAsync: updateScope } = useUpdateScope({ table });
  const { mutateAsync: createScope } = useCreateScope({ table });

  return (
    <Layout pageTitle="" parentUrl="" parentPageName="">
      <MaterialReactTable table={table} />
    </Layout>
  );
};

export default AdminScopePage;
