import React, { useEffect, useMemo, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import {
  MaterialReactTable,
  type MRT_ColumnDef,
  type MRT_ColumnFiltersState,
  type MRT_PaginationState,
  type MRT_SortingState,
  useMaterialReactTable,
} from "material-react-table";

import { Link } from "react-router-dom";
import { Engagement } from "../../../types/engagements";
import { Box, Button, Typography } from "@mui/material";
import {
  useGeneralPatch,
  useExportRelatedObjects,
  useGetObjects,
} from "../../../hooks/common";
import { EvidenceRequest } from "../../../types/evidencerequests";
import { toast } from "react-toastify";
import CSVPatch from "../../controlsets/edit/import";
import { getUserInfo } from "../../../helpers/user";

interface HasEngagements {
  id?: string;
  engagements?: Engagement[];
}

interface EngagementTableProps {
  parentObject: Partial<EvidenceRequest>;
  loadPartial: boolean;
  parentObjectUrlKey: string;
  relatedChildFieldName: string;
}

const EngagementTable = ({
  parentObject,
  loadPartial,
  parentObjectUrlKey,
  relatedChildFieldName,
}: EngagementTableProps) => {
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>(
    []
  );

  const [adminMode, setAdminMode] = useState<boolean>(false);
  useEffect(() => {
    const userInfo = getUserInfo();
    if (userInfo.role === "admin" || userInfo.role === "auditor") {
      setAdminMode(true);
    }
  }, []);

  const [editedParentObject, setEditedParentObject] =
    useState<HasEngagements>(parentObject);

  const [rowSelection, setRowSelection] = useState<Record<string, boolean>>({});
  const [initialLoad, setInitialLoad] = useState<boolean>(true);

  const [engagementIdToAdd, setEngagementIdToAdd] = useState<string | null>(
    null
  );
  const [engagementIdToRemove, setEngagementIdToRemove] = useState<
    string | null
  >(null);
  const [submitFlag, setSubmitFlag] = useState(false);
  const prevRowSelectionRef = useRef<Record<string, boolean>>({});

  const addPatchObject = useGeneralPatch("add");
  const removePatchObject = useGeneralPatch("remove");

  useEffect(() => {
    if (adminMode) {
      const currentSelectedIds = Object.keys(rowSelection);
      const previousSelectedIds = Object.keys(prevRowSelectionRef.current);
      const newlySelectedIds = currentSelectedIds.filter(
        (id) => rowSelection[id] && !prevRowSelectionRef.current[id]
      );
      const newlyDeselectedIds = previousSelectedIds.filter(
        (id) => !rowSelection[id] && prevRowSelectionRef.current[id]
      );

      if (newlySelectedIds.length > 0 && !initialLoad) {
        const engagementId = newlySelectedIds[0];

        setEngagementIdToAdd(engagementId);
        setSubmitFlag(true);
      } else if (newlyDeselectedIds.length > 0 && !initialLoad) {
        const engagementId = newlyDeselectedIds[0];
        setEngagementIdToRemove(engagementId);
        setSubmitFlag(true);
      }

      prevRowSelectionRef.current = { ...rowSelection };
    }
  }, [rowSelection, initialLoad]);

  useEffect(() => {
    if (adminMode) {
      if (submitFlag) {
        const engagementIdadd = engagementIdToAdd;
        const engagementIdremove = engagementIdToRemove;

        const parentObjectId = editedParentObject.id;

        if (parentObjectId) {
          if (engagementIdadd) {
            addPatchObject.mutate(
              {
                parentObjectId: parentObjectId,
                parentObjectType: parentObjectUrlKey,
                childObjectType: relatedChildFieldName,
                childObjectId: engagementIdadd,
              },
              {
                onSuccess: () => {
                  setSubmitFlag(false);
                  setEngagementIdToAdd(null);
                  setEngagementIdToRemove(null);
                },
              }
            );
          }
          if (engagementIdremove) {
            removePatchObject.mutate(
              {
                parentObjectId: parentObjectId,
                parentObjectType: parentObjectUrlKey,
                childObjectType: relatedChildFieldName,
                childObjectId: engagementIdremove,
              },

              {
                onSuccess: () => {
                  setSubmitFlag(false);
                  setEngagementIdToRemove(null);
                  setEngagementIdToAdd(null);
                },
              }
            );
          }
        }
      }
    }
  }, [submitFlag, engagementIdToRemove, engagementIdToAdd, rowSelection]);

  const [globalFilter, setGlobalFilter] = useState<string | undefined>("");
  const [sorting, setSorting] = useState<MRT_SortingState>([]);

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
  }, [columnFilters]);

  useEffect(() => {
    const rowSelection: Record<string, boolean> = {};

    parentObject.engagements?.forEach((engagement: Engagement) => {
      const id = engagement.id;
      rowSelection[id] = true;
    });

    setRowSelection(rowSelection);
    setTimeout(() => {
      setInitialLoad(false);
    }, 1000);
  }, []);

  const initialRowSelectionAndPinning = useMemo(() => {
    const rowSelection: Record<string, boolean> = {};
    const topPinning: string[] = [];

    parentObject.engagements?.forEach((engagement: Engagement) => {
      const id = engagement.id;
      rowSelection[id] = true;
      topPinning.push(id);
    });

    return { rowSelection };
  }, [parentObject]);

  useEffect(() => {
    setEditedParentObject(parentObject);
  }),
    [parentObject];

  const relatedParentField = parentObjectUrlKey;
  const relatedChildField = relatedChildFieldName;
  const relatedParentId = parentObject.id;
  const [showRelatedOnly, setShowRelatedOnly] = useState(loadPartial);

  useEffect(() => {
    pagination.pageIndex = 0;
  }, [showRelatedOnly]);

  const tableName = "engagements_table";
  const [columnVisibility, setColumnVisibility] = useState(() => {
    const tableState = JSON.parse(localStorage.getItem("tableState") || "{}");
    return (
      tableState[tableName]?.columnVisibility || {
        id: false,
      }
    );
  });

  useEffect(() => {
    localStorage.setItem(
      "tableState",
      JSON.stringify({
        ...JSON.parse(localStorage.getItem("tableState") || "{}"),
        [tableName]: {
          columnVisibility,
        },
      })
    );
  }, [columnVisibility, tableName]);

  const getParams = {
    pagination,
    columnFilters,
    sorting,
    globalFilter,
    relatedParentField,
    relatedChildField,
    relatedParentId,
    showRelatedOnly,
  };

  const {
    data: engagementQueryResults = [],
    isError: isLoadingEngagementsError,
    isLoading: isLoadingEngagements,
    isFetching: isFetchingEngagements,
  } = useGetObjects<
    Engagement,
    [
      "engagements",
      {
        pagination: MRT_PaginationState;
        columnFilters: MRT_ColumnFiltersState;
        sorting: MRT_SortingState;
        globalFilter: string | undefined;
        relatedParentField: string | undefined;
        relatedChildField: string | undefined;
        relatedParentId: string | undefined;
        showRelatedOnly: boolean;
      }
    ]
  >(
    [
      "engagements",
      {
        pagination,
        columnFilters,
        sorting,
        globalFilter,
        relatedParentField,
        relatedChildField,
        relatedParentId,
        showRelatedOnly,
      },
    ],
    "engagements",
    getParams
  );

  const fetchedEngagements = engagementQueryResults[0]?.data || [];

  useEffect(() => {
    const count = engagementQueryResults[0]?.rowCount;
    if (count == 0) {
      setRowCount(0);
    }
    if (!count || count === rowCount) {
      return;
    }
    setRowCount(count);
  }, [engagementQueryResults]);

  const toggleShowRelatedOnly = () => {
    setShowRelatedOnly(!showRelatedOnly);
  };

  const exportRelatedObjects = useExportRelatedObjects(
    relatedParentField,
    relatedParentId,
    "engagements"
  );

  const handleExportRelatedObjects = async () => {
    try {
      await exportRelatedObjects();
    } catch (error) {
      console.error(error);
      toast.error("Failed to export");
    } finally {
    }
  };

  const columns = useMemo<MRT_ColumnDef<Engagement>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Id",
        enableEditing: false,
        size: 80,
        enableSorting: false,
      },

      {
        accessorKey: "name",
        header: "Name",
        enableSorting: false,
        Cell: ({ row }) => {
          return (
            <>
              <Link to={`/engagement/${row.original.id}`}>
                <Typography
                  sx={{
                    fontSize: "0.875rem",
                    color: row.original.completed ? "grey" : "inherit",
                  }}
                >
                  {row.original.name}
                </Typography>
              </Link>
            </>
          );
        },
      },
      {
        accessorKey: "description",
        header: "Description",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => (
          <div style={{ textAlign: "left", maxHeight: "110px" }}>
            <ReactMarkdown>{row.original.description}</ReactMarkdown>
          </div>
        ),
      },
      {
        accessorKey: "due_date",
        enableSorting: false,
        header: "Due Date",
        enableColumnFilter: false,
        Cell: ({ row }) => {
          if (row.original.date_created) {
            const date = new Date(row.original.date_created);
            return new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
            }).format(date);
          }
        },
        muiEditTextFieldProps: {
          type: "Date",
          enableEditing: false,
        },
      },
      {
        accessorKey: "organization.name",
        header: "Organization",
        enableSorting: false,
        enableColumnFilter: false,
      },
      {
        accessorKey: "completed",
        enableSorting: false,
        header: "Completed",
        accessorFn: (row) => (row.completed ? "Yes" : "No"),
      },
      {
        accessorKey: "evidence_requests",
        header: "Evidence Requests",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.evidence_requests.map((request, index) => (
                  <div key={index}>
                    <Link to={`/evidence-request/${request.id}`}>
                      {request.title}
                    </Link>
                  </div>
                ))}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "users",
        header: "Users",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.users.map((user, index) => (
                  <div key={index}>{user.name}</div>
                ))}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "prev_engagements",
        header: "Previous Engagements",

        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.prev_engagements.map((prev_engagement, index) => (
                  <div key={index}>
                    <Link to={`/engagement/${prev_engagement.id}`}>
                      {prev_engagement.name}
                    </Link>
                  </div>
                ))}
              </div>
            </>
          );
        },
      },
      {
        accessorKey: "peer_engagements",
        header: "Peer Engagements",
        enableSorting: false,
        enableColumnFilter: false,
        Cell: ({ row }) => {
          return (
            <>
              <div className="multipleObjectsCell">
                {row.original.peer_engagements.map((peer_engagement, index) => (
                  <div key={index}>
                    <Link to={`/engagement/${peer_engagement.id}`}>
                      {peer_engagement.name}
                    </Link>
                  </div>
                ))}
              </div>
            </>
          );
        },
      },

      {
        accessorKey: "date_created",
        enableColumnFilter: false,
        enableSorting: false,
        header: "Created On",
        Cell: ({ row }) => {
          if (row.original.date_created) {
            const date = new Date(row.original.date_created);
            return new Intl.DateTimeFormat(undefined, {
              year: "numeric",
              month: "short",
              day: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
              hour12: true,
            }).format(date);
          }
        },
        muiEditTextFieldProps: {
          type: "Date",
          enableEditing: false,
        },
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    enableGlobalFilter: false,
    data: fetchedEngagements,
    positionToolbarAlertBanner: "none",
    enableRowSelection: true,

    initialState: {
      rowSelection: initialRowSelectionAndPinning.rowSelection,
      showColumnFilters: false,
      density: "compact",
      columnVisibility: columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: adminMode ? setRowSelection : undefined,
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    createDisplayMode: "modal",
    editDisplayMode: "modal",
    enableSelectAll: false,
    enableColumnResizing: true,
    getRowId: (row) => row.id,
    rowNumberDisplayMode: "original",
    muiToolbarAlertBannerProps: isLoadingEngagementsError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    rowCount,
    muiTableContainerProps: {
      sx: {
        minHeight: "100px",
      },
    },

    state: {
      columnFilters,
      globalFilter,
      rowSelection,
      pagination,
      sorting,
      columnVisibility: columnVisibility,
      isLoading: isLoadingEngagements,
      showAlertBanner: isLoadingEngagementsError,
      showProgressBars: isFetchingEngagements,
    },
  });

  if (initialLoad) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="loader"></div>
      </div>
    );
  } else {
    return (
      <div style={{ opacity: 1, transition: "opacity 3s" }}>
        <div className="fullPageTable">
          <MaterialReactTable table={table} />

          {adminMode && (
            <>
              <Button
                sx={{ margin: 2, marginLeft: 0 }}
                variant="contained"
                onClick={toggleShowRelatedOnly}
              >
                {showRelatedOnly ? "Show All" : "Show Related Only"}
              </Button>

              <Button
                sx={{ margin: 2, marginLeft: 0 }}
                variant="contained"
                onClick={handleExportRelatedObjects}
              >
                Export Related
              </Button>

              <Box
                sx={{
                  marginTop: 2,
                  gap: 2,
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    padding: 2,
                    border: 2,
                    borderColor: "divider",
                  }}
                >
                  <details>
                    <summary>Add Engagements in Bulk</summary>
                    <CSVPatch
                      mode="engagements"
                      action="add"
                      parentObjectType={parentObjectUrlKey}
                      parentObjectId={editedParentObject.id}
                      childObjectType={relatedChildFieldName}
                    />
                  </details>
                </Box>

                <Box
                  sx={{
                    padding: 2,
                    border: 2,
                    borderColor: "divider",
                  }}
                >
                  <details>
                    <summary>Remove Engagements in Bulk</summary>
                    <CSVPatch
                      mode="engagements"
                      action="remove"
                      parentObjectType={parentObjectUrlKey}
                      parentObjectId={editedParentObject.id}
                      childObjectType={relatedChildFieldName}
                    />
                  </details>
                </Box>
              </Box>
            </>
          )}
        </div>
      </div>
    );
  }
};

export default EngagementTable;
