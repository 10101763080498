import React from "react";
import { Navigate } from "react-router-dom";
import AdminEditEvidenceRequestPage from "./admin";
import UserEditEvidenceRequest from "./user";
import { getUserInfo } from "../../helpers/user";

const EditEvidenceRequestPageRouteWrapper = () => {
  const userInfo = getUserInfo();

  if (userInfo.role === "admin" || userInfo.role === "auditor") {
    return <AdminEditEvidenceRequestPage />;
  } else if (userInfo.role === "user" || userInfo.role === "org_admin") {
    return <UserEditEvidenceRequest />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default EditEvidenceRequestPageRouteWrapper;
