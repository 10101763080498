import axios, { AxiosInstance, AxiosError, AxiosRequestConfig } from "axios";
import { redirectTo } from "./navigation";

interface TokenResponse {
  access_token: string;
  refresh_token: string;
}

declare module "axios" {
  export interface AxiosRequestConfig {
    _retry?: boolean;
  }
}

export const sessionExpiry = () => {
  const now = new Date();
  const accessTokenExpiry = new Date(now.getTime() + 1 * 60 * 59 * 1000); // 59 minutes
  return accessTokenExpiry.toISOString();
};

function updateTokens(data: TokenResponse) {
  localStorage.setItem("access_token", data.access_token);
  localStorage.setItem("refresh_token", data.refresh_token);
  localStorage.setItem("session_expiry", sessionExpiry());
}

export const baseURL =
  process.env.REACT_APP_ENVIRONMENT === "development"
    ? process.env.REACT_APP_API_BASE_URL
    : `https://${process.env.REACT_APP_API_BASE_URL}`;

export const apiClient: AxiosInstance = axios.create({
  baseURL: baseURL,
});

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    delete config.headers.Authorization; // Ensure no Authorization header if no token
  }
  return config;
});

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response?.status === 401) {
      if (!error.config._retry) {
        error.config._retry = true;
        try {
          const refreshToken = localStorage.getItem("refresh_token");
          const tokenResponse = await axios.post(
            `${baseURL}/api/access-token/`,
            { refresh_token: refreshToken }
          );
          updateTokens(tokenResponse.data);
          return apiClient(error.config);
        } catch (refreshError) {
          redirectTo("/logout");
          return Promise.reject(refreshError);
        }
      } else {
        return Promise.reject(error);
      }
    } else {
      return Promise.reject(error);
    }
  }
);
