import React from "react";
import axios from "axios";

import { apiClient } from "../../api/apiClient";
import { useQuery } from "@tanstack/react-query";
import {
  MRT_ColumnFiltersState,
  MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import {
  type ComplianceStandardQueryResult,
  type ComplianceStandard,
  ComplianceStandardTableUpdateArg,
} from "../../types/compliancestandards";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";

export function useCreateComplianceStandard({
  table,
}: ComplianceStandardTableUpdateArg) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (compliancestandard: ComplianceStandard) => {
      try {
        const response = await apiClient.post(
          "/api/compliancestandards/",
          compliancestandard
        );

        table.setCreatingRow(null);
        toast.success(
          `Compliance Standard ${compliancestandard.title} created successfully`
        );
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
        } else {
          toast.error(`Error creating Compliance Standard: ${error}`);
        }
      }
    },
    onSuccess: (newComplianceStandard: ComplianceStandard) => {
      queryClient.setQueryData<ComplianceStandard[]>(
        ["compliancestandards"],
        (prevComplianceStandards = []) => [
          ...prevComplianceStandards,
          newComplianceStandard,
        ]
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["compliancestandards"] }),
  });
}

export function useDeleteComplianceStandard() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (compliancestandardId: string) => {
      try {
        await apiClient.delete(
          `/api/compliancestandards/${compliancestandardId}`
        );
        toast.success("Compliance Standard successfully deleted");
      } catch (error) {
        toast.error("Failed to delete the Compliance Standard");
      }
    },
    onMutate: (compliancestandardId: string) => {
      queryClient.setQueryData(
        ["compliancestandards"],
        (prevComplianceStandards: any) =>
          prevComplianceStandards?.filter(
            (compliancestandard: ComplianceStandard) =>
              compliancestandard.id !== compliancestandardId
          )
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["compliancestandards"] }),
  });
}

export function useSearchComplianceStandards({ q }: { q: string }) {
  return useQuery<ComplianceStandardQueryResult[]>({
    queryKey: ["compliancestandards", q],
    queryFn: async () => {
      try {
        const url = `/api/compliancestandards?search=${q}`;

        const response = await apiClient.get(url);

        return [
          {
            compliancestandards: response.data.results as ComplianceStandard[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ compliancestandards: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

export function useGetComplianceStandards({
  pagination,
  columnFilters,
  sorting,
  globalFilter,
  exportCsv,
}: {
  pagination: MRT_PaginationState;
  columnFilters: MRT_ColumnFiltersState;
  sorting: MRT_SortingState;
  globalFilter: string | undefined;
  exportCsv?: boolean;
}) {
  return useQuery<ComplianceStandardQueryResult[]>({
    queryKey: [
      "compliancestandards",
      pagination,
      columnFilters,
      sorting,
      globalFilter,
      exportCsv,
    ],
    queryFn: async () => {
      try {
        const url = `/api/compliancestandards`;
        const params = new URLSearchParams({
          page: (pagination.pageIndex + 1).toString(),
          limit: pagination.pageSize.toString(),
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
          globalFilter: globalFilter ?? "",
        });

        const response = await apiClient.get(url, { params });

        return [
          {
            compliancestandards: response.data.results as ComplianceStandard[],
            rowCount: response.data.count,
          },
        ];
      } catch (error) {
        //
        return [{ compliancestandards: [], rowCount: 0 }];
      }
    },
    refetchOnWindowFocus: true,
  });
}

export function useUpdateComplianceStandard({
  table,
}: ComplianceStandardTableUpdateArg) {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (compliancestandard: ComplianceStandard) => {
      try {
        await apiClient.patch(
          `/api/compliancestandards/${compliancestandard.id}/`,
          compliancestandard
        );

        table.setEditingRow(null);
        toast.success(
          `Compliance Standard ${compliancestandard.title} updated successfully`
        );
      } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
          const errorData = error.response.data;
          for (const key in errorData) {
            if (Array.isArray(errorData[key])) {
              const errorMessage = errorData[key].join(", ");
              toast.error(`${key.toUpperCase()}: ${errorMessage}`);
            } else {
              toast.error(`${key.toUpperCase()}: ${errorData[key]}`);
            }
          }
        } else {
          toast.error(`Error updating Compliance Standard ${error}`);
        }
      }
    },

    onMutate: (newComplianceStandardInfo: ComplianceStandard) => {
      queryClient.setQueryData(
        ["compliancestandards"],
        (prevComplianceStandards: any) =>
          prevComplianceStandards?.map(
            (prevComplianceStandard: ComplianceStandard) =>
              prevComplianceStandard.id === newComplianceStandardInfo.id
                ? newComplianceStandardInfo
                : prevComplianceStandard
          )
      );
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ["compliancestandards"] }),
  });
}

export const useExportComplianceStandards = (
  columnFilters: any,
  sorting: any
) => {
  const exportComplianceStandards = async () => {
    try {
      const response = await apiClient.get("/api/compliancestandards/export/", {
        params: {
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
        },
        responseType: "blob",
      });

      const blob = new Blob([response.data], { type: "text/csv" });
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = downloadUrl;

      const now = new Date();
      const dateString = now.toISOString().split("T")[0];
      const timestamp = now.getTime();
      const filename = `ComplianceStandards-${dateString}-${timestamp}.csv`;

      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(downloadUrl);
      a.remove();

      toast.success(`${filename} downloaded successfully`);
    } catch (error) {
      toast.error(`Failed to download file : ${error}`);
    }
  };

  return exportComplianceStandards;
};

export const useDeleteComplianceStandards = (
  columnFilters: any,
  sorting: any,
  rowCount: number
) => {
  const deleteComplianceStandards = async () => {
    try {
      await apiClient.get("/api/compliancestandards/delete/", {
        params: {
          filters: JSON.stringify(columnFilters),
          sorting: JSON.stringify(sorting),
        },
      });

      toast.success(`Deleted ${rowCount} rows successfully`);
    } catch (error) {
      toast.error("Failed to delete rows");
    } finally {
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  return deleteComplianceStandards;
};
